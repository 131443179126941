import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VoltronContent } from '../vault-cleaner/vault-cleaner.types';
import { CoreService } from './core.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { DestinyRecipesItem } from '../types/destiny-recipes-item';
import { logError } from '../console';
import { environment } from '@Env';

@Injectable({providedIn: 'root'})
export class VoltronService {
  voltronContent: VoltronContent;
  version: number;
  readonly AdeptMatchings = {
    276080079: 679281855, // Fusion exil
    532746994: 1697682876, // Pompe horizon astral
    1173780905: 3658188704, // Messager
    1366917989: 958384347, // Rocket réponse de demain
    1481892490: 432476743, // Palindrome
    2147010335: 2633186522, // somme fictive
    2386979999: 2478792241, // erudit
    2527666306: 2351180975, // marteau igné
    3514144928: 1907698332, // invocateur
    3637570176: 3164743584, // oeil du soleil
    3836861464: 47772649, // nuée
    3847137620: 1313528549, // épée balafre
  };
  columnWeights = [.25, .25, 1, 1, .5, 0];
  masterworkWeight = .5;

  constructor(private dataService: DataService, private http: HttpClient, private coreService: CoreService) {}

  voltronLoaded(): boolean {
    return this.voltronContent !== undefined;
  }

  getVoltronVersion() {
    return this.http.get<{version: number}>(environment.recipesApi + '/health/voltron').pipe(
      tap((response) => { this.version = response.version; }),
    );
  }

  loadVoltronData(): Observable<any> {
    return this.getVoltronVersion().pipe(
      mergeMap((version: { version: number, location: string }) => this.http.get<VoltronContent>(environment.recipesApi + version.location)),
      tap((response) => { this.voltronContent = response; }),
      catchError((err) => {
        logError(err);
        return throwError(err);
      })
    );
  }

  /**
   * This is trying to estimate matrixes that reflects both the propency and the polyvalence of any perks.
   * If a perk is good in pve AND pvp, it's worth 2 points
   *
   * 1) Get best score possible with poly rolls (best in slot, 2 points max per slot (poly rolls))
   * 2) Get score for best roll in slot, 1 point max per slot
   * 3) If score is maxed (n/n sockets), recompute score with 2 points max per slot
   */
  calculateScore(items: DestinyRecipesItem[]): any | DestinyRecipesItem[] {
    return items.map((item) => {
      try {

        const baseItemHash = this.AdeptMatchings[item.itemHash] || item.itemHash;
        if (item.perks?.length > 0 && this.voltronContent[baseItemHash]) {
          const pvePerks = _.flatten(this.voltronContent[baseItemHash].pve);
          const pvpPerks = _.flatten(this.voltronContent[baseItemHash].pvp);
          const bestPossibleScore = this.computeBestPossibleScore(item, baseItemHash, pvePerks, pvpPerks);

          // Step 2 : get simple score (1pt max per slot)
          const itemScore = this.computeItemScore(item, baseItemHash, pvePerks, pvpPerks);

          // Step 3 : calculate final score
          // With poly
          const polyScoreDetail = {
            pvp: bestPossibleScore.pvp.withPoly === 0 ? -1 : itemScore.poly.pvp / bestPossibleScore.pvp.withPoly, // (scoreWithoutPolyPerks.pvp === bestPossibleScore.pvp.withoutPoly ? scoreWithPolyPerks.pvp : scoreWithoutPolyPerks.pvp) / bestPossibleScore.pvp.withPoly,
            pve: bestPossibleScore.pve.withPoly === 0 ? -1 : itemScore.poly.pve / bestPossibleScore.pve.withPoly, // (scoreWithoutPolyPerks.pve === bestPossibleScore.pve.withoutPoly ? scoreWithPolyPerks.pve : scoreWithoutPolyPerks.pve) / bestPossibleScore.pve.withPoly
          };

          // No poly
          const scoreDetail = {
            pvp: bestPossibleScore.pvp.withoutPoly === 0 ? -1 : itemScore.bare.pvp / bestPossibleScore.pvp.withoutPoly, // (scoreWithoutPolyPerks.pvp === bestPossibleScore.pvp.withoutPoly ? scoreWithPolyPerks.pvp : scoreWithoutPolyPerks.pvp) / bestPossibleScore.pvp.withPoly,
            pve: bestPossibleScore.pve.withoutPoly === 0 ? -1 : itemScore.bare.pve / bestPossibleScore.pve.withoutPoly, // (scoreWithoutPolyPerks.pve === bestPossibleScore.pve.withoutPoly ? scoreWithPolyPerks.pve : scoreWithoutPolyPerks.pve) / bestPossibleScore.pve.withPoly
          };
          let polyScore;
          if (polyScoreDetail.pvp < 0 && polyScoreDetail.pve < 0) {
            polyScore = -1;
          } else if (polyScoreDetail.pvp < 0) {
            polyScore = polyScoreDetail.pve;
          } else if (polyScoreDetail.pve < 0) {
            polyScore = polyScoreDetail.pvp;
          } else {
            polyScore = (polyScoreDetail.pvp + polyScoreDetail.pve) / 2;
          }
          item.score = {
            ...scoreDetail,
            polyvalent: polyScore
          };
        }
        return item;
      } catch (err) {
        logError('Error getting score of weapon', err, item);
        return item;
      }
    });
  }

  computeItemScore(item: DestinyRecipesItem, baseItemHash: string, pvePerks, pvpPerks) {
    let log = false;
    if (item.definition.displayProperties.name === 'Guillotine actionnée') {
      log = true;
    }
    const pveMatrix: number[][] = item.perks.map((perksInSlot, slotIndex) => perksInSlot.map((perk) => {
      let basePerkHash = perk.plugHash;
      if (perk.enhanced) {
        basePerkHash = this.dataService.enhancedTraitsToNormalTraits[perk.plugHash] || perk.plugHash;
        if (log) { console.log(basePerkHash, perk, pvePerks); }
      }
      const isGoodRollPve = pvePerks?.indexOf(basePerkHash) > -1 ? this.columnWeights[slotIndex] : 0;
      const isGoodRollPvp = pvpPerks?.indexOf(basePerkHash) > -1 ? this.columnWeights[slotIndex] : 0;
      return isGoodRollPve === this.columnWeights[slotIndex] ? isGoodRollPve + isGoodRollPvp : 0;
    }));
    const pvpMatrix: number[][] = item.perks.map((perksInSlot, slotIndex) => perksInSlot.map((perk) => {
      let basePerkHash = perk.plugHash;
      if (perk.enhanced) {
        basePerkHash = this.dataService.enhancedTraitsToNormalTraits[perk.plugHash] || perk.plugHash;
      }
      const isGoodRollPve = pvePerks?.indexOf(basePerkHash) > -1 ? this.columnWeights[slotIndex] : 0;
      const isGoodRollPvp = pvpPerks?.indexOf(basePerkHash) > -1 ? this.columnWeights[slotIndex] : 0;
      return isGoodRollPvp === this.columnWeights[slotIndex] ? isGoodRollPvp + isGoodRollPve : 0;
    }));
    const scoreWithoutPolyPerks = {
      pvp: pvpMatrix.reduce((acc, perkSlot, colIdx) => acc + (perkSlot.length ? Math.min(Math.max(...perkSlot), this.columnWeights[colIdx]) : 0), 0),
      pve: pveMatrix.reduce((acc, perkSlot, colIdx) => acc + (perkSlot.length ? Math.min(Math.max(...perkSlot), this.columnWeights[colIdx]) : 0), 0),
    };
    const scoreWithPolyPerks = {
      pvp: pvpMatrix.reduce((acc, perkSlot) => acc + (perkSlot.length ? Math.max(...perkSlot) : 0), 0),
      pve: pveMatrix.reduce((acc, perkSlot) => acc + (perkSlot.length ? Math.max(...perkSlot) : 0), 0),
    };
    return {
      poly: scoreWithPolyPerks,
      bare: scoreWithoutPolyPerks
    };
  }

  computeBestPossibleScore(item: DestinyRecipesItem, baseItemHash: string, pvePerks, pvpPerks): { pvp: {withPoly: number, withoutPoly: number}, pve: {withPoly: number, withoutPoly: number} } {
    const baseScoreMatrixes = {
      pvp: this.voltronContent[baseItemHash].pvp.map((slot, colIdx) => {
        if (!slot.length) { return 0; }
        if (_.intersection(slot, pvePerks).length) {
          return 2 * this.columnWeights[colIdx];
        }
        return this.columnWeights[colIdx];
      }),
      pve: this.voltronContent[baseItemHash].pve.map((slot, colIdx) => {
        if (!slot.length) { return 0; }
        if (_.intersection(slot, pvpPerks).length) {
          return 2 * this.columnWeights[colIdx];
        }
        return this.columnWeights[colIdx];
      })
    };
    return {
      pvp: {
        withPoly: baseScoreMatrixes.pvp.reduce((acc, cur) => acc + cur, 0),
        withoutPoly: baseScoreMatrixes.pvp.reduce((acc, cur, colIdx) => acc + Math.min(cur, this.columnWeights[colIdx]), 0),
      },
      pve: {
        withPoly: baseScoreMatrixes.pve.reduce((acc, cur) => acc + cur, 0),
        withoutPoly: baseScoreMatrixes.pve.reduce((acc, cur, colIdx) => acc + Math.min(cur, this.columnWeights[colIdx]), 0),
      }
    };
  }
}
