import {
  DestinyRecipesWatcher,
  DestinyRecipesWatcherConfig,
  DestinyRecipesWatcherType
} from './destiny-recipes-watcher';
import { DataService } from '../services/data.service';
import { DestinyItemCategory } from '../services/destinyItemCategory';
import * as _ from 'lodash';
import { DestinyRecipesItem } from '../types/destiny-recipes-item';
import { ToastrService } from 'ngx-toastr';
import { ItemState } from 'bungie-api-ts/destiny2';
import { TranslateService } from '@ngx-translate/core';

export interface BestPowerWatcherConfig extends DestinyRecipesWatcherConfig {
  type: DestinyRecipesWatcherType.BEST_POWER;
}

export class BestPowerWatcher extends DestinyRecipesWatcher {

  gearCategories = [
    [DestinyItemCategory.TITAN, DestinyItemCategory.A_CLASS],
    [DestinyItemCategory.TITAN, DestinyItemCategory.A_LEGS],
    [DestinyItemCategory.TITAN, DestinyItemCategory.A_CHEST],
    [DestinyItemCategory.TITAN, DestinyItemCategory.A_LEGS],
    [DestinyItemCategory.TITAN, DestinyItemCategory.A_HELMET],
    [DestinyItemCategory.HUNTER, DestinyItemCategory.A_CLASS],
    [DestinyItemCategory.HUNTER, DestinyItemCategory.A_LEGS],
    [DestinyItemCategory.HUNTER, DestinyItemCategory.A_CHEST],
    [DestinyItemCategory.HUNTER, DestinyItemCategory.A_LEGS],
    [DestinyItemCategory.HUNTER, DestinyItemCategory.A_HELMET],
    [DestinyItemCategory.WARLOCK, DestinyItemCategory.A_CLASS],
    [DestinyItemCategory.WARLOCK, DestinyItemCategory.A_LEGS],
    [DestinyItemCategory.WARLOCK, DestinyItemCategory.A_CHEST],
    [DestinyItemCategory.WARLOCK, DestinyItemCategory.A_LEGS],
    [DestinyItemCategory.WARLOCK, DestinyItemCategory.A_HELMET],
    [DestinyItemCategory.POWER],
    [DestinyItemCategory.ENERGY],
    [DestinyItemCategory.PRIMARY],
  ];

  constructor(
    protected dataService: DataService,
    protected toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super(DestinyRecipesWatcherType.BEST_POWER, dataService);
  }

  protected beforeStart() {
  }

  protected beforeStop() {
  }

  protected checkConfig() {
  }

  protected inventoryUpdated() {
    const newItems = this.dataService.filterInventory({ lootAfter: this.watchFrom });
    if (!newItems?.length) { return; }
    this.gearCategories.forEach(async (categories) => {
      const newItemsInCategory = newItems.filter((i) => categories.every((cat) => i.definition.itemCategoryHashes.indexOf(cat) > -1));
      if (newItemsInCategory?.length) {
        const allItemsInCategory = this.dataService.filterInventory({category: categories});
        const maxPowerLevel = _.maxBy(allItemsInCategory, 'powerLevel')?.powerLevel ?? 0;
        const itemsToLock = newItemsInCategory.filter((item) => item.powerLevel >= maxPowerLevel);
        if  (itemsToLock.length > 0) {
          await this.lockItems(itemsToLock).catch((err) => {
            console.error(err);
            this.toastr.warning(this.translateService.instant('WATCHER.BEST_POWER.MISSING_LOCK'));
          });
        }
      }
    });
    this.afterUpdate();
  }

  private async lockItems(items: DestinyRecipesItem[]) {
    for (const item of items) {
      try {
        await this.dataService.setItemLockState(item, true);
        (item as any).state |= ItemState.Locked;
        this.toastr.info(this.translateService.instant('WATCHER.BEST_POWER.ITEM_LOCKED', {itemName: item.definition.displayProperties.name}));
        await new Promise(r => setTimeout(r, 500));
      } catch (err) {
        console.error(err);
        this.toastr.warning(this.translateService.instant('WATCHER.BEST_POWER.ITEM_ERROR_LOCKED') + item.definition.displayProperties.name);
      }
    }
  }
}
