import { Component, OnInit } from '@angular/core';
import { ModaleComponent } from 'src/app/common/modale/modale.component';
import { ModalContentDialog } from '../../types';

@Component({
  selector: 'app-armor-guide-dialog',
  templateUrl: './armor-guide-dialog.component.html',
  styleUrls: ['./armor-guide-dialog.component.scss']
})
export class ArmorGuideComponentDialog implements OnInit, ModalContentDialog<any> {

  data: any;
  readonly modalReference: ModaleComponent;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => { this.modalReference.confirmedDisabled = false; });
  }

  asyncClose(): any {
  }

  getData(): any {
    return undefined;
  }

}
