import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-value-slider',
  templateUrl: './value-slider.component.html',
  styleUrls: ['./value-slider.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ValueSliderComponent),
    multi: true,
  }],
})
export class ValueSliderComponent implements OnInit, ControlValueAccessor {

  onChange;
  onTouched;
  disabled: boolean;
  value: number;

  @Input() min: number;
  @Input() max: number;
  @Input() snapMax: any;
  @Input() snapMin: any;
  @Input() step = 1;
  @Input() textOverride: { [key: string]: string };

  get realMax() {
    return this.max + (!this.snapMax ? 0 : this.step);
  }

  get realMin() {
    return this.min - (!this.snapMin ? 0 : this.step);
  }

  get currentPosition() {
    return Math.floor((this.value - this.realMin) * 100 / (this.realMax - this.realMin));
  }

  get adjustedBubblePosition() {
    return `calc(${this.currentPosition}% + (${8 - this.currentPosition * 0.15}px))`;
  }

  constructor() { }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(val: number): void {
    this.value = val;
  }

}
