import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CookieService } from 'ngx-cookie-service';
import { CoreService } from './core.service';
import { DestinyComponentType, DestinyProfileResponse, getProfile } from 'bungie-api-ts/destiny2';
import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserInfoCard } from 'bungie-api-ts/user';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class ImportedDataService extends DataService {

  currentMemberShip: UserInfoCard;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected db: NgxIndexedDBService,
    protected cookies: CookieService,
    protected coreService: CoreService,
    protected translate: TranslateService,
    protected toaster: ToastrService
  ) {
    super(http, authService, db, cookies, coreService, translate, toaster);
  }

  importInventory(data: DestinyProfileResponse) {
    this.setInventoryData(data);
  }

  loadGuardianInventory(membership: UserInfoCard) {
    this.currentMemberShip = membership;
    return this.getInventory();
  }

  getInventory() {
    return from(getProfile(this.coreService.httpClient, {
      membershipType: this.currentMemberShip.membershipType,
      destinyMembershipId: this.currentMemberShip.membershipId,
      components: [
        DestinyComponentType.CharacterEquipment,
        DestinyComponentType.CharacterInventories,
        DestinyComponentType.Characters,
        DestinyComponentType.Collectibles,
        DestinyComponentType.ProfileCurrencies,
        DestinyComponentType.ItemInstances,
        DestinyComponentType.ProfileInventories,
        DestinyComponentType.Profiles,
        DestinyComponentType.ItemPerks,
        DestinyComponentType.ItemStats,
        DestinyComponentType.ItemSockets,
        DestinyComponentType.ItemReusablePlugs,
        DestinyComponentType.ItemPlugStates,
        DestinyComponentType.ItemObjectives,
      ]
    })).pipe(
      map((result) => {
        const profileResponse: DestinyProfileResponse = result.Response;
        if (!profileResponse.profileInventory.data?.items?.length) {
          this.currentMemberShip = null;
          throw new Error('This player\'s inventory is private');
        }
        return profileResponse;
      }),
    );
  }
}
