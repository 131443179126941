<div class="stat" *ngIf="statExists" [ngClass]="{'stable': !totalModValue, 'positive': totalModValue > 0, 'negative': totalModValue < 0}">
  <span class="name">{{definition.displayProperties.name}}</span>
  <span class="comparison" >
    <fa-icon [icon]="['fas', totalModValue < 0 ? 'caret-down' : 'caret-up']"></fa-icon>
  </span>
  <div class="bar" *ngIf="displayStyle === 'bar'">
    <div class="bar-progress" [style.width]="(baseValue / stat.maximumValue * 100) + '%'" [title]="baseValue">
      <div class="bar-progress modded masterwork" *ngIf="masterworked" [style.width]="(masterworkedValue / stat.maximumValue * 100) + '%'" [style.left]="((baseValue + totalModValue) / stat.maximumValue * 100) + '%'" [title]="'Masterwork : 2'"></div>
      <ng-container *ngFor="let mod of mods">
        <div class="bar-progress modded positive" [style.width]="mod.width + '%'" [style.left]="mod.left + '%'" [title]="mod.value.perkApplied?.displayProperties?.name + ' : ' + mod.value.statModifier"></div>
        <div class="bar-progress modded negative" [style.width]="-mod.width + '%'" [style.left]="mod.left + '%'" [title]="mod.value.perkApplied?.displayProperties?.name + ' : ' + mod.value.statModifier"></div>
      </ng-container>
    </div>
  </div>
  <div class="recoil" *ngIf="displayStyle === 'angle'">
    <app-recoil-graph [recoilStat]="{value: totalValue, statHash: 2715839340}" height="18px"></app-recoil-graph>
  </div>
  <span class="value">{{totalValue}}</span>
</div>
