
<div class="loadout" *ngIf="loadouts?.length">
  <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.IN_LOADOUT' | translate}}</span>
  <span *ngFor="let loadout of loadouts">{{loadout.name}}</span>
</div>
<div class="notes" *ngIf="dimSyncEntry?.notes && !noteEditorOpen" (click)="toggleNoteEditor()"><fa-icon [icon]="['fas', 'pen']"></fa-icon>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.NOTES' | translate}} {{dimSyncEntry.notes}}</div>
<div class="notes" *ngIf="!noteEditorOpen && (!dimSyncEntry || !dimSyncEntry.notes)" (click)="toggleNoteEditor()"><fa-icon [icon]="['fas', 'pen']"></fa-icon>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.ADD_NOTES' | translate}}</div>
<div class="notes editor" *ngIf="noteEditorOpen === true">
  <textarea [(ngModel)]="notes" [placeholder]="'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.ADD_NOTES_PLACEHOLDER' | translate" (keypress)="sendNotes($event)"></textarea>
</div>
