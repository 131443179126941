import { Component, Input, OnChanges } from '@angular/core';
import { DestinyInventoryItemDefinition, DestinyStat } from 'bungie-api-ts/destiny2';
import { DestinyRecipesItem, RecoilValue } from '@Types/destiny-recipes-item';
import { ItemHelper } from '../item.helper';
import { DataService } from '@Services/data.service';

@Component({
  selector: 'app-recoil-graph',
  templateUrl: './recoil-graph.component.html',
  styleUrls: ['./recoil-graph.component.scss']
})
export class RecoilGraphComponent implements OnChanges {

  amplitude = 10;

  @Input() recoilStat: DestinyStat;
  @Input() item: DestinyInventoryItemDefinition;
  @Input() instance: DestinyRecipesItem;
  @Input() height: string;
  recoil: RecoilValue;
  svgPath: string;

  constructor(private data: DataService) { }

  ngOnChanges(): void {
    this.update();
  }

  update() {
    this.svgPath = null;
    if (this.instance?.itemInstanceId) {
      this.recoil = ItemHelper.getRecoilValueFromItem(this.instance, this.data);
    } else if (this.item) {
      this.recoil = ItemHelper.getRecoilValueFromDefinition(this.item);
    } else if (this.recoilStat) {
      this.recoil = ItemHelper.getRecoilValueFromStat(this.recoilStat);
    } else {
      return;
    }
    this.amplitude = (100 - this.recoil.value + 5) / 2;
    const radAngleMin = (Math.PI / 180) * (this.recoil.angle - this.amplitude);
    const radAngleMax = (Math.PI / 180) * (this.recoil.angle + this.amplitude);
    const cxMin = 100 + (100 - this.recoil.mappedBounciness) * Math.cos(radAngleMin);
    const cyMin = 100 + (100 - this.recoil.mappedBounciness) * Math.sin(radAngleMin);

    const cxMax = 100 + (100 - this.recoil.mappedBounciness) * Math.cos(radAngleMax);
    const cyMax = 100 + (100 - this.recoil.mappedBounciness) * Math.sin(radAngleMax);
    this.svgPath = `M100,100 L${cxMin},${cyMin} A100,100 0 0,1 ${cxMax},${cyMax}z`;
  }

}
