<div class="character-btn" *ngIf="character; else vault">
  <div class="background" [style.backgroundImage]="'url(' + getEmblemBanner() + ')'"></div>
  <div class="emblem"><img [src]="'https://www.bungie.net' + character.emblemPath" alt="emblem"></div>
  <div class="className">{{getClassName()}}</div>
  <div class="actions">
    <button class="action-btn" *ngFor="let action of actions" (click)="trigger.emit({signal: action.signal, character: character})" [disabled]="action.disabled">{{action.name | translate}}</button>
  </div>
</div>
<ng-template #vault>
  <div class="character-btn" *ngIf="vaultDefinition">
    <div class="background" [style.backgroundImage]="'url(' + getEmblemBanner() + ')'"></div>
    <div class="emblem"><img [src]="'https://www.bungie.net' + vaultDefinition.displayProperties.smallTransparentIcon" alt="emblem"></div>
    <div class="actions">
      <button class="action-btn" *ngFor="let action of actions" (click)="trigger.emit({signal: action.signal, character: null})" [disabled]="action.disabled">{{action.name | translate}}</button>
    </div>
  </div>
</ng-template>
