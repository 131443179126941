import { ElementRef } from '@angular/core';

export function repositionPopup(element: ElementRef, position: { left: number; top: number}, side: 'left' | 'right'): { left: string; top: string; } {
  const out: any = {};
  if (element) {
    const tooltipHeight = element.nativeElement.clientHeight;
    if (position.top + 10 + tooltipHeight > window.innerHeight) {
      out.top = window.innerHeight - tooltipHeight - 10 + 'px';
    } else {
      out.top = (position.top + 10) + 'px';
    }

    // Prevent tooltip from going outside of the horizontal axis
    const tooltipWidth = element.nativeElement.clientWidth;

    if (side === 'right') {
      if (position.left + 10 + tooltipWidth > window.innerWidth) {
        out.left = window.innerWidth - tooltipWidth - 10 + 'px';
      } else {
        out.left = (position.left + 10) + 'px';
      }

      if (position.left > window.innerHeight - tooltipHeight - 10 && position.top < window.innerHeight - 10 &&
        position.left > window.innerWidth - tooltipWidth - 10 && position.top < window.innerWidth - 10
      ) {
        out.left = (position.left - tooltipWidth - 10) + 'px';
      }
    } else if (side === 'left') {
      if (position.left - tooltipWidth - 10 < 0) {
        out.left = (position.left + 10) + 'px';
      } else {
        out.left = position.left - tooltipWidth - 10 + 'px';
      }
    }
  } else {
    out.top = (position.top + 10) + 'px';
    out.left = (position.left + 10) + 'px';
  }
  return out;
}
