import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DestinyCharacterComponent, DestinyClassDefinition, DestinyVendorDefinition } from 'bungie-api-ts/destiny2';
import { DataService } from '../../services/data.service';

export interface CharacterButtonAction {
  name: string;
  signal: string;
  disabled: boolean;
}

export interface CharacterButtonTrigger {
  signal: string;
  character: DestinyCharacterComponent;
}

@Component({
  selector: 'app-character-button',
  templateUrl: './character-button.component.html',
  styleUrls: ['./character-button.component.scss']
})
export class CharacterButtonComponent implements OnInit {

  @Input() characterId: string;
  @Input() actions: CharacterButtonAction[];
  @Output() trigger = new EventEmitter<CharacterButtonTrigger>();

  character: DestinyCharacterComponent;
  vaultDefinition: DestinyVendorDefinition;

  constructor(private data: DataService) { }

  ngOnInit(): void {
    if (this.characterId === 'vault') {
      this.vaultDefinition = this.data.getDefinitionById<DestinyVendorDefinition>('DestinyVendorDefinition', 1037843411);
    } else {
      this.character = this.data.getCharacter(this.characterId);
    }
  }

  getClassName() {
    return this.data
      .getDefinitionById<DestinyClassDefinition>('DestinyClassDefinition', this.character.classHash)
      .genderedClassNamesByGenderHash[this.character.genderHash];
  }

  getEmblemBanner() {
    return 'https://www.bungie.net' + (this.character?.emblemBackgroundPath || this.vaultDefinition?.displayProperties.largeIcon);
  }

}
