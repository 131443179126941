import { defaultRecipeContent, RatingType, Recipe, RecipeContent, RecipeCoverage } from './destiny-recipes.api';
import * as _ from 'lodash';
import {
  ActivitySortedDestinyRecipesPerks,
  DestinyRecipesPerkRank,
  RatedDestinyRecipesPerks
} from './destiny-recipes-item';
import { DestinyInventoryItemDefinition, DestinyItemSubType, DestinyItemType } from 'bungie-api-ts/destiny2';
import { DataService } from '@Services/data.service';
import { SocketCategoryHashes } from '@Utils/generated-enums';
import { plugCategoryToColumnIndexMapper } from '../appraiser/appraiser.types';
import { ItemHelper } from '@Common/item.helper';

export class RecipeHelper {

  get hasChanges(): boolean {
    return Object.keys(this.changes).some((change) => this.changes[change]);
  }

  constructor(private dataService: DataService) {
    this.changes = {};
    this.content = defaultRecipeContent();
    this.stagingContent = _.cloneDeep(this.content);
  }

  recipe: Recipe;
  content: RecipeContent;
  stagingContent: RecipeContent;
  changes: {[key: number]: boolean};

  static from(recipe: Recipe, service: DataService): RecipeHelper {
    const helper = new RecipeHelper(service);
    helper.recipe = recipe;
    return helper;
  }

  static getStoredHash(hash: number, itemSubType: DestinyItemSubType): string | number {
    return itemSubType ? `${hash}-${itemSubType}` : hash;
  }

  public static getSortedRatingsFromPerks(ratedPerks: RatedDestinyRecipesPerks, options?: {usePlugCategoryAsIndex?: boolean}): ActivitySortedDestinyRecipesPerks {
    // TODO handle type inheritance
    if (ratedPerks) {
      const perks = ratedPerks.perks;
      const columns = perks
        .reduce((acc, cur) => {
          const idx = options?.usePlugCategoryAsIndex ? plugCategoryToColumnIndexMapper[cur.plugCategoryHash] : cur.realSocketEntriesIndex;
          if (!acc.includes(idx)) {
            acc.push(idx);
          }
          return acc;
        }, []);
      columns.sort((a, b) => a - b);
      const columnsMapping = {};
      columns.forEach((col, idx) => {
        columnsMapping[col] = idx;
      });
      const pveSortedPerks = {
        [DestinyRecipesPerkRank.S]: new Array(columns.length).fill(0).map(() => []),
        [DestinyRecipesPerkRank.A]: new Array(columns.length).fill(0).map(() => []),
        [DestinyRecipesPerkRank.B]: new Array(columns.length).fill(0).map(() => []),
        [DestinyRecipesPerkRank.C]: new Array(columns.length).fill(0).map(() => []),
      };
      const pvpSortedPerks = {
        [DestinyRecipesPerkRank.S]: new Array(columns.length).fill(0).map(() => []),
        [DestinyRecipesPerkRank.A]: new Array(columns.length).fill(0).map(() => []),
        [DestinyRecipesPerkRank.B]: new Array(columns.length).fill(0).map(() => []),
        [DestinyRecipesPerkRank.C]: new Array(columns.length).fill(0).map(() => []),
      };
      ratedPerks.perks.forEach((perk) => {
        const idx = options?.usePlugCategoryAsIndex ? plugCategoryToColumnIndexMapper[perk.plugCategoryHash] : perk.realSocketEntriesIndex;
        if (perk.proficiency?.pvp !== undefined) {
          pvpSortedPerks[perk.proficiency.pvp][columnsMapping[idx]].push(perk);
        } else {
          pvpSortedPerks[DestinyRecipesPerkRank.B][columnsMapping[idx]].push(perk);
        }
        if (perk.proficiency?.pve !== undefined) {
          pveSortedPerks[perk.proficiency.pve][columnsMapping[idx]].push(perk);
        } else {
          pveSortedPerks[DestinyRecipesPerkRank.B][columnsMapping[idx]].push(perk);
        }
      });
      return {
        pvp: pvpSortedPerks,
        pve: pveSortedPerks,
      };
    }
  }

  public static getWeightForSocketIndex(ratingSystem: RatedDestinyRecipesPerks, definition: DestinyInventoryItemDefinition, socketIndex: number): number {
    try {
      const category = definition.sockets.socketCategories.find((cat) => cat.socketCategoryHash === SocketCategoryHashes.WeaponPerks_Reusable);
      return ratingSystem.weights[category.socketIndexes.indexOf(socketIndex)];
    } catch (err) {
      return 0;
    }
  }

  clone(): RecipeHelper {
    const newRecipe = RecipeHelper.from(this.recipe, this.dataService);
    newRecipe.setContent(this.content);
    return newRecipe;
  }

  setContent(content: RecipeContent) {
    this.content = content;
    this.stagingContent = _.cloneDeep(content);
  }

  hasRatingsForRatingType(hash: number | string, type: RatingType): boolean {
    return Object.prototype.hasOwnProperty.call(this.content[type], hash);
  }

  getRatingSystemNameForItem(hash: number, itemSubType: DestinyItemSubType): RatingType | undefined {
    if (this.hasRatingsForRatingType(hash, RatingType.weaponRatings)) {
      return RatingType.weaponRatings;
    }
    const item = this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', hash);
    const archetypeHash = ItemHelper.getArchetype(item, this.dataService)?.hash;
    const searchHash = RecipeHelper.getStoredHash(archetypeHash, itemSubType);
    if (this.hasRatingsForRatingType(searchHash, RatingType.archetypeRatings)) {
      return RatingType.archetypeRatings;
    }
    return undefined;
  }

  /**
   * ItemSubType is used to disambiguate between weapon types that share the same archetype
   */
  getRatings(hash: number | string, type: RatingType, columnsAmount: number, itemSubType?: DestinyItemSubType): RatedDestinyRecipesPerks {
    console.log(':: getting ratings for #', hash, type);
    if (this.hasRatingsForRatingType(hash, type)) {
      if (!this.content[type][hash].weights) {
        this.content[type][hash].weights = new Array(columnsAmount).fill(Math.round(100 / columnsAmount));
      }
      console.log(':: has ratings for', hash, type, 'returning', this.content[type][hash]);
      return this.content[type][hash];
    } else if (type === RatingType.weaponRatings) {
      console.log(':: has no ratings for', hash, type, 'trying to extrapolate from archetype');
      const item = this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', hash as number);
      const archetypeHash = ItemHelper.getArchetype(item, this.dataService)?.hash;
      return this.extrapolateRatingsFromArchetype(archetypeHash, columnsAmount, item, itemSubType);
    }
    console.log(':: has no ratings for', hash, type);
    return {
      source: 'empty',
      perks: [],
      weights: new Array(columnsAmount).fill(Math.round(100 / columnsAmount))
    };
  }

  extrapolateRatingsFromArchetype(hash: number, columnsAmount: number, baseWeapon: DestinyInventoryItemDefinition, itemSubType: DestinyItemSubType): RatedDestinyRecipesPerks {
    const searchHash = RecipeHelper.getStoredHash(hash, itemSubType);
    console.log(':: getting archetype ratings for #', searchHash);
    const ratings = this.getRatings(searchHash, RatingType.archetypeRatings, columnsAmount);
    if (!searchHash || !ratings || ratings.source === 'empty') {
      console.log(':: no archetype ratings for #', searchHash);
      return {
        source: 'empty',
        perks: [],
        weights: new Array(columnsAmount).fill(Math.round(100 / columnsAmount))
      };
    }
    const possiblePerk = ItemHelper.getPossiblePerksOfWeapon(baseWeapon, this.dataService, { noTracker: true });
    const perks = ratings.perks.filter((perk) => possiblePerk.some((perkList) => perkList.find((p) => p.plugHash === perk.plugHash)));
    console.log(':: archetype ratings for #', searchHash, 'are', perks);
    return {
      source: 'archetype',
      perks,
      weights: new Array(columnsAmount).fill(Math.round(100 / columnsAmount))
    };
  }

  setWeaponRating(weaponHash: number, ratings: RatedDestinyRecipesPerks) {
    this.stagingContent.weaponRatings[weaponHash] = ratings;
    this.changes[weaponHash] = true;
  }

  setArchetypeRating(archetypeHash: number, ratings: RatedDestinyRecipesPerks) {
    this.stagingContent.archetypeRatings[archetypeHash] = ratings;
    this.changes[archetypeHash] = true;
  }

  removeStagingRating(hash: number, type: RatingType) {
    this.stagingContent[type][hash] = this.content[type][hash];
    this.changes[hash] = false;
  }

  saveRating(hash: number, type: RatingType) {
    this.pruneNeutralPerks();
    this.stagingContent[type][hash].source = type === RatingType.weaponRatings ? 'weapon' : 'archetype';
    this.content[type][hash] = _.cloneDeep(this.stagingContent[type][hash]);
    this.changes[hash] = false;
  }

  /** Used to remove perks that are not rated to save recipe file's space */
  pruneNeutralPerks() {
    ['weaponRatings', 'archetypeRatings'].forEach((type: keyof RecipeContent) => {
      if (this.stagingContent[type]) {
        Object.keys(this.stagingContent[type]).forEach((hash) => {
          if (this.stagingContent[type][hash].perks.length === 0) {
            delete this.stagingContent[type][hash];
          }
          this.stagingContent[type][hash].perks = this.stagingContent[type][hash].perks.filter((perk) => perk.proficiency.pve !== 0 || perk.proficiency.pvp !== 0);
        });
      }
    });
  }

  saveAll() {
    this.pruneNeutralPerks();
    this.content = _.cloneDeep(this.stagingContent);
    this.changes = {};
  }

  getCoverageReport(): RecipeCoverage {
    const weaponCoverage = Object.keys(this.content.weaponRatings).map((hash) => parseInt(hash, 10));
    const archetypeCoverage = Object.keys(this.content.archetypeRatings)
      .map((archetype) => this.dataService.getDefinitions<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', { itemType: DestinyItemType.Weapon }, undefined, {
        intrinsicPerkHash: parseInt(archetype, 10),
        hasRandomRoll: true
      }))
      .reduce((acc, cur) => {
        acc.push(...cur.values.filter((item) => !weaponCoverage.includes(item.hash)));
        return acc;
      }, []);
    const typeCoverage = Object.keys(this.content.typeRatings)
      .map((subType) => this.dataService.getDefinitions<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', { itemType: DestinyItemType.Weapon, itemSubType: parseInt(subType, 10) }, undefined, {
        hasRandomRoll: true
      }))
      .reduce((acc, cur) => {
        acc.push(...cur.values.filter((item) => !weaponCoverage.includes(item.hash) && !archetypeCoverage.includes(item.hash)));
        return acc;
      }, []);
    return {
      byWeaponRating: weaponCoverage.length,
      byArchetypeRating: archetypeCoverage.length,
      byTypeRating: typeCoverage.length,
      total: weaponCoverage.length + archetypeCoverage.length + typeCoverage.length
    };
  }
}
