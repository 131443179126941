<div class="watcher-status">
  <div class="header" (click)="openWatchersModale()">
    <strong>{{'WATCHER.CONFIGURE' | translate}}</strong>
    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
  </div>
  <div class="watcher-list" *ngIf="watcherRunning">
    <ng-container *ngFor="let watcher of watchersService.watchers | keyvalue">
      <div class="watcher" *ngIf="watchersService.getStatus(watcher.value.type) as status">
        <div class="name">{{('WATCHER.' + watcher.value.type + '.NAME') | translate}}</div>
        <div class="status" [ngClass]="status">{{('WATCHER.STATUS.' + status) | translate}}</div>
      </div>
    </ng-container>
  </div>
</div>
