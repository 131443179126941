export const modsWithConditionalStats = {
  powerfulFriends: 1484685887,
  radiantLight: 2979815167,
  chargeHarvester: 2263321587,
};

export const adeptWeaponHashes = [
  98400473,
  276080079,
  532746994,
  534775659,
  681067419,
  751170008,
  852228780,
  1173780905,
  1175310596,
  1366917989,
  1481892490,
  1866778462,
  2147010335,
  2386979999,
  2443900757,
  2475355656,
  2527666306,
  2559492205,
  2740905684,
  3446399410,
  3514144928,
  3637570176,
  3836861464,
  3847137620,
  4023807721,
  445523360,
  497806049,
  631439337,
  690668916,
  795875974,
  1216319404,
  1688544136,
  1921159786,
  1987769101,
  2124893005,
  3036117446,
  3796510434
];

export const masterworksWithCondStats = [
  186337601,
  384158423,
  758092021,
  1154004463,
  1639384016,
  2697220197,
  2993547493,
  3128594062,
  3803457565
];

export const deepsightResonanceHashes = [
  3632593563,
  213377779,
  2400712188
];

export const ObjectiveHash = {
  weaponLevel: 3077315735,
  weaponLevelProgression: 2899837482,
  weaponCraftingDate: 3947811849,
  shapedWeapon: 659359923,
};

export const killTrackers = [905869860, 2240097604, 2302094943];
export const crucibleTrackers = [38912240, 2285636663, 3244015567];

export const recoilStatHash = 2715839340;

export const weakPinnaclesMilestoneHash = [3603098564, 3312774044, 3448738070, 1437935813];
export const hiddenStatsHashes = [1345609583, 2714457168, recoilStatHash, 3555269338];

