import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestinyRecipesItemSimulator } from '@Types/destiny-recipes-item';
import { DataService } from '@Services/data.service';
import { AppraiserService } from '@Services/appraiser.service';
import { Subscription } from 'rxjs';
import { WeaponRatingDetails } from '../../appraiser/appraiser.types';
import { SocketCategoryHashes } from '@Utils/generated-enums';

@Component({
  selector: 'app-simulation-result',
  templateUrl: './simulation-result.component.html',
  styleUrls: ['./simulation-result.component.scss']
})
export class SimulationResultComponent implements OnInit, OnChanges {

  @Input() simulation: DestinyRecipesItemSimulator;

  subscription: Subscription;

  rating: WeaponRatingDetails;

  perksColumnIndexes: number[];

  constructor(
    private dataService: DataService,
    private appraiserService: AppraiserService,
  ) { }

  ngOnInit() {
    if (this.subscription) { this.subscription.unsubscribe(); }
    this.subscription = this.simulation?.update$.subscribe(() => this.update());
    this.update();
  }

  ngOnChanges() {
    this.clear();
    if (this.simulation) {
      if (this.subscription) { this.subscription.unsubscribe(); }
      this.subscription = this.simulation.update$.subscribe(() => this.update());
      this.update();
    }
  }

  clear() {
    // reset view
  }

  update() {
    if (this.simulation) {
      this.rating = this.appraiserService.getRating(this.simulation.item, true);
      this.perksColumnIndexes = this.simulation.item.definition.sockets.socketCategories.find((e) => e.socketCategoryHash === SocketCategoryHashes.WeaponPerks_Reusable).socketIndexes;
    }
  }

}
