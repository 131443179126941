import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalConfig, ModalContentDialog, ModalResponse, ModalServiceBackbone } from '../../types';
import { logError } from '../../console';

@Component({
  selector: 'app-modale',
  templateUrl: './modale.component.html',
  styleUrls: ['./modale.component.scss']
})
export class ModaleComponent<U = any> implements AfterViewInit {

  public response$ = new Subject<ModalResponse<U>>();
  public config: ModalConfig<U>;
  public component: ComponentRef<ModalContentDialog<U>>;

  modalService: ModalServiceBackbone;
  confirmedDisabled = true;

  get modalBodyClass() {
    return this.config.modalBodyClass || '';
  }

  @ViewChild('dynamic', {
    read: ViewContainerRef
  }) viewContainerRef: ViewContainerRef;

  constructor(@Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver) { }

  setConfig(config: ModalConfig<U>) {
    this.config = config;
  }

  setService(service: ModalServiceBackbone) {
    this.modalService = service;
  }

  done() {
    this.response$.next({
      canceled: false,
      data: this.component.instance.getData()
    });
    this.destroy();
  }

  close() {
    if (this.config?.async) {
      this.confirmedDisabled = true;
      this.component.instance.asyncClose();
    } else {
      this.done();
    }
  }

  dismiss() {
    this.response$.next({canceled: true});
    this.destroy();
  }

  destroy() {
    this.response$.complete();
    try {
      this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.component.hostView));
      this.component.destroy();
      this.modalService.destroy();
    } catch (err) {
      logError(err);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.initComponent(), 0);
  }

  initComponent() {
    const factory = this.factoryResolver.resolveComponentFactory(this.config.component);
    this.component = factory.create(this.viewContainerRef.parentInjector);
    (this.component.instance as any).modalReference = this;
    this.component.instance.data = this.config.data;
    this.viewContainerRef.insert(this.component.hostView);
  }
}
