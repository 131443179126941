import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataService } from './data.service';
import { AuthService } from './auth.service';
import { EMPTY, Observable, of } from 'rxjs';
import { HallOfFameEntry, RecipeApiResponse, ScoreDocument } from '../types';
import { environment } from '../../environments/environment';
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { logError } from '../console';
import { RecipesApiService } from './recipes-api.service';

export interface ScoreReport {
  id: ScoreCalculatorCategory;
  baseProgress: number;
  realProgress: number;
  name: string;
  description: string;
  weight: number;
}

export class ScoreCalculator {
  registered: boolean;
  constructor(protected scoreService: ScoreService, protected category: ScoreCalculatorCategory) {}

  register() {
    if (this.registered) { return; }
    this.scoreService.register(this, this.category);
    this.registered = true;
  }

  computeScore(category?: ScoreCalculatorCategory): ScoreReport {
    throw new Error('Not implemented');
  }
}

export enum ScoreCalculatorCategory {
  MATERIALS = 1,
  RESOURCES = 2,
  BOUNTIES = 8,
  GLIMMER = 16,
  MODS = 32,
  VAULT = 64,
  WEAPONS = 128,
}

@Injectable({ providedIn: 'root' })
export class ScoreService {
  static MAX_SCORE = 300;
  static GOLD_THRESHOLD = ScoreService.MAX_SCORE;
  static GREEN_THRESHOLD = 225;
  static BLUE_THRESHOLD = 150;
  static ORANGE_THRESHOLD = 75;

  calculators: {[category: number]: ScoreCalculator} = {};
  reports: {[category: string]: ScoreReport} = {};
  score = 0;
  storedScore = -1;
  lastUpdated;
  sending: boolean;
  private calculatorReady = false;

  constructor(private http: HttpClient, private data: DataService, private recipesApi: RecipesApiService, private auth: AuthService) {
  }

  checkReadiness() {
    this.calculatorReady = [
      ScoreCalculatorCategory.RESOURCES,
     // ScoreCalculatorCategory.MATERIALS,
      ScoreCalculatorCategory.BOUNTIES,
     // ScoreCalculatorCategory.GLIMMER,
     // ScoreCalculatorCategory.WEAPONS,
      ScoreCalculatorCategory.VAULT
    ].every((cat) => this.calculators[cat]?.computeScore !== undefined);
  }

  register(calculator: ScoreCalculator, category: ScoreCalculatorCategory) {
    this.calculators[category] = calculator;
    this.checkReadiness();
    if (this.calculatorReady) {
      this.calculate();
    }
  }

  calculate() {
    if (!this.calculatorReady) { return; }
    this.score = 0;
    let tmpRealScore = 0;
    Object.keys(this.calculators).forEach((category) => {
      this.reports[category] = this.calculators[category].computeScore(parseInt(category, 10));
      this.score += this.reports[category].baseProgress;
      tmpRealScore += this.reports[category].baseProgress + (this.reports[category].realProgress - this.reports[category].baseProgress) * (this.reports[category].weight || 1);
    });
    if (this.score >= ScoreService.MAX_SCORE) {
      this.score = tmpRealScore;
    }
    this.score = Math.floor(this.score);
    this.send().subscribe((result) => {
      if (result?.value?.score !== undefined) {
        this.storedScore = result.value.score;
        this.lastUpdated = Date.now();
      }
    }, (err) => logError(err));
  }

  send() {
    if (!this.auth.authenticated) { return of(EMPTY); }
    if (this.sending) { return of(EMPTY); }
    this.sending = true;
    let update$ = of(this.storedScore);
    if (this.storedScore === -1 || Date.now() - this.lastUpdated > 1000 * 60 * 5) {
      update$ = this.get(this.auth.bungieMembership.membershipId).pipe(
        map((response) => response.exists ? response.score : -1),
        tap((score) => {
          this.storedScore = score;
          this.lastUpdated = Date.now();
        })
      );
    }
    return update$.pipe(
      filter((updatedScore) => updatedScore === -1 || updatedScore !== this.score),
      mergeMap(() => this.recipesApi.updateScore(this.score)),
      catchError((err) => {
        this.sending = false;
        throw err;
      }),
      finalize(() => this.sending = false)
    );
  }

  get(membershipId: string): Observable<RecipeApiResponse> {
    return this.http.get<RecipeApiResponse>(environment.recipesApi + '/score/' + membershipId);
  }

  getLeaderboard(limit: number, offset: number): Observable<RecipeApiResponse<ScoreDocument>> {
    return this.http.get<RecipeApiResponse<ScoreDocument>>(environment.recipesApi + '/score?limit=' + limit + '&offset=' + offset);
  }

  getUserPosition() {
    return this.http.get<any>(environment.recipesApi + '/score/' + this.auth.bungieMembership.membershipId + '/leaderboard');
  }

  getScoreForMultipleUsers(ids: string[]): Observable<RecipeApiResponse> {
    let params = new HttpParams();
    ids.forEach((id) => params = params.append('members', id));
    return this.http.get<any>(environment.recipesApi + '/score/users', { params });
  }

  getHallOfFameForSeason(seasonNumber: number): Observable<RecipeApiResponse<HallOfFameEntry>> {
    return this.http.get<RecipeApiResponse<HallOfFameEntry>>(environment.recipesApi + '/score/hall/' + seasonNumber);
  }

}
