import { Component, Input, OnChanges } from '@angular/core';
import { logError } from '../../console';
import { ItemAnnotation, TagValue } from '@destinyitemmanager/dim-api-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DimSyncService } from '../../services/dim-sync.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dim-sync-tag-editor',
  templateUrl: './dim-sync-tag-editor.component.html',
  styleUrls: ['./dim-sync-tag-editor.component.scss']
})
export class DimSyncTagEditorComponent implements OnChanges {

  @Input() instanceId: string;

  dimSyncAvailable: boolean;
  dimSyncEntry: ItemAnnotation;
  tagDropdownActive: boolean;
  DIMTags: TagValue[];
  tagIcons: {[tag: string]: IconProp} = {
    favorite: ['fas', 'heart'],
    keep: ['fas', 'tag'],
    infuse: ['fas', 'bolt'],
    junk: ['fas', 'ban'],
    archive: ['fas', 'archive'],
  };

  constructor(private dimSync: DimSyncService, private toastr: ToastrService, private translate: TranslateService) {
    this.DIMTags = this.dimSync.DIMTags;
  }

  ngOnChanges(): void {
    this.loadDimSyncEntry();
  }

  loadDimSyncEntry() {
    this.dimSyncEntry = null;
    this.dimSyncAvailable = true;
    if (!this.instanceId) {
      return;
    }
    if (!this.dimSync.profile) {
      this.dimSyncAvailable = false;
      return;
    }
    this.dimSyncEntry = this.dimSync.getAnnotationsForItem(this.instanceId) || { id: this.instanceId, tag: null, notes: null };
  }

  updateTag() {
    if (this.dimSyncAvailable) {
      this.dimSync.sendTagUpdates([{
        action: 'tag',
        payload: {
          tag: this.dimSyncEntry.tag,
          id: this.dimSyncEntry.id
        }
      }]).subscribe({
        error: (err) => {
          logError(err);
          this.toastr.error(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.TAG_UPDATED'));
        }
      });
    }
  }

  selectTag(tag: TagValue) {
    if (tag === this.dimSyncEntry.tag) {
      this.dimSyncEntry.tag = null;
    } else {
      this.dimSyncEntry.tag = tag;
    }
    this.updateTag();
    this.tagDropdownActive = false;
  }

}
