import { Component, OnInit } from '@angular/core';
import { ModalContentDialog } from '../../types';
import { ModaleComponent } from '../modale/modale.component';
import { ErrorService } from '../../services/error.service';
import { MaxTriesError } from '../../vault-cleaner/vault-cleaner/vault-cleaner.component';

@Component({
  selector: 'cr-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements ModalContentDialog, OnInit {

  data: { error: any };
  errorType: string;
  readonly modalReference: ModaleComponent;

  constructor(public errorService: ErrorService) {
  }

  get copiedError() {
    if (this.errorType === 'maxtries') {
      return `State: ${this.errorService.currentState || 'Unknown'} (Max tries reached)
Stacks :
${JSON.stringify(this.data.error.errorStacks)}

Items In Error:
${JSON.stringify(this.data.error.itemsInError)}
      `;
    } else {
      return 'State: ' + (this.errorService.currentState || 'Unknown') + '\n```' + this.data.error.stack + '```';
    }
  }

  ngOnInit(): void {
    if (this.data.error instanceof MaxTriesError) {
      this.errorType = 'maxtries';
      console.log(this.data.error.errorStacks, this.data.error.itemsInError);
    }
    setTimeout(() => this.modalReference.confirmedDisabled = false, 0);
  }

  getData(): any {
  }
}
