import { DestinyInventoryItemDefinition } from 'bungie-api-ts/destiny2';
import { PlugCategoryHashes } from '@Utils/generated-enums';

export interface WeaponRating {
  pve: number;
  pvp: number;
  polyvalent?: number;
  notAvailable?: {pve?: boolean, pvp?: boolean};
  details?: {
    pvp: {[key: number]: number};
    pve: {[key: number]: number};
  };
}

export interface WeaponRatingDetails {
  pve: number;
  pvp: number;
  polyvalent?: number;
  details?: {
    pve: {[hash: number]: {weight: number, perk: DestinyInventoryItemDefinition, score: number}},
    pvp: {[hash: number]: {weight: number, perk: DestinyInventoryItemDefinition, score: number}},
  };
  notAvailable?: {pve?: boolean, pvp?: boolean};
  maxScore?: WeaponRating;
  raw: WeaponRating;
}

export type RatingStatus = 'no-rating' | 'synced-type' | 'synced-archetype' | 'all-available' | 'partially-available';

export function isDisplayablePlugCategory(hash: number) {
  return [
    PlugCategoryHashes.Barrels,
    PlugCategoryHashes.Frames,
    PlugCategoryHashes.Scopes,
    PlugCategoryHashes.Magazines,
    PlugCategoryHashes.MagazinesGl,
    PlugCategoryHashes.Origins
  ].includes(hash);
}

export const plugCategoryToColumnIndexMapper = {
  [PlugCategoryHashes.Barrels]: 0,
  [PlugCategoryHashes.Frames]: 2,
  [PlugCategoryHashes.Scopes]: 0,
  [PlugCategoryHashes.Magazines]: 1,
  [PlugCategoryHashes.MagazinesGl]: 1,
  [PlugCategoryHashes.Origins]: 3,
};
