import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  DestinyInventoryItemDefinition,
  DestinyInventoryItemStatDefinition,
  DestinyItemInvestmentStatDefinition,
  DestinyPlugSetDefinition,
  DestinyStat,
  DestinyStatCategory,
  DestinyStatDefinition, DestinyStatDisplayDefinition,
  DestinyStatGroupDefinition,
  ItemState, TierType
} from 'bungie-api-ts/destiny2';
import { DataService } from '@Services/data.service';
import { DestinyItemCategory } from '@Services/destinyItemCategory';
import { DestinyRecipesItem, DestinyRecipesPerk } from '@Types/destiny-recipes-item';
import { logError } from '../../console';
import { MatchingWeapon, WeaponWishes } from '../../checklist/weapons/targeted-weapons';
import { ItemHelper } from '../item.helper';
import { DimSyncService } from '@Services/dim-sync.service';
import { ItemAnnotation } from '@destinyitemmanager/dim-api-types';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { VoltronService } from '@Services/voltron.service';
import { hiddenStatsHashes } from '@Utils/known-values';

@Component({
  selector: '[dr-item-stat]',
  templateUrl: './item-stat.component.html',
  styleUrls: ['./item-stat.component.scss']
})
export class ItemStatComponent implements OnChanges {

  stats: Array<{definition: DestinyStatDefinition, value: DestinyStat}>;
  type: 'weapon' | 'armor';
  sockets: DestinyInventoryItemDefinition[];
  intrinsicPerk: DestinyInventoryItemDefinition;
  @Input() item: DestinyInventoryItemDefinition;
  @Input() instance: DestinyRecipesItem;
  @Input() richPerks: boolean;
  @Input() targets: WeaponWishes[];
  @Input() perksOnly: boolean;
  @Input() hideMods: boolean;
  @Input() hideIntrinsic: boolean;
  @Input() hideStatName: boolean;
  @Input() highlights: WeaponWishes;
  @Input() statsToggleable: boolean;
  @Input() showPerksInitialState: boolean;

  showStats = true;

  possiblePerks: DestinyRecipesPerk[][];
  socketGroup = {
    weapon: 4241085061,
    armor: 590099826
  };
  masterworked: boolean;
  weaponMods: DestinyInventoryItemDefinition[];
  modded: number;
  statsModded: {[statHash: string]: number};
  total: {
    raw: number;
    masterworked?: number;
    modded?: number;
  };
  itemTarget: WeaponWishes;
  selectedMatch: MatchingWeapon;
  dimSyncAvailable: boolean;
  dimSyncObject: ItemAnnotation;
  lockingItem: boolean;
  voltron: boolean;
  isArtificeArmor: DestinyInventoryItemDefinition;

  mainStatGroup: DestinyStatGroupDefinition;
  hiddenStatGroup: DestinyStatDisplayDefinition[];

  isLocked(item: DestinyRecipesItem) {
    return ItemHelper.isLocked(item);
  }

  constructor(private dataService: DataService, private dimSync: DimSyncService, private toast: ToastrService, private translate: TranslateService, private voltronService: VoltronService) {
    this.voltron = Boolean(this.voltronService.voltronContent);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.item && changes.item.currentValue !== changes.item.previousValue) || (changes.instance && changes.instance.currentValue !== changes.instance.previousValue)) {
      this.refresh();
    }
    if (this.showPerksInitialState !== undefined) {
      this.showStats = this.showPerksInitialState;
    }
  }

  refresh() {
    try {
      this.dimSyncAvailable = !!this.dimSync.profile;
      if (this.dimSyncAvailable && this.instance) {
        this.dimSyncObject = this.dimSync.getAnnotationsForItem(this.instance.itemInstanceId);
      }
      this.type = this.item.itemCategoryHashes.includes(DestinyItemCategory.WEAPON) ? 'weapon' : 'armor';
      if (this.targets) {
        this.itemTarget = this.targets.find((e) => e.itemHash === this.item.hash);
        this.selectedMatch = this.itemTarget.matches?.[0];
      }
      if (this.instance) {
        this.masterworked = (this.instance.state & ItemState.Masterwork) !== 0 && this.type === 'armor';
      }
      this.statsModded = {};
      // COMPUTE STATS
      const statHashes = this.instance?.stats || this.item.stats;

      // COMPUTE MODS & PERKS
      if (this.item.sockets) {
        if (ItemHelper.isArtificeArmor(this.item)) {
          this.isArtificeArmor = this.dataService.getDefinitionById('DestinyInventoryItemDefinition', 3727270518);
        }
        const socketEntriesIndexes = this.item.sockets.socketCategories.find((e) => e.socketCategoryHash === this.socketGroup[this.type]);
        if (socketEntriesIndexes) {
          this.sockets = socketEntriesIndexes.socketIndexes.map((socketIdx) => {
            if (this.instance) {
              const plugs = this.instance.currentPlugs;
              if (plugs?.sockets[socketIdx]) {
                const modApplied = this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plugs.sockets[socketIdx].plugHash);
                if (modApplied?.investmentStats?.length > 0) {
                  this.applyStats(modApplied);
                }
                return modApplied;
              }
            }
            return this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', this.item.sockets.socketEntries[socketIdx].singleInitialItemHash);
          });

          // If no instance, we can only see the definition. Getting all perks for the weapon
          if (!this.instance && this.type === 'weapon') {
            this.possiblePerks = [];
            socketEntriesIndexes.socketIndexes.forEach((weaponPerkColumnIndex, realColumnIndex) => {
              const perksInColumns: DestinyRecipesPerk[] = [];
              const plugSetHash = this.item.sockets.socketEntries[weaponPerkColumnIndex].randomizedPlugSetHash || this.item.sockets.socketEntries[weaponPerkColumnIndex].reusablePlugSetHash;
              if (plugSetHash) {
                perksInColumns.push(...this.dataService.getDefinitionById<DestinyPlugSetDefinition>('DestinyPlugSetDefinition', plugSetHash).reusablePlugItems.map((plug) => ({
                  plugHash: plug.plugItemHash,
                  enhanced: this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plug.plugItemHash).inventory.tierType === TierType.Common,
                  active: false
                })).filter((perk) => {
                  if (!this.itemTarget) {
                    return true;
                  } else if (this.itemTarget.perks[realColumnIndex]?.length === 0) {
                    return false;
                  }
                  return this.itemTarget.perks[realColumnIndex]?.indexOf(perk.plugHash) > -1;
                }));
                if (perksInColumns.length === 0) {
                  perksInColumns.push({plugHash: 712324018, active: false, enhanced: false});
                }
              }
              this.possiblePerks.push(perksInColumns);
            });
          }
        }
      }
      this.computeStats(this.item.stats.statGroupHash, statHashes.stats);
      this.computeHiddenStats();

      // GET intrinsic PERK
      if (this.type === 'weapon') {
        this.getMods();
        const intrinsicPerk = this.item.sockets.socketCategories.find((e) => e.socketCategoryHash === 3956125808);
        if (intrinsicPerk?.socketIndexes) {
          const perk = this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', this.item.sockets.socketEntries[intrinsicPerk.socketIndexes[0]].singleInitialItemHash);
          (perk as any).instrinsic = true;
          this.intrinsicPerk = perk;
        }
      }
    } catch (err) {
      logError(err);
    }
  }

  statHasBar(stat: {definition: DestinyStatDefinition, value: DestinyStat}) {
    const blackList = [
      2762071195,
      4284893193,
      3871231066,
      2961396640,
      209426660,
      3736848092,
      447667954
    ];
    return stat.value && blackList.indexOf(stat.value.statHash) === -1;
  }

  getMods() {
    if (this.instance) {
      this.weaponMods = [];
      const modsSocketIndex = this.instance.definition.sockets.socketCategories.find((s) => s.socketCategoryHash === 2685412949 /* WEAPON MODS */)?.socketIndexes || [];
      modsSocketIndex.forEach((modSocketIndex) => {
        const plug = this.instance.currentPlugs?.sockets[modSocketIndex];
        if (plug?.isEnabled && plug.plugHash) {
          this.weaponMods.push(this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plug.plugHash));
        }
      });
    }
  }

  getMaxStat(stat: {definition: DestinyStatDefinition, value: DestinyStat}) {
    return stat.definition.statCategory === DestinyStatCategory.Defense ? 50 : 100;
  }

  applyStats(mod: DestinyInventoryItemDefinition) {
    mod.investmentStats.forEach((investment) => {
      if (this.isStatActive(mod, investment) && investment.value > 0) {
        if (!this.statsModded[investment.statTypeHash]) {
          this.statsModded[investment.statTypeHash] = 0;
        }
        this.statsModded[investment.statTypeHash] += investment.value;
      }
    });
  }

  computeStats(statGroupHash: number, stats: {[key: number]: DestinyInventoryItemStatDefinition | DestinyStat}) {
    const statGroup = this.dataService.getDefinitionById<DestinyStatGroupDefinition>('DestinyStatGroupDefinition', statGroupHash);
    this.mainStatGroup = statGroup;
    if (statGroup && statGroup.scaledStats?.length) {
      this.stats = statGroup.scaledStats.map((stat) => {
        const def = this.dataService.getDefinitionById<DestinyStatDefinition>('DestinyStatDefinition', stat.statHash);
        return {definition: def, value: stats[stat.statHash]};
      });
    }
    if (this.type === 'armor') {
      let totalModdedValue = 0;
      Object.keys(this.statsModded)
        .filter((statHash: string) => ![3578062600, 2399985800, 3344745325, 3779394102, 998798867].includes(parseInt(statHash, 10))) // Not a type cost stat
        .forEach((statHash: string) => {
          totalModdedValue += this.statsModded[statHash];
        });
      if (totalModdedValue > 0) {
        this.modded = totalModdedValue;
      } else {
        this.modded = 0;
      }
      this.total = {
        raw: this.stats.reduce((acc, cur) => acc + cur.value.value, 0) - (this.masterworked ? 12 : 0) - (this.modded > 0 ? this.modded : 0),
        masterworked: this.masterworked ? 12 : 0,
        modded: this.modded > 0 ? this.modded : 0
      };
    }
  }

  computeHiddenStats() {
    this.hiddenStatGroup = null;
    if (this.type === 'weapon') {
      this.hiddenStatGroup = Object.keys(this.item.stats.stats)
        .map((statHash) => parseInt(statHash, 10))
        .filter((statHash) => hiddenStatsHashes.includes(statHash))
        .map((statHash) => ({
          displayInterpolation: [
            { value: 0, weight: 0 },
            { value: 100, weight: 100 }
          ],
          statHash,
          displayAsNumeric: false,
          maximumValue: 100
        }));
    }
  }

  toggleItemLockState(item: DestinyRecipesItem) {
    this.lockingItem = true;
    const newState = !ItemHelper.isLocked(item);
    this.dataService.setItemLockState(item, newState).subscribe((data) => {
      this.lockingItem = false;
      if (data.Message === 'Ok') {
        if (newState === false) {
          (item as any).state &= ~ItemState.Locked;
          this.toast.success(this.translate.instant('SECTIONS.LOOT.comparator.ITEM_UNLOCKED'));
        } else {
          (item as any).state |= ItemState.Locked;
          this.toast.success(this.translate.instant('SECTIONS.LOOT.comparator.ITEM_LOCKED'));
        }
      }
    }, (err) => {
      logError(err);
      if (newState === false) {
        this.toast.error(this.translate.instant('SECTIONS.LOOT.comparator.ITEM_UNLOCKED_ERR'));
      } else {
        this.toast.error(this.translate.instant('SECTIONS.LOOT.comparator.ITEM_LOCKED_ERR'));
      }
      this.lockingItem = false;
    });
  }

  isStatActive(mod: DestinyInventoryItemDefinition, stat: DestinyItemInvestmentStatDefinition) {
    return ItemHelper.isPlugStatActive(this.instance, mod.hash, stat.statTypeHash, stat.isConditionallyActive);
  }

}
