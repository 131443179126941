import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DimSyncService } from '../../services/dim-sync.service';
import { DataService } from '../../services/data.service';
import { DestinyRecipesItem } from '../../types/destiny-recipes-item';
import { ItemAnnotation, TagValue } from '@destinyitemmanager/dim-api-types';
import { ItemHelper } from '../item.helper';

@Component({
  selector: 'app-small-tag-editor',
  templateUrl: './small-tag-editor.component.html',
  styleUrls: ['./small-tag-editor.component.scss']
})
export class SmallTagEditorComponent implements OnChanges {

  @Input() item: DestinyRecipesItem;
  annotation: ItemAnnotation;
  locking: boolean;

  get locked() {
    return ItemHelper.isLocked(this.item);
  }

  get dimSyncAvailable() {
    return Boolean(this.dimSyncService.profile);
  }

  constructor(
    public dimSyncService: DimSyncService,
    public dataService: DataService,
  ) { }

  ngOnChanges() {
    this.reload();
  }

  reload() {
    this.annotation = null;
    if (this.item) {
      this.annotation = this.dimSyncService.getAnnotationsForItem(this.item.itemInstanceId);
    }
  }

  updateTag(tag: TagValue) {
    if (this.dimSyncService.busy) { return; }
    this.dimSyncService.sendTagUpdates([{ action: 'tag', payload: { id: this.item.itemInstanceId, tag } }]).subscribe(() => this.reload());
  }

  toggleItemLock() {
    if (this.locking) { return; }
    this.locking = true;
    this.dataService.setItemLockState(this.item, !this.locked).subscribe({
      next: () => { this.locking = false; },
      error: () => { this.locking = false; }
    });
  }

}
