import { Component, OnInit } from '@angular/core';
import { ModalContentDialog } from '../../types';
import { ModaleComponent } from '../../common/modale/modale.component';
import { DataService } from '../../services/data.service';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import { GroupUserInfoCard } from 'bungie-api-ts/groupv2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit, ModalContentDialog {

  data: any;

  readonly modalReference: ModaleComponent;
  vaultJson: string;
  size: string;
  saved = false;
  memberships: GroupUserInfoCard[];

  bungieLink = `<a target="_blank" href="https://www.bungie.net/7/en/User/Account/Privacy">bungie.net/7/en/User/Account/Privacy</a>`;

  constructor(
    private dataService: DataService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.modalReference.confirmedDisabled = false;
    this.vaultJson = this.dataService.exportData({vaultSpace: this.data.vaultSpace});
    const bytes = this.vaultJson.length;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    this.size = (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    this.memberships = this.auth.validMemberships;
  }

  getData(): any {
    return null;
  }

  downloadVault() {
    const fileToSave = new Blob([this.vaultJson], { type: 'application/json'});
    const date = new Date(Date.now());
    saveAs(fileToSave, `vault_${this.auth.currentMemberShip.displayName}_${date.toISOString()}.json`);
    this.saved = true;
  }

  copied() {
    this.toastr.info(this.translate.instant('SECTIONS.VAULT_CLEANER.share-modal.COPIED'));
  }


}
