export enum DestinyItemCategory {
    ALL = undefined,
    ARMOR = 20,
    WEAPON = 1,
    WARLOCK = 21,
    HUNTER = 23,
    TITAN = 22,
    PRIMARY = 2,
    ENERGY = 3,
    POWER = 4,
    // Weapons categories
    W_AUTO_RIFLE = 5,
    W_REVOLVER = 6,
    W_PULSE = 7,
    W_SCOUT = 8,
    W_FUSION = 9,
    W_SNIPER = 10,
    W_SHOTGUN = 11,
    W_LMG = 12,
    W_ROCKET = 13,
    W_SIDEARM = 14,
    W_SWORDS = 54,
    W_GLAIVE = 3871742104,
    W_GRENADE_LAUNCHER = 153950757,
    W_LINEAR_FUSION = 1504945536,
    W_TRACE_RIFLE = 2489664120,
    W_BOW = 3317538576,
    W_SUBMACHINEGUN = 3954685534,
    A_HELMET = 45,
    A_ARMS = 46,
    A_CHEST = 47,
    A_LEGS = 48,
    A_CLASS = 49,
    // MISC
    GHOST_SHELL = 39,
    SPARROWS = 43,
    SHIPS = 42
}
