import { AuthConfig } from 'angular-oauth2-oidc-noscope';
import { environment } from '@Env';

export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://www.bungie.net',
  loginUrl: 'https://www.bungie.net/en/OAuth/Authorize',
  tokenEndpoint: 'https://www.bungie.net/Platform/App/OAuth/token/',
  redirectUri: environment.production ? environment.frontendUrl : 'https://localhost:4200/login',
  dummyClientSecret: environment.apiConfig.client_secret,
  clientId: environment.apiConfig.client_id,
  responseType: 'code',
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  omitScope: true,
  oidc: false,
};
