import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  critical: boolean;
  currentState: string;

  setCritical(critical: boolean, state?: string) {
    this.critical = critical;
    this.currentState = state;
  }

  isCritical() {
    return this.critical;
  }
}
