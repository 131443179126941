import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { GroupUserInfoCard } from 'bungie-api-ts/groupv2';
import { AuthService } from '../../services/auth.service';
import { DataService, UpdateStatus } from '../../services/data.service';
import { CoreService } from '@Services/core.service';
import { LoadingComponent } from '../../types';

@Component({
  selector: 'app-bungie-card',
  templateUrl: './bungie-card.component.html',
  styleUrls: ['./bungie-card.component.scss']
})
export class BungieCardComponent implements OnInit {

  @Input() loginText = 'COMMON.LOGIN';
  @Input() refreshing: boolean;
  @Input() small: boolean;
  @Output() refresh = new EventEmitter();
  loginLoading = true;

  get lastUpdate() {
    return this.dataService.lastUpdate;
  }

  get usingCache() {
    return this.dataService.lastUpdateStatus === UpdateStatus.CACHED;
  }

  get otherMbr(): GroupUserInfoCard[] {
    return this.authService.currentMemberShip.applicableMembershipTypes.map((type) => {
      return this.authService.membershipData.destinyMemberships.find((e) => e.membershipType === type);
    });
  }

  get isAuthenticated(): boolean {
    return this.authService.authenticated;
  }

  memberships: GroupUserInfoCard[];

  constructor(public authService: AuthService, public dataService: DataService, public core: CoreService) { }

  ngOnInit(): void {
    this.authService.authChanged.subscribe((status) => {
      if (status) {
        this.memberships = this.authService.validMemberships;
      }
    });
    if (this.authService.authenticated) {
      this.memberships = this.authService.validMemberships;
    }
    this.core.waitFor(LoadingComponent.AUTH).subscribe(() => {
      this.loginLoading = false;
    });
  }

  logout() {
    this.authService.logout();
    this.dataService.purge();
  }

  doRefresh() {
    this.refresh.emit();
  }

  login() {
    this.authService.login();
  }

  getMembershipName(item) {
    return `<div align="left"><img src="https://bungie.net${item.iconPath}" height="16px" style="margin-right: 10px"> ${item.displayName}</div>`;
  }

  selectMembership(membershipId) {
    this.authService.setMembership(membershipId);
    this.dataService.getInventory().subscribe();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event?.isTrusted && (event.target as any)?.nodeName === 'BODY' && event.code === 'KeyR' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
      this.doRefresh();
    }
  }
}
