import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rating'
})
export class RatingPipe implements PipeTransform {

  constructor() {}

  transform(value: number): string {
    if (value < 0) {
      return '-';
    }
    if (value < 25) {
      return 'F';
    }
    if (value < 50) {
      return 'D-';
    }
    if (value <= 55) {
      return 'D';
    }
    if (value <= 58) {
      return 'D+';
    }
    if (value <= 62) {
      return 'C-';
    }
    if (value <= 65) {
      return 'C';
    }
    if (value <= 69) {
      return 'C+';
    }
    if (value <= 72) {
      return 'B-';
    }
    if (value <= 75) {
      return 'B';
    }
    if (value <= 80) {
      return 'B+';
    }
    if (value <= 85) {
      return 'A-';
    }
    if (value <= 90) {
      return 'A';
    }
    if (value <= 95) {
      return 'A+';
    }
    return 'S';
  }
}
