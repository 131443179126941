import { Component, Input, OnInit } from '@angular/core';
import {
  DestinyInventoryItemDefinition,
  DestinyStatDefinition,
  DestinyStatDisplayDefinition,
  ItemState
} from 'bungie-api-ts/destiny2';
import { DestinyRecipesItem, DestinyRecipesPerksStats } from '@Types/destiny-recipes-item';
import { DataService } from '@Services/data.service';
import { ItemHelper } from '../item.helper';
import { recoilStatHash } from '@Utils/known-values';

@Component({
  selector: 'app-stat-bar',
  templateUrl: './stat-bar.component.html',
  styleUrls: ['./stat-bar.component.scss']
})
export class StatBarComponent implements OnInit {

  @Input() stat: DestinyStatDisplayDefinition;
  @Input() instance: DestinyRecipesItem;
  @Input() item: DestinyInventoryItemDefinition;
  @Input() comparisonActive: boolean;

  definition: DestinyStatDefinition;
  baseValue: number;
  totalValue: number;
  modValue: DestinyRecipesPerksStats[];
  totalModValue: number;
  masterworkedValue: number;
  masterworked: boolean;

  mods: Array<{left: number; value: DestinyRecipesPerksStats; width: number }>;

  displayStyle: 'bar' | 'numeric' | 'angle';
  statExists: boolean;

  constructor(private data: DataService) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.definition = this.data.getDefinitionById<DestinyStatDefinition>('DestinyStatDefinition', this.stat.statHash);
    this.displayStyle = this.stat.statHash === recoilStatHash ? 'angle' : (this.stat.displayAsNumeric ? 'numeric' : 'bar');
    const stat = ItemHelper.getStatValueFromInstance(this.instance || { definition: this.item } as DestinyRecipesItem, this.stat.statHash, this.data, this.stat);
    this.masterworked = this.instance?.isArmor && (this.instance.state & ItemState.Masterwork) !== 0;


    if (stat) {
      this.baseValue = stat.baseRolledStatValue;
      this.modValue = stat.modsStatValue;
      this.totalValue = stat.displayValue;
      this.masterworkedValue = stat.masterworkStatValue;
      this.totalModValue = stat.totalModsStatValue;
      this.mods = stat.modsStatValue.map((e, i) => {
        return {
          left: stat.modsStatValue.slice(0, i).reduce((acc, cur) => acc + (cur.statModifier / this.stat.maximumValue * 100), this.baseValue / this.stat.maximumValue * 100),
          width: e.statModifier / this.stat.maximumValue * 100,
          value: e,
        };
      });
      this.statExists = true;
    } else {
      this.statExists = false;
    }
  }

}
