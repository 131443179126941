import { Component, Input, OnInit } from '@angular/core';
import { ScoreService } from '../../services/score.service';
import { AuthService } from '@Services/auth.service';
import { CoreService } from '@Services/core.service';
import { LoadingComponent } from '../../types';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit {

  get percentage(): number {
    if (this.score < ScoreService.MAX_SCORE) {
      return (1 - (this.score / ScoreService.MAX_SCORE)) * 1000;
    } else if (this.score < ScoreService.MAX_SCORE * 2) {
      return (1 - (this.score - ScoreService.MAX_SCORE) / ScoreService.MAX_SCORE) * 1000;
    } else {
      return 0;
    }
  }

  get className(): 'red' | 'orange' | 'green' | 'blue' | 'gold' {
    if (this.score < ScoreService.ORANGE_THRESHOLD) {
      return 'red';
    } else if (this.score < ScoreService.BLUE_THRESHOLD) {
      return 'orange';
    } else if (this.score < ScoreService.GREEN_THRESHOLD) {
      return 'blue';
    } else if (this.score < ScoreService.GOLD_THRESHOLD) {
      return 'green';
    } else {
      return 'gold';
    }
  }

  @Input() score: number;
  @Input() textOverride: any;
  @Input() smallText: boolean;
  @Input() small: boolean;

  constructor(public auth: AuthService, public core: CoreService) { }

  ngOnInit(): void {
  }

  protected readonly LoadingComponent = LoadingComponent;
}
