<div class="search">
  <div class="input-ctn">
    <input type="text" placeholder="enter Bungie name" [(ngModel)]="autocompleteText" (ngModelChange)="modelUpdated()">
    <div class="loading" *ngIf="loading">
      <fa-icon [icon]="['fas', 'circle-notch']" animation="spin" size="lg"></fa-icon>
    </div>
  </div>
  <div class="result" *ngIf="result$ | async as results">
    <div class="no-result" *ngIf="searchDone && results.length === 0 && autocompleteText?.length && !loading">{{'SECTIONS.COMMON.user-autocomplete.NO_GUARDIAN_FOUND' | translate}}</div>
    <div class="result-user" *ngFor="let user of results" (click)="userSelected.emit(user)">{{user.bungieGlobalDisplayName}}<span class="code">#{{user.bungieGlobalDisplayNameCode}}</span></div>
  </div>
</div>
