
<div #tooltip class="equipment-tooltip" *ngIf="item" [style.top]="currentY" [style.left]="currentX" [ngClass]="tierTypeClass[item.inventory?.tierType]" >

  <div class="vault-cleaner-summary" *ngIf="tooltipInstance?.instance?.vaultCleanerResult && tooltipInstance.rules">
    <div class="toggler" [ngClass]="{'large': showTogglable}" (click)="showTogglable = !showTogglable">
      {{ 'SECTIONS.COMMON.equipment-tooltip.WHY_HERE' | translate }}
    </div>
    <div class="toggable-section" [ngClass]="{'show': showTogglable}">
      <div class="vc-content">
        <div class="vc-rule" *ngFor="let ruleResult of tooltipInstance.instance.vaultCleanerResult">
          <span class="vc-rule-name" *ngIf="!tooltipInstance.rules[ruleResult.rule]?.customization">{{('SECTIONS.VAULT_CLEANER.RULES_SHORT.' + ruleResult.rule) | translate}}</span>
          <span class="vc-rule-name" *ngIf="tooltipInstance.rules[ruleResult.rule]?.customization as custom">
            {{('SECTIONS.VAULT_CLEANER.RULES_SHORT.' + ruleResult.rule) | translate}}
            <strong>
              <ng-container *ngIf="custom.type === 'number' && custom.formControl.value <= custom.options.sliderMax && custom.formControl.value >= custom.options.sliderMin">: {{custom.formControl.value}}</ng-container>
              <ng-container *ngIf="custom.type === 'number' && custom.formControl.value > custom.options.sliderMax">: {{ 'SECTIONS.COMMON.equipment-tooltip.BEST' | translate }}</ng-container>
              <ng-container *ngIf="custom.type === 'number' && custom.formControl.value < custom.options.sliderMin">: {{ 'SECTIONS.COMMON.equipment-tooltip.IGNORE' | translate }}</ng-container>
              <ng-container *ngIf="custom.type === 'rating'">: {{custom.formControl.value}} </ng-container>
            </strong>
          </span>
          <span class="vc-rule-result" [ngClass]="'result-' + ruleResult.result.result" *ngIf="ruleResult.result">
            <span *ngIf="ruleResult.result.value">{{ruleResult.result.value}}</span>
            <fa-icon *ngIf="ruleResult.result.result === true" [icon]="['fas', 'check']"></fa-icon>
            <fa-icon *ngIf="ruleResult.result.result === undefined" [icon]="['fas', 'times']"></fa-icon>
            <fa-icon *ngIf="ruleResult.result.result === false" [icon]="['fas', 'trash']"></fa-icon>
          </span>
        </div>
        <div class="vc-legend">
          <div class="vc-legend-item">
            <fa-icon class="result-true" [icon]="['fas', 'check']"></fa-icon>
            <span>{{'SECTIONS.VAULT_CLEANER.LEGEND.KEEP' | translate}}</span>
          </div>
          <div class="vc-legend-item">
            <fa-icon class="result-undefined" [icon]="['fas', 'times']"></fa-icon>
            <span>{{'SECTIONS.VAULT_CLEANER.LEGEND.IGNORED' | translate}}</span>
          </div>
          <div class="vc-legend-item">
            <fa-icon class="result-false" [icon]="['fas', 'trash']"></fa-icon>
            <span>{{'SECTIONS.VAULT_CLEANER.LEGEND.TRASH' | translate}}</span>
          </div>
        </div>
        <div class="vc-legend-help">
          <span>{{'SECTIONS.VAULT_CLEANER.LEGEND.HELP' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="equipment-title">
    <h2 class="item-name" (click)="devVerbose()">
      <span>{{item.displayProperties.name}}</span>
      <div class="header-buttons">
        <div class="toggle-btn" (click)="showTogglable = !showTogglable" *ngIf="tooltipInstance?.instance?.vaultCleanerResult?.length"><fa-icon [icon]="['fas', 'tasks']"></fa-icon></div>
        <div class="close-btn" (click)="closeTooltip()"><fa-icon [icon]="['fas', 'times-circle']"></fa-icon> </div>
      </div>
    </h2>
    <div class="item-header-details" *ngIf="item.inventory">
      <span class="item-class">{{item.itemTypeDisplayName}}</span>
      <span class="item-rarity">{{item.inventory.tierTypeName}}</span>
    </div>

    <div class="links" *ngIf="tooltipInstance?.instance?.isWeapon || tooltipInstance?.instance?.isArmor || item.itemType === 3">
      <a [href]="'https://www.light.gg/db/items/' + item.hash" target="_blank"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAFNklEQVR42q2X2W9UVRzHDyj6IgYwGiQGkKUFNDGRpcAD4vLAP4BQeEMTAVPjAg3EiNUXHxB5YAsGEU2M+2MFmhaxBJB2urcznbm3y0w7hbYpaWa5dzoztx/740xhpJ07LfBNfsmZc8+Z374cNR0As4HVQLFt25+OjCS9pPqrSHTug/Q2bxfry84wTz1qAGsTicTR3t7e9tbW1lRTUxO1tbW0tAUgvAsChdC5CbreSTJ42kuy40iLycZHwbhgTNMfOjo6bI/HQ1VVFRcuXOD8+fOUl5dT42kCcwtcV3BDQY2CBgVGIfSVWiR9Z2saeelBme/s6+u7KZpevHgRYVpZWUlNTQ3t7e2EQiFu3x6GaDX0n4DQB+ArgrontTC1IshyGDoRjtnsmBbzVCp1MBAIOOMaX7p0CWEaiUQYHR0lNxyI10OwBOqf1oI0zIDw7jRObL9SzJwSc5/PR0VFBcK8rq6OaDTKtBFvBP+b2i0eBaHtkIrsz2f2YsMwHGEuZhdBHMchF6qjcCXmZhAbgnvvCiGWiFpsz8V82ViU92fMLszdFXRgtQHrDLAcXDAKwT2ZAH0MBo/fvNbMigkCxGKxHyXgMmZ31VxwaABm+TR9MQiQxxL+13VMGEvAbjv7v3gAVpummRCzS8Dl8/mvEZhrwjMdmuaZ8EcUd1jNUD9bZ0d4v93WyTo1DsuyjkqeS6pJtLvhkgULg/DcGC3oEdLrRWN02cIdwfe1FczlMNJ5eFz7p8Z87xffS567aX/FhoIemN8Li27C4ltCstZ7hT1w1XYLnAapEzo1B7/1ln3PnDvm93q9KfG9FJlceV5laebPh2FJPywbhIIhIVnLnnzTQvxt5YrHNPjW6oDsfjfZFKBIAdubm5sR8/v9fibDLxFY2C1aCiPNdNUwvBwRkrXsyTc5o93xW4TJESrRbujaDLBV2bZ9UKK/vPwvKa8TUu2zAQm4jJ/7RFvN8JUYvGoLyVr25JuckbP6zucDk6Ro/3EtQMdKSIQ+Uslksto0/LQ0NzI8PMw4auNQZMDjXh3pC0Lib9FUtNaMi9JCspY9+SZn5Kzc0Xc3GFAX5x6GftYuCCyEhP+IIhmupHsL+NZApIJxeOL68qyHFGCjAfWWmwCWWYoUh38VDJwgG2K+Qw/ggmeDujaUTcUFwDa6NunN0IdMhASU5P50glAK0xSDUMYounelqFG6n+PkLECFedJwfhhW9MDlKabhnWr41U/MZeBUOw1KFwmriVyQIlPoUoiE+fXpFKKjzFECEsY3mEszbijBDVJkFk1SiheP0T95S/Hee6U4IaU4A6/BesL7bGqVnmSsVtzwexTm3deM/szbjJqym5HV6M9qRtIaibeew3hRS+h/A0YTuKEsqx1/OaV2vDmj/VKwvd8pxQyVjestrGTg2C0aZkqQ6M7FqOtAssaAorwDiQPB3VkDycm+a55JBhLdlikm/F4aj7onhGPn7o5RuOo6klkyDd0/km1TbiAVKZUB8q4Q/rfAamTaiDeIK7OG0h3gxD5R+cFMnEipSIuky41MYAZL9Mg96gAueR6vE8tJwOm74tLwnjSp2D7t9ykiFmOnPCowlkvk6j+re0KKlaSqlFSp65r6j+k93zo5k/UwKYChU70xi2L1IJBnlTyvkGeWWQgNGbfcEJL1hN/6jLkC+g7ESfrPVNezSj0s5KFJsvNrBk+3IQ/QztfAXAntLwjpdedm6H5nBDkz0n242WCDetSQst3WRRHp9Nskuj9mxDyHHTiHrGGrfDtwkrlqGvgP9CgI5veEaPcAAAAASUVORK5CYII="> light.gg</a>
      <a [href]="'https://bray.tech/details/' + item.hash" target="_blank"><img src="assets/logo/braytech.svg"> Braytech</a>
      <div class="flex-spacer"></div>
      <app-dim-sync-tag-editor [instanceId]="tooltipInstance.instance.itemInstanceId" *ngIf="dimSyncAvailable && tooltipInstance?.instance"></app-dim-sync-tag-editor>
    </div>
  </div>
  <div class="red-band" *ngIf="tooltipInstance?.type === 'bounty' && tooltipInstance.bounty.notForSale">
    {{'SECTIONS.COMMON.equipment-tooltip.NOT_FOR_SALE' | translate}}
  </div>
  <div class="red-band" *ngIf="tooltipInstance?.type === 'bounty' && tooltipInstance.bounty.warning">
    {{'SECTIONS.COMMON.equipment-tooltip.BOUNTY_NEGATIVE_IMPACT' | translate}}
  </div>
  <div class="yellow-band" *ngIf="tooltipInstance?.type === 'bounty' && tooltipInstance.bounty.category.value === 0">
    {{'SECTIONS.COMMON.equipment-tooltip.REMOVED_BOUNTY' | translate}}
  </div>
  <div class="yellow-band" *ngIf="tooltipInstance?.type === 'bounty' && tooltipInstance.bounty.vendorState?.saleStatus > 0">
    {{ tooltipInstance.bounty.vendorState.saleStatus | cannotSaleReason }}
  </div>
  <div class="max-light" *ngIf="tooltipInstance?.type === 'bounty'">
    <span>{{'SECTIONS.COMMON.equipment-tooltip.XP_GRANTED' | translate}}</span>
    <span class="light-level">{{tooltipInstance.bounty.category.value}} {{'SECTIONS.COMMON.equipment-tooltip.XP' | translate}}</span>
  </div>
  <div class="dim-sync-actions" *ngIf="dimSyncAvailable && tooltipInstance?.instance">
    <app-dim-sync-notes [instanceId]="tooltipInstance?.instance?.itemInstanceId"></app-dim-sync-notes>
  </div>
  <div class="equipment-description ammo-type" *ngIf="tooltipInstance?.type ==='item'">
    <div class="equipment-info">
      <div class="equipment-power">
        <img alt="damage type" class="equipment-info-image" *ngIf="item.defaultDamageType" [src]="'assets/damageType/' + item.defaultDamageType + '.png'" />
        <span class="equipment-info-text" style="font-size: 24px" *ngIf="tooltipInstance?.instance?.powerLevel">{{tooltipInstance.instance.powerLevel}}</span>
      </div>
      <div class="equipment-defense" *ngIf="tooltipInstance?.instance?.isArmor">
        <span>{{'SECTIONS.COMMON.equipment-tooltip.DEFENSE' | translate}}</span>
      </div>
    </div>
    <span class="flex-spacer"></span>
    <div class="equipment-ammo" *ngIf="item.equippingBlock?.ammoType" [ngClass]="'ammo-' + item.equippingBlock?.ammoType">
      <img alt="equipment-info-image" class="equipment-info-image" [src]="'/assets/ammo/' + item.equippingBlock?.ammoType + '.png'">
      <span class="equipment-info-text ammo-name" [ngClass]="AmmoType[item.equippingBlock?.ammoType]">{{('SECTIONS.COMMON.equipment-tooltip.AMMO_TYPE.' + AmmoType[item.equippingBlock?.ammoType]) | translate}}</span>
    </div>
    <div class="armor-energy" *ngIf="tooltipInstance?.instance?.energy">
      <span>{{tooltipInstance.instance.energy.energyCapacity}}</span>
    </div>
    <div class="max-light" *ngIf="tooltipInstance?.type === 'item' && !!maxLight && maxLight < 9000">
      <span class="cap">{{'SECTIONS.COMMON.equipment-tooltip.POWER_CAP' | translate}}</span>
      <span class="light-level">
          <svg aria-hidden="true" focusable="false" class="light-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="currentColor" d="M22.962 8.863c-2.628-2.576-4.988-5.407-7.045-8.458l-0.123-0.193c-2.234 3.193-4.556 5.993-7.083 8.592l0.015-0.016c-2.645 2.742-5.496 5.245-8.542 7.499l-0.184 0.13c3.341 2.271 6.262 4.682 8.943 7.335l-0.005-0.005c2.459 2.429 4.71 5.055 6.731 7.858l0.125 0.182c4.324-6.341 9.724-11.606 15.986-15.649l0.219-0.133c-3.401-2.168-6.359-4.524-9.048-7.153l0.010 0.010zM18.761 18.998c-1.036 1.024-1.971 2.145-2.792 3.35l-0.050 0.078c-0.884-1.215-1.8-2.285-2.793-3.279l0 0c-1.090-1.075-2.28-2.055-3.552-2.923l-0.088-0.057c1.326-0.969 2.495-1.988 3.571-3.097l0.007-0.007c1.010-1.051 1.947-2.191 2.794-3.399l0.061-0.092c0.882 1.32 1.842 2.471 2.912 3.51l0.005 0.005c1.089 1.072 2.293 2.034 3.589 2.864l0.088 0.053c-1.412 0.905-2.641 1.891-3.754 2.994l0.002-0.002z"></path></svg>
        {{maxLight}}
      </span>
    </div>
  </div>
  <div class="equipment-description weapon-level" *ngIf="tooltipInstance?.instance?.crafting">
    <div class="objective weapon-level-objective">
      <div class="progress-objective">
        <div class="progress" [style.width]="(tooltipInstance.instance.crafting.progress * 100).toFixed(0) + '%'"></div>
        <span>{{dataService.weaponLevelText}} {{tooltipInstance.instance.crafting.level}}</span>
        <span class="value">{{tooltipInstance.instance.crafting.progress * 100 | number: '1.0-0'}}%</span>
      </div>
    </div>
  </div>
  <div class="equipment-description tracker" *ngIf="trackerObjective">
    <img [src]="trackerObjective.objectiveHash | itemIconUrl: 'DestinyObjectiveDefinition'" alt="tracker"/>
    <app-objective-description [objective]="trackerObjective"></app-objective-description>
    <div class="flex-spacer"></div>
    <span>{{trackerObjective.progress}}</span>
  </div>
  <div class="equipment-description padded" *ngIf="tooltipInstance?.type === 'bounty' && tooltipInstance.bounty && !noInstanceBounty">
    <div class="objective" *ngFor="let o of objectives.objectives" [ngClass]="{'complete': o.complete}">
      <div class="check-objective"></div>
      <div class="progress-objective">
        <div class="progress" [style.width]="(o.progress / o.completionValue * 100).toFixed(0) + '%'"></div>
        <app-objective-description [objective]="o"></app-objective-description>
        <span class="value">{{o.progress}}/{{o.completionValue}}</span>
      </div>
    </div>
  </div>
  <div class="equipment-description padded" *ngIf="tooltipInstance?.type === 'bounty' && tooltipInstance.bounty && noInstanceBounty">
    <div class="objective" *ngFor="let obj of objectiveDefinitions | keyvalue">
      <div class="check-objective"></div>
      <div class="progress-objective">
        <div class="progress"></div>
        <app-objective-description [objectiveDefinition]="obj.value"></app-objective-description>
        <span class="value">0/{{obj.value.completionValue}}</span>
      </div>
    </div>
  </div>
  <div class="equipment-description padded" *ngIf="item.displayProperties.description">
    {{item.displayProperties.description}}
  </div>
  <div class="equipment-description padded" *ngIf="tooltipInstance?.type === 'mod' && modPerkDefinition">
    {{modPerkDefinition.displayProperties.description}}
  </div>
  <div class="equipment-description energy-cost padded" *ngIf="item.plug?.energyCost">
    <img alt="energy type" [src]="'/assets/energyType/' + item.plug.energyCost.energyType + '.png'" *ngIf="item.plug.energyCost.energyType > 0">
    <span class="number" [ngClass]="'energy-' + item.plug.energyCost.energyType">{{item.plug.energyCost.energyCost}}</span>
    <span class="cost-text">{{'SECTIONS.COMMON.equipment-tooltip.ENERGY_COST' | translate}}</span>
  </div>
  <div class="equipment-description" *ngIf="tooltipInstance?.type === 'item' && !!tooltipInstance.instance">
    <div dr-item-stat [item]="item" [showPerksInitialState]="tooltipInstance.showPerksInitialState" [instance]="tooltipInstance.instance" [richPerks]="tooltipInstance.richPerks"></div>
  </div>
  <div class="equipment-description craftable-state" *ngIf="deepsightObjective && craftableState">
    <ng-container *ngIf="(craftableState.craftable && !craftableState.unlocked) else noPattern">
      <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
      <span>This weapon's <strong>Pattern</strong> can be extracted.</span>
    </ng-container>
    <ng-template #noPattern>
      <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
      <span>This weapon has <strong>Resonant Elements</strong> available to be extracted.</span>
    </ng-template>
  </div>
  <div class="equipment-description deepsight" *ngIf="deepsightObjective">
    <div class="objective" [ngClass]="{'complete': deepsightObjective.complete}">
      <div class="check-objective"></div>
      <div class="progress-objective">
        <div class="progress" [style.width]="(deepsightObjective.progress / deepsightObjective.completionValue * 100).toFixed(0) + '%'"></div>
        <app-objective-description [objective]="deepsightObjective"></app-objective-description>
        <span class="value">{{deepsightObjective.progress}}/{{deepsightObjective.completionValue}}</span>
      </div>
    </div>
  </div>
</div>

