import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestinyRecipesItem, DestinyRecipesPerk, DestinyRecipesPerkRank } from '../../types/destiny-recipes-item';
import { DestinyInventoryItemDefinition, DestinyPlugSetDefinition, TierType } from 'bungie-api-ts/destiny2';
import { DataService } from '../../services/data.service';
import { VoltronService } from '../../services/voltron.service';
import * as _ from 'lodash';
import { SocketHash } from '../../types';

@Component({
  selector: 'app-perk-list',
  templateUrl: './perk-list.component.html',
  styleUrls: ['./perk-list.component.scss']
})
export class PerkListComponent implements OnInit, OnChanges {

  @Input() item: DestinyRecipesItem;
  @Input() displayAllPossiblePerks: boolean;
  possiblePerks: DestinyRecipesPerk[][];

  constructor(
      private dataService: DataService,
      private voltronService: VoltronService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    this.possiblePerks = [];
    const socketEntriesIndexes = this.item.definition.sockets.socketCategories.find((e) => e.socketCategoryHash === 4241085061);
    socketEntriesIndexes.socketIndexes.forEach((weaponPerkColumnIndex, realColumnIndex) => {
      const perksInColumns: DestinyRecipesPerk[] = [];
      const plugSetHash = this.item.definition.sockets.socketEntries[weaponPerkColumnIndex].randomizedPlugSetHash || this.item.definition.sockets.socketEntries[weaponPerkColumnIndex].reusablePlugSetHash;
      if (plugSetHash) {
        perksInColumns.push(...this.dataService.getDefinitionById<DestinyPlugSetDefinition>('DestinyPlugSetDefinition', plugSetHash).reusablePlugItems.map((plug) => ({
          plugHash: plug.plugItemHash,
          active: this.item.perks[realColumnIndex].find((e) => e.plugHash === plug.plugItemHash)?.active,
          enhanced: this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plug.plugItemHash).inventory.tierType === TierType.Common,
          available: this.item.perks[realColumnIndex].findIndex((e) => e.plugHash === plug.plugItemHash) > -1,
          proficiency: this.getRollsForPerk(plug.plugItemHash)
        })));
      }
      if (this.item.definition.sockets.socketEntries[weaponPerkColumnIndex].socketTypeHash !== SocketHash.Trackers) {  // trackers
        if (this.displayAllPossiblePerks) {
          perksInColumns.sort(this.sortByProficiency);
          this.possiblePerks.push(_.uniqBy(perksInColumns, (e) => e.plugHash));
        } else {
          this.possiblePerks.push(_.uniqBy(perksInColumns.filter((p) => p.available), (p) => p.plugHash));
        }
      }
    });
  }

  sortByProficiency(a: DestinyRecipesPerk, b: DestinyRecipesPerk) {
    if (a.proficiency.pve && a.proficiency.pvp && (!b.proficiency.pvp || !b.proficiency.pve)) {
      return -1;
    }
    if (b.proficiency.pve && b.proficiency.pvp && (!a.proficiency.pvp || !a.proficiency.pve)) {
      return 1;
    }
    if (a.proficiency.pve && !b.proficiency.pve) {
      return -1;
    }
    if (b.proficiency.pve && !a.proficiency.pve) {
      return 1;
    }
    if (a.proficiency.pvp && !b.proficiency.pvp) {
      return -1;
    }
    if (b.proficiency.pvp && !a.proficiency.pvp) {
      return 1;
    }
    return 0;
  }

  getRollsForPerk(plugHash) {
    if (this.voltronService.voltronContent) {
      const voltronPerks = this.voltronService.voltronContent[this.voltronService.AdeptMatchings[this.item.itemHash] || this.item.itemHash];
      return {
        pvp: _.flatten(voltronPerks?.pvp).indexOf(plugHash) > -1 ? DestinyRecipesPerkRank.A : DestinyRecipesPerkRank.B,
        pve: _.flatten(voltronPerks?.pve).indexOf(plugHash) > -1 ? DestinyRecipesPerkRank.A : DestinyRecipesPerkRank.B
      };
    } else {
      return {
        pvp: DestinyRecipesPerkRank.B,
        pve: DestinyRecipesPerkRank.B
      };
    }
  }

}
