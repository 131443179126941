import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalConfig, ModalResponse, ModalServiceBackbone } from '../types';
import { ModaleComponent } from '../common/modale/modale.component';
import { logError } from '../console';

@Injectable({providedIn: 'root'})
export class ModalService implements ModalServiceBackbone {

  factoryResolver: ComponentFactoryResolver;
  rootViewContainer: ViewContainerRef;
  component: ComponentRef<ModaleComponent>;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  create<T = any, U = any>(modalConfig: ModalConfig<U>): Observable<ModalResponse<U>> {
    const factory = this.factoryResolver.resolveComponentFactory(ModaleComponent);
    this.component = factory.create(this.rootViewContainer.parentInjector);
    this.component.instance.setConfig(modalConfig);
    this.rootViewContainer.insert(this.component.hostView);
    this.component.instance.setService(this);
    return this.component.instance.response$;
  }

  destroy() {
    try {
      this.rootViewContainer.remove(this.rootViewContainer.indexOf(this.component.hostView));
      this.component.destroy();
    } catch (err) {
      logError(err);
    }
  }
}
