import { VaultCleanerConfigImport, VaultCleanerFactoryId } from './vault-cleaner.types';
import { UntypedFormControl } from '@angular/forms';

export const defaultConfig: VaultCleanerConfigImport = {
  weapons: {
    execute: true,
    groupArchetypes: false,
    groupElements: false,
    options: [
      {
        id: VaultCleanerFactoryId.WEAPONS_BASE,
        execute: true,
        hidden: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_ALL,
        execute: false
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_GODROLL_PVE,
        execute: true,
        customization: {
          formControl: new UntypedFormControl(101),
        },
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_GODROLL_PVP,
        execute: true,
        customization: {
          formControl: new UntypedFormControl(101),
        },
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_PREFER_POLY,
        execute: false,
        customization: {
          formControl: new UntypedFormControl(101),
        },
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_BEST_POWER_LEVEL,
        execute: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_CURATED,
        execute: false
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_EXOTICS,
        execute: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_MASTERWORK,
        execute: false
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_LOCKED,
        execute: false
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_SUNSET,
        execute: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_LOADOUT,
        execute: true,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_FAVORITE,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_KEEP,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_INFUSE,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_ARCHIVE,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.WEAPONS_DIM_REMOVE_JUNK,
        execute: false,
        dimSyncRequired: true
      }
    ]
  },
  armor: {
    execute: true,
    groupExotics: true,
    groupByBuild: true,
    groupByArtifice: true,
    options: [
      {
        id: VaultCleanerFactoryId.ARMOR_BASE,
        execute: true,
        hidden: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_ALL,
        execute: false
      },
      {
        id: VaultCleanerFactoryId.ARMOR_QUALITY,
        execute: true,
        customization: {
          formControl: new UntypedFormControl('Best'),
        },
      },
      {
        id: VaultCleanerFactoryId.ARMOR_BEST_POWER_LEVEL,
        execute: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_MASTERWORK,
        execute: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_EXOTICS,
        execute: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_RAID,
        execute: false
      },
      {
        id: VaultCleanerFactoryId.ARMOR_LOCKED,
        execute: false
      },
      {
        id: VaultCleanerFactoryId.ARMOR_SUNSET,
        execute: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_LOADOUT,
        execute: true,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_FAVORITE,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_KEEP,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_INFUSE,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_ARCHIVE,
        execute: false,
        dimSyncRequired: true
      },
      {
        id: VaultCleanerFactoryId.ARMOR_DIM_REMOVE_JUNK,
        execute: false,
        dimSyncRequired: true
      }
    ]
  }
};
