import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeUrl'
})
export class SanitizePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeStyle {
    if (value) {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`);
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(`url('~/assets/missing_icon.png')`);
    }
  }
}
