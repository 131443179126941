import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import {
  AuthTokenResponse,
  Checklist,
  Recipe,
  RecipeCreationDto,
  ServerChecklistResponse
} from '../types/destiny-recipes.api';
import { GeneralUser } from 'bungie-api-ts/user';
import { Observable, of } from 'rxjs';
import { RecipeApiResponse } from '../types';
import { RecipeHelper } from '@Types/recipe-helper';

@Injectable({
  providedIn: 'root'
})
export class RecipesApiService {

  private tokenResponse: AuthTokenResponse;

  get token() {
    return this.tokenResponse?.accessToken;
  }

  get bungieProfile(): GeneralUser {
    return this.tokenResponse?.bungieNetUser;
  }

  get connectedMembershipId(): string {
    return this.tokenResponse?.bungieNetUser?.membershipId;
  }

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  getToken(): Observable<string> {
    if (!this.token || (this.tokenResponse.expiresIn * 1000) < Date.now()) {
      return this.http.post(`${environment.recipesApi}/auth/token`, {}, {headers: {Authorization: this.auth.bearerToken}}).pipe(
        map((response: AuthTokenResponse) => {
          this.tokenResponse = response;
          return this.token;
        })
      );
    } else {
      return of(this.token);
    }
  }

  getHeaders(): Observable<HttpHeaders> {
    return this.getToken().pipe(map((token) => new HttpHeaders({Authorization: 'Bearer ' + token})));
  }

  getChecklist(membershipId: string): Observable<ServerChecklistResponse> {
    return this.http.get<ServerChecklistResponse>(environment.recipesApi + '/checklist/' + membershipId);
  }

  findChecklist(code: string): Observable<ServerChecklistResponse> {
    return this.http.get<ServerChecklistResponse>(environment.recipesApi + '/checklist/search/' + code);
  }

  createChecklist(checklist: Checklist) {
    return this.getHeaders().pipe(
      mergeMap((headers) => this.http.post<ServerChecklistResponse>(environment.recipesApi + '/checklist/' + this.connectedMembershipId, { checklist: JSON.stringify(checklist) }, {headers}))
    );
  }

  updateChecklist(checklist: Checklist) {
    const dto = {
      mode: 'update',
      checklist: JSON.stringify(checklist)
    };
    return this.getHeaders().pipe(
      mergeMap((headers) => this.http.put<ServerChecklistResponse>(environment.recipesApi + '/checklist/' + this.connectedMembershipId, dto, {headers}))
    );
  }

  subscribeToChecklist(checklistCode: string) {
    const dto = {
      mode: 'subscribe',
      code: checklistCode
    };
    return this.getHeaders().pipe(
      mergeMap((headers) => this.http.put<ServerChecklistResponse>(environment.recipesApi + '/checklist/' + this.connectedMembershipId, dto, { headers }))
    );
  }

  updateScore(score: number) {
    return this.getHeaders().pipe(
      mergeMap((headers) => this.http.put<any>(environment.recipesApi + '/score/' + this.connectedMembershipId, { score }, { headers }))
    );
  }
  /**
   *  Recipes Appraiser Resources
   */
  createRecipe(dto: RecipeCreationDto): Observable<Recipe> {
    return this.getHeaders().pipe(
      mergeMap((headers) => this.http.post<Recipe>(environment.recipesApi + '/recipes', dto, { headers }))
    );
  }

  getRecipes(): Observable<RecipeApiResponse<Recipe>> {
    return this.getHeaders().pipe(
      mergeMap((headers) => this.http.get<RecipeApiResponse<Recipe>>(environment.recipesApi + '/recipes', { headers }))
    );
  }

  getRecipeById(recipeId: string): Observable<Recipe> {
    return this.getHeaders().pipe(
      mergeMap((headers) => this.http.get<Recipe>(environment.recipesApi + '/recipes/' + recipeId, { headers }))
    );
  }

  uploadRecipeContent(recipeHelper: RecipeHelper) {
    return this.getHeaders().pipe(
      mergeMap((headers) => {
        const json = JSON.stringify(recipeHelper.content);
        const blob = new Blob([json], { type: 'application/json' });
        const file = new File([blob], recipeHelper.recipe._id + '.json', { type: 'application/json' });
        const formData = new FormData();
        formData.append('content', file, recipeHelper.recipe._id + '.json');
        formData.append('coverage', JSON.stringify(recipeHelper.getCoverageReport()));
        return this.http.post<any>(environment.recipesApi + '/recipes/' + recipeHelper.recipe._id + '/content', formData , { headers });
      })
    );
  }
}
