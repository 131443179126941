import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

export interface Notification {
  title: string;
  message?: string;
  style: 'info' | 'error' | 'success';
  loading?: boolean;
  timeout?: number;
}

export class NotificationHandler {
  public readonly id: string;
  private readonly state$: Subject<'close' | 'show'>;

  constructor(public content: Notification) {
    this.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    this.state$ = new Subject<'close' | 'show'>();
    if (content.timeout) {
      timer(content.timeout).subscribe(() => this.close());
    }
  }

  close() {
    this.state$.next('close');
  }

  show() {
    this.state$.next('show');
  }

  on(action: 'close' | 'show'): Observable<NotificationHandler> {
    return this.state$.pipe(filter((a) => a === action), share(), map(() => this));
  }
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationQueue = [];

  create(notif: Notification): NotificationHandler {
    const handler = new NotificationHandler(notif);
    this.notificationQueue.push(handler);
    handler.on('close').subscribe((instance) => {
      const idx = this.notificationQueue.findIndex((h) => instance.id === h.id);
      if (idx > -1) {
        this.notificationQueue.splice(idx, 1);
      }
    });
    return handler;
  }

  error(title: string, message: string, timeout: number): NotificationHandler {
    return this.create({title, message, timeout, style: 'error'});
  }

  loader(title: string, message: string): NotificationHandler {
    return this.create({title, message, loading: true, style: 'info'});
  }

  success(title: string, message: string, timeout: number): NotificationHandler {
    return this.create({title, message, timeout, style: 'success'});
  }

  info(title: string, message: string, timeout: number): NotificationHandler {
    return this.create({title, message, timeout, style: 'info'});
  }

  clearQueue() {
    this.notificationQueue = [];
  }
}
