import {
  DestinyClass,
  DestinyInventoryItemDefinition,
  DestinyItemType,
  DestinyPlugSetDefinition, DestinyStat, DestinyStatDisplayDefinition,
  ItemState, TierType
} from 'bungie-api-ts/destiny2';
import {
  DestinyRecipesItem,
  DestinyRecipesPerk,
  DestinyRecipesPerkRank, DestinyRecipesPerksStats,
  DestinyRecipesStat,
  RecoilValue
} from '../types/destiny-recipes-item';
import {
  adeptWeaponHashes,
  masterworksWithCondStats,
  modsWithConditionalStats,
  recoilStatHash
} from '../../utils/known-values';
import { ItemCategoryHashes, SocketCategoryHashes, StatHashes } from '../../utils/generated-enums';
import { DestinyItemCategory } from '../services/destinyItemCategory';
import { DataService } from '../services/data.service';
import * as _ from 'lodash';
import { watermarkToSeason } from '@Utils/watermark-to-season';
import { SocketHash } from '../types';

export class ItemHelper {

  public static getArchetype = ItemHelper.getIntrinsicPerk;

  public static isWeapon(item: DestinyInventoryItemDefinition) {
    return item?.itemType === DestinyItemType.Weapon;
  }

  public static isArmor(item: DestinyInventoryItemDefinition) {
    return item?.itemType === DestinyItemType.Armor;
  }

  public static setContextData(item: DestinyRecipesItem, data: any) {
    if (item) {
      if (!item.contextData) {
        item.contextData = {};
      }
      item.contextData = {...item.contextData, ...data};
    }
  }

  public static isLocked(item: DestinyRecipesItem): boolean {
    if (!item) {
      return false;
    }
    return (item?.state & ItemState.Locked) > 0;
  }

  public static isCraftable(item: DestinyRecipesItem, data: DataService): boolean {
    if (!item) {
      return false;
    }
    if (!data.allPatterns) {
      data.getAllPatterns();
    }
    return data.allPatterns.findIndex((i) => item.itemHash === i.crafting.outputItemHash) > -1;
  }

  public static hasSocket(item: DestinyRecipesItem, socketHash: number): boolean {
    if (!item) {
      return false;
    }
    return item.definition.sockets?.socketEntries?.findIndex((s) => s.socketTypeHash === socketHash) > -1;
  }

  public static isArtificeArmor(item: DestinyInventoryItemDefinition): boolean {
    if (!item || !ItemHelper.isArmor(item)) {
      return false;
    }
    return item.sockets?.socketEntries?.some((s) => s.singleInitialItemHash === 3727270518);
  }

  public static isPlugStatActive(
    item: DestinyRecipesItem,
    plugHash: number,
    statHash: number,
    isConditionallyActive: boolean,
  ): boolean {
    if (!isConditionallyActive) {
      return true;
    } else if (
      plugHash === modsWithConditionalStats.powerfulFriends ||
      plugHash === modsWithConditionalStats.radiantLight
    ) {
      // TODO Not supported
      return false;
    } else if (plugHash === modsWithConditionalStats.chargeHarvester) {
      return (
        (item.classType === DestinyClass.Hunter && statHash === StatHashes.Mobility) ||
        (item.classType === DestinyClass.Titan && statHash === StatHashes.Resilience) ||
        (item.classType === DestinyClass.Warlock && statHash === StatHashes.Recovery)
      );
    } else if (masterworksWithCondStats.includes(plugHash)) {
      return adeptWeaponHashes.includes(item.itemHash);
    } else {
      return true;
    }
  }

  public static getPossiblePerksOfWeapon(item: DestinyInventoryItemDefinition, dataService: DataService, options?: {noTracker: boolean}): DestinyRecipesPerk[][] {
    if (item?.sockets && item.itemCategoryHashes.indexOf(DestinyItemCategory.WEAPON) > -1) {
      const socketEntriesIndexes = item.sockets.socketCategories.find((e) => e.socketCategoryHash === SocketCategoryHashes.WeaponPerks_Reusable);
      if (socketEntriesIndexes) {
        const possiblePerks = [];
        socketEntriesIndexes.socketIndexes.forEach((weaponPerkColumnIndex, realColumnIndex) => {
          if (options?.noTracker && item.sockets.socketEntries[weaponPerkColumnIndex].socketTypeHash === SocketHash.Trackers) {
            return;
          }
          const perksInColumns: DestinyRecipesPerk[] = [];
          const plugSetHash = item.sockets.socketEntries[weaponPerkColumnIndex].randomizedPlugSetHash || item.sockets.socketEntries[weaponPerkColumnIndex].reusablePlugSetHash;
          if (plugSetHash) {
            perksInColumns.push(...dataService.getDefinitionById<DestinyPlugSetDefinition>('DestinyPlugSetDefinition', plugSetHash).reusablePlugItems.map((plug) => ({
              plugHash: plug.plugItemHash,
              active: false,
              proficiency: { pve: DestinyRecipesPerkRank.B, pvp: DestinyRecipesPerkRank.B },
              available: true,
              enhanced: dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plug.plugItemHash).inventory.tierType === TierType.Common,
              realSocketEntriesIndex: weaponPerkColumnIndex,
              plugCategoryHash: dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plug.plugItemHash).plug.plugCategoryHash
            })));
          }
          possiblePerks.push(_.uniqBy(perksInColumns, 'plugHash'));
        });
        return possiblePerks;
      }
    }
    return [];
  }

  public static getIntrinsicPerkHash(item: DestinyInventoryItemDefinition) {
    const intrinsicPerk = item.sockets.socketCategories.find((e) => e.socketCategoryHash === 3956125808);
    if (intrinsicPerk?.socketIndexes) {
      return item.sockets.socketEntries[intrinsicPerk.socketIndexes[0]].singleInitialItemHash;
    }
  }

  public static getIntrinsicPerk(item: DestinyInventoryItemDefinition, dataService: DataService) {
    const intrinsicPerk = item?.sockets?.socketCategories?.find((e) => e.socketCategoryHash === 3956125808);
    if (intrinsicPerk?.socketIndexes) {
      const perk = dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', item.sockets.socketEntries[intrinsicPerk.socketIndexes[0]].singleInitialItemHash);
      (perk as any).instrinsic = true;
      return perk;
    }
  }

  public static getRecoilValueFromStat(recoil: DestinyStat): RecoilValue {
    if (!Boolean(recoil)) { return undefined; }
    const angle = Math.sin((recoil.value + 5) * (Math.PI * 2 / 20)) * (100 - recoil.value);
    return {
      angle,
      bounciness: 100 - recoil.value,
      mappedBounciness: 0, // ((5 * (recoil.value - 40))) / 3,
      value: recoil.value
    };
  }

  public static getRecoilValueFromDefinition(item: DestinyInventoryItemDefinition): RecoilValue {
    if (item.itemType === DestinyItemType.Weapon) {
      const recoil = item.stats.stats[recoilStatHash];
      return ItemHelper.getRecoilValueFromStat(recoil);
    }
  }

  public static getRecoilValueFromItem(item: DestinyRecipesItem, dataService: DataService): RecoilValue {
    if (item.isWeapon) {
      const recoil = ItemHelper.getStatValueFromInstance(item, recoilStatHash, dataService);
      return ItemHelper.getRecoilValueFromStat({value: recoil.displayValue, statHash: recoilStatHash });
    }
  }

  public static getAllBonusModsForStat(instance: DestinyRecipesItem, statHash: number, data: DataService, plugCategoryHash: number): DestinyRecipesPerksStats[] {
    if (!instance?.currentPlugs) { return []; }
    const out: DestinyRecipesPerksStats[] = [];
    const plugs = instance.currentPlugs;
    const socketsWhitelist = instance.definition.sockets.socketCategories.find((s) => s.socketCategoryHash === plugCategoryHash)?.socketIndexes || [];
    socketsWhitelist.forEach((socketIndex) => {
      const perkApplied = data.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plugs.sockets[socketIndex].plugHash);
      const statModifier = perkApplied?.investmentStats?.find((s) => s.statTypeHash === statHash);
      if (statModifier && ItemHelper.isPlugStatActive(instance, plugs.sockets[socketIndex].plugHash, statHash, statModifier.isConditionallyActive)) {
        out.push({ perkApplied, statModifier: statModifier.value });
      }
    });
    return out;
  }

  public static computeStatFromPlugCategory(instance: DestinyRecipesItem, statHash: number, data: DataService, plugCategoryHash: number) {
    if (!instance?.currentPlugs) { return 0; }
    let out = 0;
    const plugs = instance.currentPlugs;
    const socketsWhitelist = instance.definition.sockets.socketCategories.find((s) => s.socketCategoryHash === plugCategoryHash)?.socketIndexes || [];
    socketsWhitelist.forEach((socketIndex) => {
      const perkApplied = data.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plugs.sockets[socketIndex].plugHash);
      const statModifier = perkApplied?.investmentStats?.find((s) => s.statTypeHash === statHash);
      if (statModifier && ItemHelper.isPlugStatActive(instance, plugs.sockets[socketIndex].plugHash, statHash, statModifier.isConditionallyActive)) {
        out += statModifier.value;
      }
    });
    return out;
  }

  public static getStatValueFromInstance(instance: DestinyRecipesItem, statHash: number, data: DataService, statDisplayDefinition?: DestinyStatDisplayDefinition): DestinyRecipesStat {
    /** Get the stat value from the definition of the item. Weapons have one, exotic armor has bonus base stats here */
    const investmentStat = instance.definition.investmentStats.find((s) => s.statTypeHash === statHash);
    const baseStatInDefinition = investmentStat?.value || 0;
    /** For armors, compute all stats from the base modifier rolled (3154740035) */
    let baseRolledStatValue = baseStatInDefinition;
    let modsStatValue = [];
    let masterworkStatValue = 0;
    if (instance.isArmor) {
      baseRolledStatValue += ItemHelper.computeStatFromPlugCategory(instance, statHash, data, SocketCategoryHashes.ArmorPerks_LargePerk);
      modsStatValue = ItemHelper.getAllBonusModsForStat(instance, statHash, data, SocketCategoryHashes.ArmorMods);
      masterworkStatValue = instance.masterworked ? 2 : 0;
    } else if (instance.isWeapon) {
      modsStatValue = ItemHelper.getAllBonusModsForStat(instance, statHash, data, SocketCategoryHashes.WeaponMods);
    }
    /** Compute all bonus stats from mods */
    const totalModsStatValue = modsStatValue.reduce((acc, curr) => acc + curr.statModifier, 0);

    return {
      baseRolledStatValue: ItemHelper.interpolateStatBonus(statDisplayDefinition, baseRolledStatValue),
      modsStatValue,
      masterworkStatValue,
      totalModsStatValue,
      displayValue: ItemHelper.interpolateStatBonus(statDisplayDefinition, baseRolledStatValue + totalModsStatValue + masterworkStatValue),
    };
  }

  public static getSeason(item: DestinyInventoryItemDefinition): number {
    if (item?.iconWatermark) {
      return watermarkToSeason[item.iconWatermark];
    }
  }

  public static hasRandomRoll(item: DestinyRecipesItem | DestinyInventoryItemDefinition): boolean {
    if (ItemHelper.isInstance(item)) {
      return Boolean(item.energy) || item.definition.sockets?.socketEntries.some((s) => Boolean(s.randomizedPlugSetHash));
    } else {
      return item.sockets?.socketEntries.some((s) => Boolean(s.randomizedPlugSetHash));
    }
  }

  /** returns the %age of the stat that is a bonus */
  public static interpolateStatBonus(stat: DestinyStatDisplayDefinition, bonusValue: number) {
    if (typeof bonusValue === 'undefined') {
      return 0;
    }
    const value = Math.abs(bonusValue);
    if (!stat?.displayInterpolation) {
      // No interpolation, just return the value
      return value;
    }
    let idx = 0;
    while (idx + 1 < stat.displayInterpolation.length && stat.displayInterpolation[idx + 1].value <= value) {
      idx++;
    }
    const minRange = stat.displayInterpolation[idx];
    let maxRange = stat.displayInterpolation[idx];
    if (idx + 1 < stat.displayInterpolation.length) {
      maxRange = stat.displayInterpolation[idx + 1];
    }
    if ((minRange === maxRange) || (bonusValue === 0 && stat.displayInterpolation.length > 2)) {
      return minRange.weight;
    }
    return (Math.sign(bonusValue)) * Math.floor((((maxRange.weight - minRange.weight) * (value - minRange.value)) / (maxRange.value - minRange.value)) + minRange.weight);
  }

  public static isInstance(item: DestinyRecipesItem | DestinyInventoryItemDefinition): item is DestinyRecipesItem {
    return Object.prototype.hasOwnProperty.call(item, 'isWeapon') || Object.prototype.hasOwnProperty.call(item, 'isArmor');
  }
}
