import { Component, Input } from '@angular/core';
import { DestinyInventoryItemDefinition, DestinyItemInvestmentStatDefinition } from 'bungie-api-ts/destiny2';
import { CoreService } from '../../services/core.service';
import { DestinyRecipesItem } from '../../types/destiny-recipes-item';
import { ItemHelper } from '../item.helper';

@Component({
  selector: 'app-mod-slot',
  templateUrl: './mod-slot.component.html',
  styleUrls: ['./mod-slot.component.scss']
})
export class ModSlotComponent {

  @Input() mod: DestinyInventoryItemDefinition;
  @Input() item: DestinyRecipesItem;

  constructor(private coreService: CoreService) { }

  devVerbose() {
    if (this.coreService.devMode) {
      console.log(this.mod);
    }
  }

  isStatActive(stat: DestinyItemInvestmentStatDefinition) {
    return ItemHelper.isPlugStatActive(this.item, this.mod.hash, stat.statTypeHash, stat.isConditionallyActive);
  }

}
