import { DataService } from '../services/data.service';
import { DestinyRecipesItem, DestinyRecipesItemHelper } from '../types/destiny-recipes-item';
import * as _ from 'lodash';

export enum DestinyRecipesWatcherType {
  FARMING_MODE = 'FARMING_MODE',
  BEST_POWER = 'BEST_POWER',
  ARMOR_QUALITY = 'ARMOR_QUALITY',
  ROLL_QUALITY = 'ROLL_QUALITY',
  ARMOR_QUALITY_TAGGER = 'ARMOR_QUALITY_TAGGER'
}

export interface DestinyRecipesWatcherConfig {
  watcherType: DestinyRecipesWatcherType;
  data: any;
}

export abstract class DestinyRecipesWatcher {

  config: DestinyRecipesWatcherConfig;
  started: boolean;
  watchFrom: string;

  protected constructor(public type: DestinyRecipesWatcherType, protected dataService: DataService) {}

  setConfig(config: DestinyRecipesWatcherConfig) {
    this.config = config;
    this.checkConfig();
  }

  start() {
    if (this.started) {
      return;
    }
    this.checkConfig();
    this.beforeStart();
    this.getMostRecentItem();
    this.started = true;
    console.log(`[Watcher ${this.type}] Started`);
    this.notify();
  }

  stop() {
    this.beforeStop();
    this.started = false;
    console.log(`[Watcher ${this.type}] Stopped`);
  }

  restart() {
    this.started = false;
    this.beforeStart();
    this.started = true;
    console.log(`[Watcher ${this.type}] Restarted`);
    this.notify();
  }

  getMostRecentItem() {
    const lastItem: DestinyRecipesItem = _.maxBy(this.dataService.inventory.filter((e) => e.isWeapon || e.isArmor), DestinyRecipesItemHelper.getItemRecencyIdentifier);
    this.watchFrom = DestinyRecipesItemHelper.getItemRecencyIdentifier(lastItem);
  }

  public notify() {
    try {
      this.inventoryUpdated();
    } catch (err) {
      console.error(err);
      this.started = false;
    }
  }

  protected afterUpdate() {
    console.log('Watcher ' + this.type + ' updated');
    this.getMostRecentItem();
  }

  protected abstract inventoryUpdated();

  protected abstract checkConfig();

  protected abstract beforeStart();

  protected abstract beforeStop();

}
