<div class="social-ctn">
  <div class="social">
    <div class="row">
      <a href="https://twitter.com/D2Recipes" target="_blank" class="social-button twitter"><fa-icon [icon]="['fab', 'twitter']"></fa-icon>&#64;D2Recipes</a>
      <a href="https://discord.gg/cX8R6wPRB8" target="_blank" class="social-button discord"><fa-icon [icon]="['fab', 'discord']"></fa-icon>{{'SECTIONS.COMMON.social.JOIN' | translate}}</a>
    </div>

    <div class="bug"><i>{{'SECTIONS.COMMON.social.BUG' | translate}}<a href="https://discord.gg/mbcEaA72XS" target="_blank">{{'SECTIONS.COMMON.social.REPORT' | translate}}</a></i></div>
  </div>
</div>
