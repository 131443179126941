<div class="simulation-details" *ngIf="rating; else noRating">
  <div class="item">
    <img [src]="'https://www.bungie.net' + simulation.item.definition.screenshot" [alt]="simulation.item.definition.displayProperties.name">
    <div class="perks" *ngIf="simulation.item.perks">
      <ng-container *ngFor="let perkColIdx of perksColumnIndexes">
        <app-weapon-perk size="lg" [perk]="simulation.item.perks[perkColIdx][0]" *ngIf="simulation.item.perks[perkColIdx]?.length > 0; else emptyPerkSlot"></app-weapon-perk>
      </ng-container>
    </div>
  </div>

  <div class="ratings pve" [ngClass]="{'notApplicable': rating.notAvailable.pve}">
    <img src="assets/rolls/pve.png" alt="PvE">
    <div class="rating-score" *ngIf="!rating.notAvailable.pve; else notApplicableTemplate">
      <span>{{rating.raw.pve}}/{{rating.maxScore.pve}}</span> <span>({{rating.pve * 100 | number: '1.0-0'}}%)</span>
    </div>
  </div>

  <div class="perk-rating-detail pve" *ngFor="let perkEntry of rating.details.pve | keyvalue">
    <app-destiny-item [isPerk]="true" [item]="perkEntry.value.perk"></app-destiny-item>
    <span class="score-diff" [ngClass]="perkEntry.value.score < 0 ? 'neg' : 'pos'">
      <span>{{perkEntry.value.score}}</span> x <span>{{perkEntry.value.weight}}</span> = <span>{{perkEntry.value.score * perkEntry.value.weight}}</span>
      <br>
      <span>max. {{rating.maxScore.details.pve[perkEntry.key] * perkEntry.value.weight}}</span>
    </span>
  </div>

  <div class="ratings pvp" [ngClass]="{'notApplicable': rating.notAvailable.pvp}">
    <img src="assets/rolls/pvp.png" alt="PvP">
    <div class="rating-score" *ngIf="!rating.notAvailable.pvp; else notApplicableTemplate">
      <span>{{rating.raw.pvp}}/{{rating.maxScore.pvp}}</span> <span>({{rating.pvp * 100 | number: '1.0-0'}}%)</span>
    </div>
  </div>
  <div class="perk-rating-detail pvp" *ngFor="let perkEntry of rating.details.pvp | keyvalue">
    <app-destiny-item [isPerk]="true" [item]="perkEntry.value.perk"></app-destiny-item>
    <span class="score-diff" [ngClass]="perkEntry.value.score < 0 ? 'neg' : 'pos'">
      <span>{{perkEntry.value.score}}</span> x <span>{{perkEntry.value.weight}}</span> = <span>{{perkEntry.value.score * perkEntry.value.weight}}</span>
      <br>
      <span>max. {{rating.maxScore.details.pve[perkEntry.key] * perkEntry.value.weight}}</span>
    </span>
  </div>
</div>
<ng-template #notApplicableTemplate>
  <span>n/a</span>
</ng-template>
<ng-template #noRating>
  <p class="info">No ratings available.</p>
</ng-template>
<ng-template #emptyPerkSlot>
  <div class="empty-perk"></div>
</ng-template>
