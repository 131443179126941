import { Pipe, PipeTransform } from '@angular/core';
import { VendorItemStatus } from "bungie-api-ts/destiny2";

@Pipe({
  name: 'cannotSaleReason'
})
export class CannotSalePipe implements PipeTransform {

  constructor() {}

  transform(saleState: VendorItemStatus): string {
    switch (saleState) {
      case VendorItemStatus.NoInventorySpace:
        return 'Bounty limit reached';
      case VendorItemStatus.NoFunds:
        return 'Can\'t buy: too expensive';
      case VendorItemStatus.NoProgression:
        return 'You are missing some requirements';
      case VendorItemStatus.NoUnlock:
        return 'Already done this week';
      case VendorItemStatus.NoQuantity:
        return 'NoQuantity';
      case VendorItemStatus.OutsidePurchaseWindow:
        return 'This bounty is not available anymore';
      case VendorItemStatus.NotAvailable:
        return 'This bounty is not available';
      case VendorItemStatus.UniquenessViolation:
        return 'You can hold only one of those bounties';
      case VendorItemStatus.AlreadySelling:
        return 'Already selling';
      case VendorItemStatus.Unsellable:
        return 'Unsellable';
      case VendorItemStatus.SellingInhibited:
        return 'The vendor does not feel well';
      case VendorItemStatus.DisplayOnly:
        return 'This bounty is for display only';
      case VendorItemStatus.AlreadyOwned:
        return 'You already own this bounty';
      case VendorItemStatus.UnknownError:
      default:
        return 'Unknown error';
    }
  }
}
