<div class="item" [ngClass]="{'iconOnly': iconOnly, 'perk': isPerk, 'intrinsic': isIntrinsic, 'masterwork': isMasterWorked, 'bounty': !!bounty, 'bounty-warning': bounty?.warning, 'lg': size === 'lg'}"  *ngIf="item" (click)="devVerbose()">
  <div class="item-icon">
    <div class="image" [style.backgroundImage]="iconUrl | sanitizeUrl">
      <img *ngIf="watermarkIconUrl" alt="watermark season" class="seasonal-watermark" [src]="watermarkIconUrl">
      <img *ngIf="bounty && bounty.complete" alt="bounty icon" class="completedOverlay" src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTMgMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY5LjY0Mzc3IC0xMzYuNTE3MjkpIj48cGF0aCBkPSJtNjkuNjQzNzcxIDE0OS41MTcyOWgxMi45OTk5OTl2LTEzIiBmaWxsPSIjYjY4OTQzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBkPSJtNTQuODE2NzYyIDE4Ny40MDkwNnYxLjM2NDE3aC43NzkwNjV2LTEuMzY0MTd6bS43Njk0ODctMy4wNjM2NHYtMS43NTk3OWgtLjc1OTkwOXYxLjc1OTc5bC4xOTk5NzYgMi40ODc3MWguMzU5OTU3eiIgZmlsbD0iI2ZmZiIgdHJhbnNmb3JtPSJtYXRyaXgoMS4zMjMwNzQyIDAgMCAuNzU1ODE1NDggNS44MzE2MzMgNS4yOTE2NjcpIi8+PC9nPjwvc3ZnPgo=">
    </div>
  </div>
  <div class="item-name" *ngIf="!iconOnly">
    <span class="name">{{item.displayProperties.name}}</span>
    <span class="description" *ngIf="isPerk">{{item.itemTypeDisplayName}}</span>
  </div>
</div>
<div class="item" [ngClass]="{'lg': size === 'lg', 'iconOnly': iconOnly, 'perk': isPerk, 'intrinsic': isIntrinsic, 'masterwork': isMasterWorked, 'deepsight': inventoryItem.deepsight}"  *ngIf="inventoryItem"  (click)="devVerbose()">
  <div class="item-icon">
    <div class="image" [style.backgroundImage]="iconUrl | sanitizeUrl">
      <img *ngIf="watermarkIconUrl" alt="watermark season" class="seasonal-watermark" [src]="watermarkIconUrl">
      <div class="state-icons">
        <img class="state-icon" alt="crafted" *ngIf="inventoryItem.crafted" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA8iAAAPIgH6p8NtAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAj5JREFUWIXdl79rU1EUxz/n5WFqo4XUoX+AVqSSdLBNBwfbzaA4NDq66dZNRF0UXSw42kncHEvtIpai0Ixqupjgj2r/ABGsKM0PJbnHoRoQ3vPdEyIVz/R433O/58v3vHvfPVItFZSeQm/llyo3AKqlyZsg13thCXor3rfQAOjRgf4ICH8KEMOir0BHNGj9eiEatFT0M5AChiwKpFoqdDC0QmAyt/RiPQqrlQoTChVD/c6ut8AsIAi03QsWu8a6oI18iUfDbSOd2QHHQOtjPJz+ADiLAqMAeTf+oFqPQ48ulreBTUN9NbVAcY8Sk0QfWwX4OtAm0PuJ9Tupe4D3x2gRcHd8cX0jKSm3/OwNoguenJ4tEH2SCupXPUlJSeMKyFMv6trs5CUNJIyUp9LCuY38cmVVjOeFgtTOTpxUTR0W0YHI4s5+bvx/EdkCVUJB9yDSUNXXW58yKzPlssmutenpcPhAvSgiY6gOKvJd5PfdIU7bUi0VmkBkj7qCYG2wsVUcXdn85lP8ffFQurl3eBXhREJqy2sXCMw0M9l5n1yAZiY771Ec2DkH/C4jKnPV0rEjSWmvzk2NoTLnxQniLwBClfBCUlJH3UUgcltHhelfIKqnk3PklIXS4gDA6Mvz+Uwc+PbM8f0KB/+mgEAa6ZE4sJN2IxhdNd+IUsGfbr3tfUY6swM4F/3fSMLiwiygzyHmuYDuYCJ3cg+f3waozU5dU9HL2AcTF2J3YAhAxXWP751nyRp5gH+gBbs9HcsP0AS/Ua5DBDoAAAAASUVORK5CYII=">
        <fa-icon *ngIf="isLocked" [icon]="['fas', 'lock']" size="sm"></fa-icon>
        <fa-icon *ngIf="hasNotes" [icon]="['fas', 'sticky-note']" size="sm"></fa-icon>
        <fa-icon *ngIf="tag" [icon]="tag" size="sm"></fa-icon>
      </div>
      <div class="armor-rating" *ngIf="inventoryItem?.armorAppraisal && inventoryItem.armorAppraisal.rating !== 'NA' && inventoryItem.armorAppraisal.rating !== 'UNKNOWN'">
        <div><span>{{inventoryItem.armorAppraisal.rating}}</span></div>
      </div>
    </div>
    <div class="item-score" *ngIf="displayScore && inventoryItem?.score && scoreDisplayType === 'percentage'">
      <div class="score score-pve" [ngClass]="{'highlight': inventoryItem?.contextData?.goodRoll === 'pve' || inventoryItem?.contextData?.goodRoll === 'both'}"><img src="assets/rolls/pve.png">{{inventoryItem.score?.pve < 0 ? '-' : (((inventoryItem.score?.pve || 0) * 100 | number: '1.0-0') + '%')}}</div>
      <div class="score score-pvp" [ngClass]="{'highlight': inventoryItem?.contextData?.goodRoll === 'pvp' || inventoryItem?.contextData?.goodRoll === 'both'}"><img src="assets/rolls/pvp.png">{{inventoryItem.score?.pvp < 0 ? '-' : (((inventoryItem.score?.pvp || 0) * 100 | number: '1.0-0') + '%')}}</div>
      <div class="score score-poly" *ngIf="showPoly" [ngClass]="{'highlight': inventoryItem?.contextData?.goodRoll === 'both'}"><fa-icon [icon]="['fas', 'heart']" size="sm"></fa-icon>{{inventoryItem.score?.polyvalent < 0 ? '-' : (((inventoryItem.score?.polyvalent || 0) * 100 | number: '1.0-0') + '%')}}</div>
    </div>
    <div class="item-score" *ngIf="displayScore && inventoryItem?.score && scoreDisplayType === 'rating'">
      <div class="score score-pve"><img src="assets/rolls/pve.png">{{(inventoryItem.score?.pve || 0) * 100 | rating}}</div>
      <div class="score score-pvp"><img src="assets/rolls/pvp.png">{{(inventoryItem.score?.pvp || 0) * 100 | rating}}</div>
      <div class="score score-poly" *ngIf="showPoly"><fa-icon [icon]="['fas', 'trophy']" size="sm"></fa-icon>{{(inventoryItem.score?.polyvalent || 0) * 100 | rating}}</div>
    </div>
    <div class="item-score" *ngIf="displayScore && ((inventoryItem?.checkedAttributes || {}) | keyvalue)?.length">
      <div class="checked-attr" *ngIf="inventoryItem?.checkedAttributes?.bestTotal">{{'SECTIONS.COMMON.destiny-item.BEST_TOTAL' | translate}}</div>
      <div class="checked-attr" *ngIf="inventoryItem?.checkedAttributes?.isHighestPower">{{'SECTIONS.COMMON.destiny-item.BEST_POWER' | translate}}</div>
      <ng-container *ngIf="inventoryItem?.armorAppraisal">
      </ng-container>
    </div>
  </div>
  <div class="item-name" *ngIf="!iconOnly">
    {{inventoryItem.definition.displayProperties.name}}
  </div>
</div>

