import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DestinyRecipesPerk, DestinyRecipesPerkRank } from '../../types/destiny-recipes-item';
import { DataService } from '../../services/data.service';
import { DestinyInventoryItemDefinition } from 'bungie-api-ts/destiny2';
import { VoltronService } from '../../services/voltron.service';
import * as _ from 'lodash';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-weapon-perk',
  templateUrl: './weapon-perk.component.html',
  styleUrls: ['./weapon-perk.component.scss']
})
export class WeaponPerkComponent implements OnInit {

  @Input() perk: DestinyRecipesPerk;
  @Input() itemHash: number;
  @Input() lowlightUnavailable: boolean;
  @Input() highlights: number[];
  @Input() displayType: 'circle' | 'row' = 'circle';
  @Input() disablePopup: boolean;
  @Input() noPopupOnHover: boolean;
  @Input() size: 'lg' | 'sm' = 'sm';

  @Output() hover = new EventEmitter();
  @Output() hoverOut = new EventEmitter();
  @Output() selected = new EventEmitter();

  definition: DestinyInventoryItemDefinition;
  Rank = DestinyRecipesPerkRank;
  highlighted: boolean;

  constructor(
    private dataService: DataService,
    private voltronService: VoltronService,
    private core: CoreService,
  ) { }

  ngOnInit(): void {
    if (!this.perk.proficiency) {
      if (this.voltronService.voltronContent) {
        const voltronPerks = this.voltronService.voltronContent[this.voltronService.AdeptMatchings[this.itemHash] || this.itemHash];
        let basePerkHash = this.perk.plugHash;
        if (this.perk.enhanced) {
          basePerkHash = this.dataService.enhancedTraitsToNormalTraits[this.perk.plugHash] || this.perk.plugHash;
        }
        this.perk.proficiency = {
          pvp: _.flatten(voltronPerks?.pvp).indexOf(basePerkHash) > -1 ? DestinyRecipesPerkRank.A : DestinyRecipesPerkRank.B,
          pve: _.flatten(voltronPerks?.pve).indexOf(basePerkHash) > -1 ? DestinyRecipesPerkRank.A : DestinyRecipesPerkRank.B,
        };
      } else {
        this.perk.proficiency = {
          pvp: DestinyRecipesPerkRank.B,
          pve: DestinyRecipesPerkRank.B
        };
      }
    }
    this.definition = this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', this.perk.plugHash);
    if (this.highlights?.length) {
      this.highlighted = this.highlights.indexOf(this.perk.plugHash) > -1;
    }
  }

  devVerbose() {
    if (this.core.devMode) {
      console.log(this.perk, this.definition);
    }
  }

  @HostListener('mouseover', ['$event'])
  mouseHover() {
    this.hover.emit();
  }

  @HostListener('mouseout', ['$event'])
  mouseHoverOut() {
    this.hoverOut.emit();
  }

  perkClicked() {
    this.selected.emit(this.perk);
    if (this.core.devMode) {
      console.log(this.perk, this.definition);
    }
  }
}
