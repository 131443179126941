<p>Destiny Recipes uses a predictable way to appraise armor quality. Here's how it works:</p>

<h1>Terminology</h1>
<div class="term"><strong>Stat: </strong>one of the 6 stats in the game (Mobility/Resilience/Recovery/Discipline/Intellect/Strength)</div>
<div class="term"><strong>Base total: </strong>the sum of all 6 stats, without masterwork or mod bonuses (between 44 and 68*)</div>
<div class="term"><strong>Displayed total: </strong>the sum of all 6 stats, with added masterwork and mod bonuses</div>
<div class="term"><strong>Segment: </strong>A group of 3 specific stats. Can be either <span class="top">top</span> (Mobility/Resilience/Recovery) or <span class="bottom">bottom</span> (Discipline/Intellect/Strength)</div>
<div class="term"><strong>Max segment: </strong>A segment with a base total of 34 points.</div>
<div class="term"><strong>Build type: </strong>The kind of build an armor is most likely to be helpful towards (Mob+Res or Rec+Res). Only relevant to the <span class="top">top</span> segment.</div>
<div class="term"><strong>Build segment: </strong>The sum of points distributed towards the two relevant stats for a build (Mob+Res or Rec+Res)</div>
<div class="term"><strong>Wasted points: </strong>The number of points outside the build type stats above the minimum value of 2.</div>
<small>Example: for a Mob+Res build type, the wasted points are the ones allocated towards Recovery above 2.</small>
<div class="term"><strong>Top/bottom total: </strong>The base total of either the <span class="top">top</span> three or <span class="bottom">bottom</span> three stats. Between 22 and 34* </div>
<div class="term"><strong>Top/bottom distribution quality: </strong>How well points are distributed across the segments' stats (see below for more details).</div>
<div class="term"><strong>Global quality: </strong>A rating trying to mix distribution quality and base total across both segment (see below for more details).</div>

<small>*: Some pre-shadowkeep exotics have intrinsic stats that can put them over the 68 maximum base total.</small>

<h1>Usefulness of armor stats</h1>
<p>Due to how armor pieces are generated, we divide armor stats into two segments (<span class="top">top</span> and <span class="bottom">bottom</span>).</p>

<p>Stats are semi-randomly generated across a segment's stats. There are only a fixed - yet large - number of combinations possible.</p>
<p>A segment has between 22 and 34 points. The closer you are from 34 in both segments, the better your armor piece is.</p>
<p>A single stat cannot have less than 2 points, and no more than 30 points. </p>

<p>In the current Destiny 2 meta, we want a maximum of points in Resilience. A Mobility+Recovery build would be less than ideal.</p>
<small>That leaves only two useful builds in the <span class="top">top</span> segment: Mobility+Resilience and Recovery+Resilience.</small>

<p>In the <span class="bottom">bottom</span> segment, Intellect and Strength are not very useful because most build revolving around Super regen and melee regen will be done with specific mods and exotics.</p>
<small>Because of that, the only kinda useful stat in the <span class="bottom">bottom</span> segment is Discipline.</small>

<h1>Top segment rating</h1>
<small>Important note: Only base stat points are counted in the following checks (we exclude masterwork and mod points)</small>
<p>To know how good a <span class="top">top</span> segment is, we check three things:</p>

<ol>
  <li>How many points does the <span class="top">top</span> segment have? <small>Closer to 34 is better</small></li>
  <li>Is this a good Mob+Res piece? <small>How many points in the relevant stats, and how many wasted in Recovery.</small></li>
  <li>Is this a good Rec+Res piece? <small>How many points in the relevant stats, and how many wasted in Mobility.</small></li>
</ol>

<h1>Bottom segment rating</h1>
<p>Since the <span class="bottom">bottom</span> segment is less valuable, the checks applied are looser:</p>

<ol>
  <li>How many points does the <span class="bottom">bottom</span> segment have? <small>Closer to 34 is better</small></li>
  <li>How good are the stats distributed towards Discipline? <small>The less points in Intellect and Strength, the better.</small></li>
</ol>

<h1>General Metrics</h1>
<p>You can fiddle with the "Fine tuning" button in the configuration page if you know what you are looking for. If you don't want that, we tried to devise a notation system that can approximate the overall quality of an armor piece using the segment ratings described above.</p>

<p>The goal of the rating is to calculate a <strong class="quality-decay">Quality Decay</strong> for the armor piece.</p>
<p>The <strong class="quality-decay">Quality Decay</strong> is a number that will grow larger the worst an armor piece is. A <strong class="quality-decay">Quality Decay</strong> of 0 means the piece is perfect.</p>

<div class="term"><strong>(Top segment gap)</strong> we calculate the gap between the actual <span class="top">top</span> segment points and a max segment (34). </div>
<small>Gives a number between 0 and 12*</small>
<div class="term"><strong>(MobRes build segment gap)</strong> we calculate the gap between 32 (the maximum points two combined stats can have) and the piece's points across Mobility and Resilience.</div>
<small>Gives a number between 0 and 28*</small>
<div class="term"><strong>(RecRes build segment gap)</strong> we calculate the gap between 32 (the maximum points two combined stats can have) and the piece's points across Recovery and Resilience.</div>
<small>Gives a number between 0 and 28*</small>

<div class="term"><strong>(Bottom segment gap)</strong> we calculate the gap between the actual <span class="bottom">bottom</span> segment points and a max segment (34). </div>
<small>Gives a number between 0 and 12*</small>
<div class="term"><strong>(Discipline quality)</strong> we rate how good the <span class="bottom">bottom</span> segment distribution is towards Discipline</div>
<small>Gives a rating from S to D (mapped to 0-4)</small>
<br><br>
<small>*: Less is better. Note that some pre-shadowkeep exotics can have more than 34 points in a segment. If they do exceed than number, the score is 0.</small>

<h1>Which build is relevant?</h1>
<p>Before we calculate the <strong class="quality-decay">Quality Decay</strong>, we need to figure out if the armor piece is better for a Mob+Res or a Rec+Res build.</p>
<p>To do so, we calculate two separate decays:</p>

<div class="formulas">
  <img src="assets/formulas/mobres.png" alt="MobRes decay formula">
  <img src="assets/formulas/recres.png" alt="RecRes decay formula">
</div>
<br><br>
<div class="term"><strong>(Top Decay)</strong> From the two values we get, we select the lower one (best quality). This also gives us the build type for the armor piece.</div>

<h1>Destiny Recipes Rating System : the Quality Decay</h1>
<p>Once we have all those, we calculate the <strong class="quality-decay">Quality Decay</strong> of the armor piece.</p>

<div class="formulas">
  <img src="assets/formulas/qualitydecay.png" height="40px" alt="Quality decay formula">
</div>
<small>This gives a number between 0 and 10. All <span class="bottom">bottom</span> segment metrics are weighed way down because they are less important.</small>

<div class="term"><strong>(S)</strong> a <strong class="quality-decay">Quality Decay</strong> of 0</div>
<div class="term"><strong>(A)</strong> a <strong class="quality-decay">Quality Decay</strong> >0 and ≤3</div>
<div class="term"><strong>(B)</strong> a <strong class="quality-decay">Quality Decay</strong> >3 and ≤5</div>
<div class="term"><strong>(C)</strong> a <strong class="quality-decay">Quality Decay</strong> >5 and ≤7</div>
<div class="term"><strong>(D)</strong> a <strong class="quality-decay">Quality Decay</strong> >7 and ≤9</div>
<div class="term"><strong>(E)</strong> a <strong class="quality-decay">Quality Decay</strong> >9 and <10</div>
<div class="term"><strong>(F)</strong> a <strong class="quality-decay">Quality Decay</strong> of 10</div>


