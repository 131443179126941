import { Component, OnInit } from '@angular/core';
import { ModalContentDialog } from '../../types';
import { ModaleComponent } from '../modale/modale.component';

@Component({
  selector: 'app-watcher-manager-modal',
  template: `<div class="watcher-modal"><app-watcher-manager></app-watcher-manager></div>`,
  styleUrls: ['./watcher-status.component.scss']
})
export class WatcherManagerModalComponent implements ModalContentDialog, OnInit {
    data: any;
    readonly modalReference: ModaleComponent;

    getData(): any {
        return undefined;
    }

    ngOnInit(): void {
      setTimeout(() => this.modalReference.confirmedDisabled = false);
    }
}
