<ng-container *ngIf="!perksOnly">
  <div class="stat-toggle" (click)="showStats = !showStats">{{ 'SECTIONS.COMMON.item-stat.TOGGLE_STATS' | translate }}</div>
  <div class="stats" *ngIf="showStats && (instance || type === 'weapon')">
    <app-stat-bar *ngFor="let stat of mainStatGroup.scaledStats" [stat]="stat" [item]="item" [instance]="instance"></app-stat-bar>
<!--    <app-stat-bar *ngFor="let stat of hiddenStatGroup" [stat]="stat" [item]="item" [instance]="instance"></app-stat-bar>-->
  </div>
  <div class="stat-list" [ngClass]="{hidden: statsToggleable && !showStats}" *ngIf="type === 'weapon' || instance">
    <ng-container *ngIf="type === 'armor' && total">
      <span class="name" *ngIf="!hideStatName">{{'SECTIONS.COMMON.item-stat.TOTAL' | translate}}</span>
      <span class="value">
      {{total.raw + total.masterworked + total.modded}}
    </span>
      <div class="summary-value">
        <span class="raw-text" *ngIf="masterworked || modded">{{total.raw}}</span>
        <span class="masterwork-text" *ngIf="masterworked"> + {{total.masterworked}}</span>
        <span class="moded-text" *ngIf="modded"> + {{total.modded}}</span>
      </div>
    </ng-container>
  </div>
  <div class="intrinsic" *ngIf="!hideIntrinsic && (intrinsicPerk || weaponMods?.length)">
    <app-destiny-item [item]="intrinsicPerk" [isPerk]="true" *ngIf="intrinsicPerk"></app-destiny-item>
    <div class="weapon-mods">
      <app-mod-slot [mod]="mod" *ngFor="let mod of weaponMods" [item]="instance"></app-mod-slot>
    </div>
  </div>
  <div class="artifice" *ngIf="isArtificeArmor">
    <img src="/assets/artifice.png" alt="Artifice armor" />
    <div class="artifice-content">
      <strong>{{isArtificeArmor.displayProperties.name}}</strong>
      <span>{{isArtificeArmor.displayProperties.description}}</span>
    </div>
  </div>

  <div class="perk-list" *ngIf="!hideMods && (!richPerks || type === 'armor')">
    <app-destiny-item [item]="socket" *ngFor="let socket of sockets" [isPerk]="true"></app-destiny-item>
  </div>

  <div class="voltron-absent-warning" *ngIf="voltron && instance && richPerks && type === 'weapon' && !instance.score && instance.definition.inventory.tierType > 3 && instance.definition.inventory.tierType < 6 && !instance.curated">{{'SECTIONS.COMMON.item-stat.PERKS_NOT_AVAILABLE' | translate}}</div>
  <div class="curated-roll" *ngIf="instance?.curated && instance.definition.inventory.tierType !== 6">{{'SECTIONS.COMMON.item-stat.CURATED' | translate}}</div>
  <ng-container *ngIf="itemTarget">
    <div class="matches" *ngIf="itemTarget.matches">
      <div class="scroller scroller-left"><fa-icon [icon]="['fas', 'caret-left']"></fa-icon></div>
      <div class="scroller scroller-right"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon></div>
      <div class="matches-wrapper">
        <div class="nav nav-tabs list" role="tablist">
          <li class="title">{{(itemTarget?.matches.length ? 'SECTIONS.COMMON.item-stat.ROLLS' : 'SECTIONS.COMMON.item-stat.DO_NOT_OWN') | translate}}</li>
          <li class="match" *ngFor="let match of itemTarget.matches" [ngClass]="{'selected': selectedMatch === match}" (click)="selectedMatch = match">
            <fa-icon [icon]="['fas', 'lock']" *ngIf="isLocked(match.instance)"></fa-icon>
            {{match.matchingPercentage | toFixed:0:'down'}}%
          </li>
        </div>
      </div>
    </div>
    <div class="selected-item" *ngIf="selectedMatch && selectedMatch.instance">
      <div class="actions">
        <button class="small-btn" [disabled]="lockingItem" (click)="toggleItemLockState(selectedMatch.instance)"><fa-icon [icon]="['fas', isLocked(selectedMatch.instance) ? 'lock' : 'unlock']"></fa-icon>{{(isLocked(selectedMatch.instance) ? 'SECTIONS.COMMON.item-stat.UNLOCK' : 'SECTIONS.COMMON.item-stat.LOCK') | translate}}</button>
        <app-dim-sync-tag-editor [instanceId]="selectedMatch.instance.itemInstanceId"></app-dim-sync-tag-editor>
      </div>
      <div class="notes">
        <app-dim-sync-notes [instanceId]="selectedMatch?.instance.itemInstanceId"></app-dim-sync-notes>
      </div>
      <div dr-item-stat [richPerks]="true" [instance]="selectedMatch.instance" [item]="selectedMatch.instance.definition" [perksOnly]="true" *ngIf="selectedMatch" [highlights]="itemTarget"></div>
    </div>
    <div class="perk-sub-title">{{ 'SECTIONS.COMMON.item-stat.TARGETED' | translate }}</div>
  </ng-container>
</ng-container>
<div class="rich-perk-list" *ngIf="richPerks && type === 'weapon'">
  <div class="perk-vertical-group" *ngFor="let perkGroup of (instance?.perks || possiblePerks); let idx = index">
    <app-weapon-perk [perk]="perk" displayType="circle" [itemHash]="item.hash" *ngFor="let perk of perkGroup" [highlights]="highlights?.perks[idx] || itemTarget?.perks[idx]"></app-weapon-perk>
  </div>
</div>
