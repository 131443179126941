
<div #tooltip class="equipment-tooltip" *ngIf="item" [style.top]="currentY" [style.left]="currentX" [ngClass]="tierTypeClass[item.inventory?.tierType]" >
  <div class="equipment-title">
    <h2 class="item-name">
      <span>{{item.displayProperties.name}}</span>
      <div class="close-btn" (click)="closeTooltip()"><fa-icon [icon]="['fas', 'times-circle']"></fa-icon> </div>
    </h2>
    <div class="item-header-details" *ngIf="item.inventory">
      <span class="item-class">{{item.itemTypeDisplayName}}</span>
      <span class="item-rarity">{{item.inventory.tierTypeName}}</span>
    </div>

    <div class="links" *ngIf="item.itemType === 3 || item.itemType === 2">
      <a [href]="'https://www.light.gg/db/items/' + item.hash" target="_blank"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAFNklEQVR42q2X2W9UVRzHDyj6IgYwGiQGkKUFNDGRpcAD4vLAP4BQeEMTAVPjAg3EiNUXHxB5YAsGEU2M+2MFmhaxBJB2urcznbm3y0w7hbYpaWa5dzoztx/740xhpJ07LfBNfsmZc8+Z374cNR0As4HVQLFt25+OjCS9pPqrSHTug/Q2bxfry84wTz1qAGsTicTR3t7e9tbW1lRTUxO1tbW0tAUgvAsChdC5CbreSTJ42kuy40iLycZHwbhgTNMfOjo6bI/HQ1VVFRcuXOD8+fOUl5dT42kCcwtcV3BDQY2CBgVGIfSVWiR9Z2saeelBme/s6+u7KZpevHgRYVpZWUlNTQ3t7e2EQiFu3x6GaDX0n4DQB+ArgrontTC1IshyGDoRjtnsmBbzVCp1MBAIOOMaX7p0CWEaiUQYHR0lNxyI10OwBOqf1oI0zIDw7jRObL9SzJwSc5/PR0VFBcK8rq6OaDTKtBFvBP+b2i0eBaHtkIrsz2f2YsMwHGEuZhdBHMchF6qjcCXmZhAbgnvvCiGWiFpsz8V82ViU92fMLszdFXRgtQHrDLAcXDAKwT2ZAH0MBo/fvNbMigkCxGKxHyXgMmZ31VxwaABm+TR9MQiQxxL+13VMGEvAbjv7v3gAVpummRCzS8Dl8/mvEZhrwjMdmuaZ8EcUd1jNUD9bZ0d4v93WyTo1DsuyjkqeS6pJtLvhkgULg/DcGC3oEdLrRWN02cIdwfe1FczlMNJ5eFz7p8Z87xffS567aX/FhoIemN8Li27C4ltCstZ7hT1w1XYLnAapEzo1B7/1ln3PnDvm93q9KfG9FJlceV5laebPh2FJPywbhIIhIVnLnnzTQvxt5YrHNPjW6oDsfjfZFKBIAdubm5sR8/v9fibDLxFY2C1aCiPNdNUwvBwRkrXsyTc5o93xW4TJESrRbujaDLBV2bZ9UKK/vPwvKa8TUu2zAQm4jJ/7RFvN8JUYvGoLyVr25JuckbP6zucDk6Ro/3EtQMdKSIQ+Uslksto0/LQ0NzI8PMw4auNQZMDjXh3pC0Lib9FUtNaMi9JCspY9+SZn5Kzc0Xc3GFAX5x6GftYuCCyEhP+IIhmupHsL+NZApIJxeOL68qyHFGCjAfWWmwCWWYoUh38VDJwgG2K+Qw/ggmeDujaUTcUFwDa6NunN0IdMhASU5P50glAK0xSDUMYounelqFG6n+PkLECFedJwfhhW9MDlKabhnWr41U/MZeBUOw1KFwmriVyQIlPoUoiE+fXpFKKjzFECEsY3mEszbijBDVJkFk1SiheP0T95S/Hee6U4IaU4A6/BesL7bGqVnmSsVtzwexTm3deM/szbjJqym5HV6M9qRtIaibeew3hRS+h/A0YTuKEsqx1/OaV2vDmj/VKwvd8pxQyVjestrGTg2C0aZkqQ6M7FqOtAssaAorwDiQPB3VkDycm+a55JBhLdlikm/F4aj7onhGPn7o5RuOo6klkyDd0/km1TbiAVKZUB8q4Q/rfAamTaiDeIK7OG0h3gxD5R+cFMnEipSIuky41MYAZL9Mg96gAueR6vE8tJwOm74tLwnjSp2D7t9ykiFmOnPCowlkvk6j+re0KKlaSqlFSp65r6j+k93zo5k/UwKYChU70xi2L1IJBnlTyvkGeWWQgNGbfcEJL1hN/6jLkC+g7ESfrPVNezSj0s5KFJsvNrBk+3IQ/QztfAXAntLwjpdedm6H5nBDkz0n242WCDetSQst3WRRHp9Nskuj9mxDyHHTiHrGGrfDtwkrlqGvgP9CgI5veEaPcAAAAASUVORK5CYII="> light.gg</a>
      <a [href]="'https://bray.tech/details/' + item.hash" target="_blank"><img src="assets/logo/braytech.svg"> Braytech</a>
    </div>
  </div>
  <div class="equipment-description ammo-type" *ngIf="tooltipInstance?.type ==='item'">
    <div class="equipment-info">
      <div class="equipment-power">
        <img alt="damage type" class="equipment-info-image" *ngIf="item.defaultDamageType" [src]="'assets/damageType/' + item.defaultDamageType + '.png'" />
        <span class="equipment-info-text" style="font-size: 24px"  *ngIf="tooltipInstance?.instance?.powerLevel">{{tooltipInstance?.instance?.powerLevel || item.defaultDamageTypeHash | itemName: 'DestinyDamageTypeDefinition'}}</span>
        <span class="equipment-info-text" style="font-size: 24px"  *ngIf="!tooltipInstance?.instance?.powerLevel && item.defaultDamageType > 0">{{item.defaultDamageTypeHash | itemName: 'DestinyDamageTypeDefinition'}}</span>
        <span class="equipment-info-text" style="font-size: 24px"  *ngIf="tooltipInstance?.instance?.powerLevel && item.defaultDamageType === 0">Kinetic</span>
      </div>
      <div class="equipment-ammo" *ngIf="item.equippingBlock?.ammoType" [ngClass]="'ammo-' + item.equippingBlock?.ammoType">
        <img alt="equipment-info-image" class="equipment-info-image" [src]="'/assets/ammo/' + item.equippingBlock?.ammoType + '.png'">
        <span class="equipment-info-text ammo-name" [ngClass]="AmmoType[item.equippingBlock?.ammoType]">{{('SECTIONS.COMMON.equipment-tooltip.AMMO_TYPE.' + AmmoType[item.equippingBlock?.ammoType]) | translate}}</span>
      </div>
    </div>
    <span class="flex-spacer"></span>
  </div>
  <div class="equipment-description padded" *ngIf="wishlistItem">
    {{ 'SECTIONS.COMMON.equipment-tooltip.SOURCE' | translate }} {{wishlistItem.source}}
  </div>
  <div class="equipment-description padded" *ngIf="item.displayProperties.description">
    {{item.displayProperties.description}}
  </div>
  <div class="equipment-description" *ngIf="tooltipInstance?.type === 'item' && !tooltipInstance.instance">
    <div dr-item-stat [statsToggleable]="true" [showPerksInitialState]="tooltipInstance.showPerksInitialState" [item]="item" richPerks="true" [targets]="tooltipInstance.target" class="item-stat"></div>
  </div>
</div>

