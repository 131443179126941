import { Component, OnInit } from '@angular/core';
import { ModalContentDialog } from '../../types';
import { ModaleComponent } from '../../common/modale/modale.component';

@Component({
  selector: 'dr-confirm-tag-modal',
  templateUrl: 'confirm-tag-modal.component.html',
  styleUrls: ['confirm-tag-modal.component.scss']
})
export class ConfirmTagModalComponent implements OnInit, ModalContentDialog<boolean> {
  data: any;
  readonly modalReference: ModaleComponent;
  overrideTags: boolean;

  ngOnInit() {
    setTimeout(() => this.modalReference.confirmedDisabled = false, 0);
  }

  getData(): any {
    return this.overrideTags;
  }

  close(overrideTags: boolean) {
    this.overrideTags = overrideTags;
    this.modalReference.close();
  }
}
