<div class="perk-circle" (click)="devVerbose()">
  <img [src]="'https://bungie.net' + mod.displayProperties.icon" [alt]="mod.displayProperties.name" *ngIf="mod.displayProperties?.icon">
  <img src="assets/missing_icon.png" *ngIf="!mod.displayProperties?.icon" alt="icon">

  <div class="perk-details">
    <div class="perk-name">{{mod.displayProperties.name}}</div>
    <div class="perk-description">{{mod.displayProperties.description}}</div>
    <div class="perk-effects" *ngIf="mod.investmentStats?.length">
      <ng-container *ngFor="let stat of mod.investmentStats" >
        <div class="perk-effect" *ngIf="isStatActive(stat)" [ngClass]="{'negative': stat.value < 0, 'positive': stat.value > 0}">
          <span class="effect-name">{{stat.statTypeHash | itemName : 'DestinyStatDefinition' }}</span>
          <span class="effect-value">{{(stat.value > 0 ? '+' : '') + stat.value }}</span>
        </div>
      </ng-container>
    </div>
  </div>

</div>
