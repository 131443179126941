<h4>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.CHOOSE_FILE' | translate}}</h4>

<input type="file" accept=".json" (change)="handleFile($event)">

<div class="result" *ngIf="toImport && isOwnVault">
  <dl>
    <dt>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.VAULT_OWNER' | translate}}</dt> <dd>{{toImport.owner}}<span class="bungie-code">#{{toImport.ownerCode}}</span><span *ngIf="isOwnVault" class="you">&nbsp;(this vault)</span></dd>
    <dt>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.CHOOSE_FILE' | translate}}</dt><dd>{{toImport.cleaner}}<span class="bungie-code" *ngIf="toImport.cleanerCode > -1">#{{toImport.cleanerCode}}</span></dd>
    <dt>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.CLEANUP_DATE' | translate}}</dt><dd>{{toImport.date | date: 'MMM dd yyyy HH:mm'}}</dd>
    <dt>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.TO_KEEP' | translate}}</dt><dd>{{toImport.toKeep.length}}</dd>
    <dt>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.TO_REMOVE' | translate}}</dt><dd>{{toImport.toRemove.length}}</dd>
  </dl>
</div>
<div class="error" *ngIf="error">
  {{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.PARSE_ERROR' | translate}}
</div>
<div class="error" *ngIf="!isOwnVault && toImport">
  {{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.NOT_CORRESPONDING' | translate}}
</div>

<div class="info">
  <p>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.INFO1' | translate}}</p>
  <p>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.INFO2' | translate}}</p>
  <p>{{'SECTIONS.VAULT_CLEANER.import-cleanup-modal.INFO3' | translate}}</p>
</div>

