import { UntypedFormControl } from '@angular/forms';

export interface VaultCleanerConfig<ConfigOptions = VaultCleanerConfigOption[]> {
  weapons: {
    execute: boolean;
    options: ConfigOptions;
    groupArchetypes: boolean;
    groupElements: boolean;
  };
  armor: {
    execute: boolean;
    groupExotics: boolean;
    groupByBuild: boolean;
    groupByArtifice: boolean;
    options: ConfigOptions;
  };
}

export type VaultCleanerConfigImport = VaultCleanerConfig<Partial<VaultCleanerConfigOption<Partial<VaultCleanerConfigCustomization>>>[]>;

export interface VaultCleanerConfigOption<Customization = VaultCleanerConfigCustomization> {
  id: VaultCleanerFactoryId;
  execute: boolean;
  hidden?: boolean;
  onChange?: (opt: VaultCleanerConfigOption) => void;
  customization?: Customization;
  dimSyncRequired?: boolean;
  templateName?: string;
  isActive?: () => boolean;
}

export interface VaultCleanerConfigCustomizationBase {
  formControl: UntypedFormControl;
  type: 'number' | 'string' | 'date' | 'rating';
  override?: { [key: string]: string };
}

export interface VaultCleanerConfigCustomizationNumber extends VaultCleanerConfigCustomizationBase {
  type: 'number';
  options: {
    sliderMax: number;
    sliderMin: number;
  };
}

export interface VaultCleanerConfigCustomizationRating extends VaultCleanerConfigCustomizationBase{
  type: 'rating';
  options: {
    ratings: string[];
  };
}

export type VaultCleanerConfigCustomization = VaultCleanerConfigCustomizationNumber | VaultCleanerConfigCustomizationRating;

export enum VaultCleanerFactoryId {

  WEAPONS_MASTERWORK = 'WEAPONS_MASTERWORK',
  WEAPONS_GODROLL_PVP = 'WEAPONS_GODROLL_PVP',
  WEAPONS_CURATED = 'WEAPONS_CURATED',
  WEAPONS_SUNSET = 'WEAPONS_SUNSET',
  WEAPONS_EXOTICS = 'WEAPONS_EXOTICS',
  WEAPONS_NEXT_SUNSET = 'WEAPONS_NEXT_SUNSET',
  WEAPONS_GODROLL_PVE = 'WEAPONS_GODROLL_PVE',
  WEAPONS_ALL = 'WEAPONS_ALL',
  WEAPONS_BASE = 'WEAPONS_BASE',
  WEAPONS_LOCKED = 'WEAPONS_LOCKED',
  WEAPONS_PREFER_POLY = 'WEAPONS_PREFER_POLY',
  WEAPONS_BEST_POWER_LEVEL = 'WEAPONS_BEST_POWER_LEVEL',
  WEAPONS_DEEPSIGHT = 'WEAPONS_DEEPSIGHT',
  WEAPONS_CRAFTED = 'WEAPONS_CRAFTED',
  WEAPONS_HAS_CRAFTED = 'WEAPONS_HAS_CRAFTED',
  WEAPONS_CAN_CRAFT = 'WEAPONS_CAN_CRAFT',

  ARMOR_MASTERWORK = 'ARMOR_MASTERWORK',
  ARMOR_SUNSET = 'ARMOR_SUNSET',
  ARMOR_NEXT_SUNSET = 'ARMOR_NEXT_SUNSET',
  ARMOR_BASE = 'ARMOR_BASE',
  ARMOR_BEST_POWER_LEVEL = 'ARMOR_BEST_POWER_LEVEL',

  ARMOR_QUALITY = 'ARMOR_QUALITY',
  ARMOR_TOP_SCORE = 'ARMOR_TOP_SCORE',
  ARMOR_BOTTOM_SCORE = 'ARMOR_BOTTOM_SCORE',
  ARMOR_MIN_DIS = 'ARMOR_MIN_DIS',
  ARMOR_MOBRES_QUALITY = 'ARMOR_MOBRES_QUALITY',
  ARMOR_RECRES_QUALITY = 'ARMOR_RECRES_QUALITY',

  ARMOR_EXOTICS = 'ARMOR_EXOTICS',
  ARMOR_ALL = 'ARMOR_ALL',
  ARMOR_RAID = 'ARMOR_RAID',
  ARMOR_CURRENT_SEASON = 'ARMOR_CURRENT_SEASON',
  ARMOR_LOCKED = 'ARMOR_LOCKED',

  WEAPONS_DIM_KEEP_KEEP = 'WEAPONS_DIM_KEEP_KEEP',
  WEAPONS_DIM_KEEP_FAVORITE = 'WEAPONS_DIM_KEEP_FAVORITE',
  WEAPONS_DIM_KEEP_INFUSE = 'WEAPONS_DIM_KEEP_INFUSE',
  WEAPONS_DIM_KEEP_ARCHIVE = 'WEAPONS_DIM_KEEP_ARCHIVE',
  WEAPONS_DIM_REMOVE_JUNK = 'WEAPONS_DIM_REMOVE_JUNK',
  WEAPONS_DIM_KEEP_LOADOUT = 'WEAPONS_DIM_KEEP_LOADOUT',

  ARMOR_DIM_KEEP_KEEP = 'ARMOR_DIM_KEEP_KEEP',
  ARMOR_DIM_KEEP_FAVORITE = 'ARMOR_DIM_KEEP_FAVORITE',
  ARMOR_DIM_KEEP_INFUSE = 'ARMOR_DIM_KEEP_INFUSE',
  ARMOR_DIM_KEEP_ARCHIVE = 'ARMOR_DIM_KEEP_ARCHIVE',
  ARMOR_DIM_REMOVE_JUNK = 'ARMOR_DIM_REMOVE_JUNK',
  ARMOR_DIM_KEEP_LOADOUT = 'ARMOR_DIM_KEEP_LOADOUT',
}

export interface VoltronContent {
  [itemHash: string]: {
    pvp: number[][],
    pve: number[][]
  };
}

export class VaultConfigHelper {
  static getRuleConfig(config: VaultCleanerConfig, rule: VaultCleanerFactoryId) {
    return config?.armor?.options?.find((o) => o.id === rule) || config?.weapons?.options?.find((o) => o.id === rule);
  }
}
