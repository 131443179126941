import { Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { DestinyInventoryItemDefinition } from 'bungie-api-ts/destiny2';
import { DataService } from '../../services/data.service';
import { CoreService, TooltipInstance } from '../../services/core.service';
import { DimSyncService } from '../../services/dim-sync.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentTooltipComponent } from '../equipment-tooltip/equipment-tooltip.component';
import { WeaponWishes } from '../../checklist/weapons/targeted-weapons';

@Component({
  selector: 'cr-target-equipment-tooltip',
  templateUrl: './target-equipment-tooltip.component.html',
  styleUrls: ['../equipment-tooltip/equipment-tooltip.component.scss', '../equipment-tooltip/tooltip-item-description.scss',  './target-equipment-tooltip.component.scss']
})
export class TargetEquipmentTooltipComponent extends EquipmentTooltipComponent implements OnChanges {

  @ViewChild('tooltip', { static: false }) tooltip: ElementRef;
  @Input() item: DestinyInventoryItemDefinition;
  @Input() tooltipInstance: TooltipInstance;
  @Input() position: {x: number, y: number};
  wishlistItem: WeaponWishes;

  constructor(
    public dataService: DataService,
    protected coreService: CoreService,
    protected eRef: ElementRef,
    protected dimSync: DimSyncService,
    protected toastr: ToastrService,
    protected translate: TranslateService
  ) {
    super(dataService, coreService, eRef, dimSync, toastr, translate);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.coreService.tooltipLocked) {
      this.coreService.closeTooltip();
    }
  }

  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    if (this.tooltipInstance?.target) {
      this.wishlistItem = this.tooltipInstance.target.find((e) => e.itemHash === this.item.hash);
    }
  }
}
