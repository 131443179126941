import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'trustHTML'
})
export class TrustHTMLPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
