import { NgModule } from '@angular/core';
import { DestroyDirective } from './directives/destroy.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { SanitizePipe } from './pipes/sanitize-url.pipe';
import { DestinyItemComponent } from './destiny-item/destiny-item.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EquipmentTooltipComponent } from './equipment-tooltip/equipment-tooltip.component';
import { SplashComponent } from './splash/splash.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArchive,
  faBan,
  faBars,
  faBolt,
  faCaretDown,
  faCaretLeft,
  faCaretRight, faCheck,
  faCircleNotch,
  faExchangeAlt,
  faExclamationCircle, faExclamationTriangle,
  faExternalLinkAlt,
  faGlobe,
  faHeart,
  faInfoCircle, faLevelDownAlt,
  faLock, faMinus,
  faPause,
  faPen,
  faPlay,
  faPlus,
  faQuestionCircle,
  faSignOutAlt,
  faSort,
  faSpinner,
  faStickyNote,
  faSyncAlt,
  faTag, faTasks, faTimes, faTrash,
  faTrophy,
  faUnlock,
  faTimesCircle, faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { NgxPaginationModule } from 'ngx-pagination';
import { ItemStatComponent } from './item-stat/item-stat.component';
import { BungieCardComponent } from './bungie-card/bungie-card.component';
import { CharacterCardComponent } from './character-card/character-card.component';
import { ObjectiveDescriptionComponent } from './objective-description/objective-description.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { ModaleComponent } from './modale/modale.component';
import { TrustHTMLPipe } from './pipes/trust-html.pipe';
import { CircleProgressComponent } from './circle-progress/circle-progress.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextModalComponent } from './text-modal/text-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { WeaponPerkComponent } from './weapon-perk/weapon-perk.component';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ItemNamePipe } from './pipes/item-name.pipe';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { RatingPipe } from './pipes/rating.pipe';
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { ValueSliderComponent } from './value-slider/value-slider.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModSlotComponent } from './mod-slot/mod-slot.component';
import { ItemIconUrlPipe } from './pipes/item-icon-url.pipe';
import { CannotSalePipe } from './pipes/cannot-sale.pipe';
import { SocialComponent } from './social/social.component';
import { ManifestEntryPipe } from './pipes/manifest-entry.pipe';
import { TargetEquipmentTooltipComponent } from './target-equipment-tooltip/target-equipment-tooltip.component';
import { DimSyncNotesComponent } from './dim-sync-notes/dim-sync-notes.component';
import { DimSyncTagEditorComponent } from './dim-sync-tag-editor/dim-sync-tag-editor.component';
import { LetDirective } from './directives/let.directive';
import { PerkListComponent } from './perk-list/perk-list.component';
import { CharacterButtonComponent } from './character-button/character-button.component';
import { PerkPopupComponent } from './perk-popup/perk-popup.component';
import { PerkPopupTriggerDirective } from './directives/perk-popup-trigger.directive';
import { WatcherStatusComponent } from './watcher-status/watcher-status.component';
import { WatcherManagerComponent } from './watcher-manager/watcher-manager.component';
import { WatcherManagerModalComponent } from './watcher-status/watcher-manager-modal.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ToFixedPipe } from './pipes/to-fixed.pipe';
import { SmallTagEditorComponent } from './small-tag-editor/small-tag-editor.component';
import { ConfirmNavigationLeaveComponent } from '@Common/confirm-navigation-leave/confirm-navigation-leave.component';
import { StatBarComponent } from '@Common/stat-bar/stat-bar.component';
import { RecoilGraphComponent } from '@Common/recoil-graph/recoil-graph.component';
import { SimulationResultComponent } from '@Common/simulation-result/simulation-result.component';
import { ClarityInsightComponent } from './clarity-insight/clarity-insight.component';
import { SkeletonComponent } from '@Common/skeleton/skeleton-component.directive';

@NgModule({ declarations: [
    DestinyItemComponent,
    DropdownComponent,
    EquipmentTooltipComponent,
    SplashComponent,
    DestroyDirective,
    TooltipDirective,
    SanitizePipe,
    ItemStatComponent,
    BungieCardComponent,
    CharacterCardComponent,
    ObjectiveDescriptionComponent,
    HeaderComponent,
    ModaleComponent,
    TrustHTMLPipe,
    CircleProgressComponent,
    CheckboxComponent,
    TextModalComponent,
    WeaponPerkComponent,
    ErrorModalComponent,
    ItemNamePipe,
    RatingPipe,
    UserAutocompleteComponent,
    ValueSliderComponent,
    ModSlotComponent,
    ItemIconUrlPipe,
    CannotSalePipe,
    SocialComponent,
    ManifestEntryPipe,
    TargetEquipmentTooltipComponent,
    DimSyncNotesComponent,
    DimSyncTagEditorComponent,
    LetDirective,
    PerkListComponent,
    CharacterButtonComponent,
    PerkPopupComponent,
    PerkPopupTriggerDirective,
    WatcherStatusComponent,
    WatcherManagerComponent,
    WatcherManagerModalComponent,
    DateAgoPipe,
    ToFixedPipe,
    SmallTagEditorComponent,
    ConfirmNavigationLeaveComponent,
    StatBarComponent,
    RecoilGraphComponent,
    SimulationResultComponent,
    ClarityInsightComponent,
    SkeletonComponent,
  ],
  exports: [
    DestinyItemComponent,
    DropdownComponent,
    EquipmentTooltipComponent,
    SplashComponent,
    DestroyDirective,
    TooltipDirective,
    SanitizePipe,
    ToFixedPipe,
    ItemStatComponent,
    BungieCardComponent,
    CharacterCardComponent,
    HeaderComponent,
    ModaleComponent,
    TrustHTMLPipe,
    DateAgoPipe,
    CircleProgressComponent,
    CheckboxComponent,
    ToastrModule,
    ItemNamePipe,
    RatingPipe,
    UserAutocompleteComponent,
    ObjectiveDescriptionComponent,
    ValueSliderComponent,
    TranslateModule,
    ModSlotComponent,
    ItemIconUrlPipe,
    CannotSalePipe,
    SocialComponent,
    ManifestEntryPipe,
    TargetEquipmentTooltipComponent,
    LetDirective,
    PerkListComponent,
    CharacterButtonComponent,
    PerkPopupComponent,
    WatcherStatusComponent,
    WatcherManagerComponent,
    WeaponPerkComponent,
    SmallTagEditorComponent,
    ConfirmNavigationLeaveComponent,
    SimulationResultComponent,
    SkeletonComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgxPaginationModule,
    ClipboardModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild({ extend: true }),
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    NgOptimizedImage
  ],
  providers: [provideHttpClient(withInterceptorsFromDi(), withFetch())] })
export class AppCommonModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faSort,
      faTasks,
      faExternalLinkAlt,
      faPlay,
      faPause,
      faGlobe,
      faSpinner,
      faSignOutAlt,
      faExchangeAlt,
      faCaretDown,
      faTimesCircle,
      faSyncAlt,
      faCaretUp,
      faPlus,
      faQuestionCircle,
      faExclamationCircle,
      faBars,
      faTrophy,
      faDiscord,
      faInfoCircle,
      faLock,
      faCircleNotch,
      faPen,
      faHeart,
      faCheck,
      faTag,
      faBolt,
      faArchive,
      faBan,
      faStickyNote,
      faCaretLeft,
      faUnlock,
      faCaretRight,
      faExclamationTriangle,
      faLevelDownAlt,
      faTimes,
      faMinus,
      faTrash,
    );
  }}
