<div class="perk-details" *ngIf="perkPopupConfig" [style.top]="currentY" [style.left]="currentX" #popup>
  <div class="perk-info" *ngIf="perk.proficiency.pve || perk.proficiency.pvp || perkPopupConfig.highlighted" [ngClass]="{'gold': (perk.proficiency.pve && perk.proficiency.pvp) || perkPopupConfig.highlighted, 'pve': perk.proficiency.pve && !perk.proficiency.pvp, 'pvp': perk.proficiency.pvp && !perk.proficiency.pve}">
    <span *ngIf="perk.proficiency.pve && perk.proficiency.pvp" class="gold"><fa-icon [icon]="['fas', 'trophy']"></fa-icon> <span>{{'SECTIONS.COMMON.weapon-perk.ALL' | translate}}</span> </span>
    <span *ngIf="!perk.proficiency.pve && perk.proficiency.pvp" class="pvp"><img alt="pvp" src="assets/rolls/pvp.png" /><span>{{'SECTIONS.COMMON.weapon-perk.PVP' | translate}}</span></span>
    <span *ngIf="perk.proficiency.pve && !perk.proficiency.pvp" class="pve"><img alt="pve" src="assets/rolls/pve.png" /><span>{{'SECTIONS.COMMON.weapon-perk.PVE' | translate}}</span></span>
    <span *ngIf="perkPopupConfig.highlighted" class="gold"><span>This is a targeted perk</span></span>
  </div>
  <div class="perk-name">{{definition.displayProperties.name}}</div>
  <div class="perk-description">{{definition.displayProperties.description}}</div>
  <div class="perk-effects" *ngIf="definition.investmentStats?.length > 0">
    <div class="perk-effect" *ngFor="let effect of definition.investmentStats" [ngClass]="{'negative': effect.value < 0, 'positive': effect.value > 0}">
      <span class="effect-name">{{effect.statTypeHash | itemName : 'DestinyStatDefinition' }}</span>
      <span class="effect-value">{{(effect.value > 0 ? '+' : '') + effect.value }}</span>
    </div>
  </div>
  <div class="clarity-insight-ctn">
    <app-clarity-insight [perkHash]="perk.plugHash"></app-clarity-insight>
  </div>
</div>
