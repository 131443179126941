import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DestinyRecipesPerk } from '../../types/destiny-recipes-item';
import { DestinyInventoryItemDefinition } from 'bungie-api-ts/destiny2';
import { DataService } from '../../services/data.service';
import { PerkPopupConfig } from '../../services/core.service';

@Component({
  selector: 'app-perk-popup',
  templateUrl: './perk-popup.component.html',
  styleUrls: ['./perk-popup.component.scss']
})
export class PerkPopupComponent implements OnChanges {

  @ViewChild('popup', { static: false }) popup: ElementRef;
  @Input() perkPopupConfig: PerkPopupConfig;
  @Input() position: {x: number, y: number};

  definition: DestinyInventoryItemDefinition;
  perk: DestinyRecipesPerk;
  currentX = '0';
  currentY = '0';

  constructor(private data: DataService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.perkPopupConfig) {
      this.perk = this.perkPopupConfig.perk;
      this.definition = this.data.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', this.perkPopupConfig.perk.plugHash);
    }
    if (changes.position && changes.position.currentValue) {
      if (this.popup) {
        // Prevent tooltip from going outside of the vertical axis
        const tooltipHeight = this.popup.nativeElement.clientHeight;
        if (changes.position.currentValue.y + 10 + tooltipHeight > window.innerHeight) {
          const cursorPosFromBottom = window.innerHeight - changes.position.currentValue.y;
          this.currentY = window.innerHeight - tooltipHeight - 10 - cursorPosFromBottom + 'px';
        } else {
          this.currentY = (changes.position.currentValue.y + 10) + 'px';
        }

        // Prevent tooltip from going outside of the horizontal axis
        const tooltipWidth = this.popup.nativeElement.clientWidth;
        if (changes.position.currentValue.x + 10 + (tooltipWidth / 2) > window.innerWidth) {
          this.currentX = window.innerWidth - (tooltipWidth / 2) - 10 + 'px';
        } else {
          this.currentX = (changes.position.currentValue.x + 10) + 'px';
        }

      } else {
        this.currentY = (changes.position.currentValue.y + 10) + 'px';
        this.currentX = (changes.position.currentValue.x + 10) + 'px';
      }
    }
  }

}
