import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { catchError, concatMap, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { GeneralUser, getMembershipDataForCurrentUser, UserMembershipData } from 'bungie-api-ts/user';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupUserInfoCard } from 'bungie-api-ts/groupv2';
import { DestinyComponentType, getProfile } from 'bungie-api-ts/destiny2';
import { OAuthService } from 'angular-oauth2-oidc-noscope';
import { authCodeFlowConfig } from '../oauth.config';
import { CoreService } from './core.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  private static CACHED_MEMBERSHIP_DATA = 'membershipData';
  private static CACHED_VALIDMEMBERSHIPS_DATA = 'validMembershipsData';

  membershipData: UserMembershipData;
  currentMemberShip: GroupUserInfoCard;
  bungieMembership: GeneralUser;
  validMemberships: GroupUserInfoCard[];
  authenticated: boolean;
  authChanged = new BehaviorSubject<boolean>(false);

  get token(): string {
    return this.oauthService.getAccessToken();
  }

  get bearerToken(): string {
    return 'Bearer ' + this.oauthService.getAccessToken();
  }

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private coreService: CoreService,
    private router: Router) {
  }

  init(): Observable<boolean> {
    this.oauthService.initCodeFlow();
    this.oauthService.configure(authCodeFlowConfig);
    if (this.oauthService.hasValidAccessToken() && this.hasCachedData()) {
      return this.loadCachedData();
    }
    return from(this.oauthService.tryLogin()).pipe(
      mergeMap(() => this.route.queryParams),
      mergeMap((params) => {
        if (params.state) {
          const routeParam = params.state.split(';').find((p) => p.startsWith('route'));
          if (routeParam) {
            const route = decodeURIComponent(routeParam).split('=');
            if (route.length > 1) {
              from(this.router.navigate([route[1]]));
            }
          }
        }
        return of('');
      }),
      mergeMap(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return of(true);
        }
        if (this.oauthService.getRefreshToken()) {
          return from(this.oauthService.refreshToken());
        } else {
          return of(false);
        }
      }),
      mergeMap(() => this.fetchBungieInfo()),
      tap((authenticated) => {
        this.authChanged.next(authenticated);
      })
    );
  }

  login() {
    this.oauthService.initLoginFlow('route=' + this.router.url);
  }

  logout() {
    this.oauthService.logOut();
    this.purge();
    this.router.navigate(['/']);
  }

  async redirect(state) {
    if (state && atob(state) && this.router.parseUrl(atob(state)) !== null) {
      await this.router.navigateByUrl(this.router.parseUrl(atob(state)));
    }
  }

  purge() {
    this.coreService.deleteOption('global.membership');
    this.coreService.removeLocalStorageItem(AuthService.CACHED_MEMBERSHIP_DATA);
    this.coreService.removeLocalStorageItem(AuthService.CACHED_VALIDMEMBERSHIPS_DATA);
    this.authenticated = false;
    this.authChanged.next(false);
  }

  setMembership(mbrId: string) {
    this.coreService.setOption('global.membership', mbrId);
    this.currentMemberShip = this.membershipData.destinyMemberships.find((m) => m.membershipId === mbrId);
  }

  getHeaders() {
    return {
      authorization: this.bearerToken
    };
  }

  hasCachedData() {
    return this.coreService.getLocalStorageItem(AuthService.CACHED_MEMBERSHIP_DATA) !== null &&
            this.coreService.getLocalStorageItem(AuthService.CACHED_VALIDMEMBERSHIPS_DATA) !== null;
  }

  cacheData() {
    this.coreService.setLocalStorageItem(AuthService.CACHED_MEMBERSHIP_DATA, JSON.stringify(this.membershipData));
    this.coreService.setLocalStorageItem(AuthService.CACHED_VALIDMEMBERSHIPS_DATA, JSON.stringify(this.validMemberships));
  }

  loadCachedData(): Observable<boolean> {
    this.oauthService.setupAutomaticSilentRefresh();
    const cachedMembershipData = this.coreService.getLocalStorageItem(AuthService.CACHED_MEMBERSHIP_DATA);
    const cachedValidMemberships = this.coreService.getLocalStorageItem(AuthService.CACHED_VALIDMEMBERSHIPS_DATA);
    try {
      if (cachedMembershipData && cachedValidMemberships) {
        this.membershipData = JSON.parse(cachedMembershipData);
        this.bungieMembership = this.membershipData.bungieNetUser;
        this.setMemberships(JSON.parse(cachedValidMemberships));
        this.authenticated = true;
        this.authChanged.next(true);
        return of(true);
      } else {
        this.coreService.removeLocalStorageItem(AuthService.CACHED_MEMBERSHIP_DATA);
        this.coreService.removeLocalStorageItem(AuthService.CACHED_VALIDMEMBERSHIPS_DATA);
        return of(false);
      }
    } catch (e) {
      this.coreService.removeLocalStorageItem(AuthService.CACHED_MEMBERSHIP_DATA);
      this.coreService.removeLocalStorageItem(AuthService.CACHED_VALIDMEMBERSHIPS_DATA);
      return of(false);
    }

  }

  private setMemberships(validMemberships: GroupUserInfoCard[]) {
    this.validMemberships = validMemberships;
    let currentMembershipId = this.coreService.getOption('global.membership');
    if (!currentMembershipId) {
      currentMembershipId = this.membershipData.primaryMembershipId || this.validMemberships[0].membershipId;
      this.coreService.setOption('global.membership', currentMembershipId);
    }
    this.currentMemberShip = this.membershipData.destinyMemberships.find((m) => m.membershipId === currentMembershipId);
  }

  private fetchBungieInfo(): Observable<boolean> {
    if (!this.oauthService.hasValidAccessToken()) {
      return of(false);
    } else {
      this.oauthService.setupAutomaticSilentRefresh();
      return from(getMembershipDataForCurrentUser(this.coreService.httpClient)).pipe(
        concatMap((result) => {
          this.membershipData = result.Response;
          this.bungieMembership = result.Response.bungieNetUser;
          return from(this.membershipData.destinyMemberships);
        }),
        filter((membership) => membership.applicableMembershipTypes.length > 0),
        concatMap((membershipData) => from(getProfile(this.coreService.httpClient, {
            components: [DestinyComponentType.None], membershipType: membershipData.membershipType, destinyMembershipId: membershipData.membershipId
          })).pipe( map(() => membershipData), catchError(() => of(null)))
        ),
        filter((e) => Boolean(e)),
        toArray(),
        map((validMemberships: GroupUserInfoCard[]) => {
          this.setMemberships(validMemberships);
          this.authenticated = true;
          this.cacheData();
          return true;
        }),
      );
    }
  }
}
