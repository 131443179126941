export const watermarkToSeason = {
  '/common/destiny2_content/icons/0dac2f181f0245cfc64494eccb7db9f7.png': 1,
  '/common/destiny2_content/icons/dd71a9a48c4303fd8546433d63e46cc7.png': 1,
  '/common/destiny2_content/icons/591f14483308beaad3278c3cd397e284.png': 2,
  '/common/destiny2_content/icons/50d36366595897d49b5d33e101c8fd07.png': 2,
  '/common/destiny2_content/icons/e10338777d1d8633e073846e613a1c1f.png': 3,
  '/common/destiny2_content/icons/aaa61f6c70478d87de0df41e5709a773.png': 3,
  '/common/destiny2_content/icons/0669efb55951e8bc9e99f3989eacc861.png': 4,
  '/common/destiny2_content/icons/02478e165d7d8d2a9f39c2796e7aac12.png': 4,
  '/common/destiny2_content/icons/bbddbe06ab72b61e708afc4fdbe15d95.png': 5,
  '/common/destiny2_content/icons/c23c9ec8709fecad87c26b64f5b2b9f5.png': 5,
  '/common/destiny2_content/icons/f9110e633634d112cff72a67159e3b12.png': 6,
  '/common/destiny2_content/icons/e4a1a5aaeb9f65cc5276fd4d86499c70.png': 6,
  '/common/destiny2_content/icons/785e5a64153cabd5637d68dcccb7fea6.png': 7,
  '/common/destiny2_content/icons/69bb11f24279c7a270c6fac3317005b2.png': 7,
  '/common/destiny2_content/icons/d4141b2247cf999c73d3dc409f9d00f7.png': 8,
  '/common/destiny2_content/icons/82a8d6f2b1e4ee14e853d4ffbe031406.png': 8,
  '/common/destiny2_content/icons/8aae1c411642683d341b2c4f16a7130c.png': 8,
  '/common/destiny2_content/icons/ee3f5bb387298acbdb03c01940701e63.png': 8,
  '/common/destiny2_content/icons/ac012e11fa8bb032b923ad85e2ffb29c.png': 9,
  '/common/destiny2_content/icons/9b7e4bbc576fd15fbf44dfa259f8b86a.png': 9,
  '/common/destiny2_content/icons/3d335ddc3ec6668469aae60baad8548d.png': 10,
  '/common/destiny2_content/icons/e27a4f39c1bb8c6f89613648afaa3e9f.png': 10,
  '/common/destiny2_content/icons/796813aa6cf8afe55aed4efc2f9c609b.png': 11,
  '/common/destiny2_content/icons/49dc693c5f3411b9638b97f38a70b69f.png': 11,
  '/common/destiny2_content/icons/2347cc2407b51e1debbac020bfcd0224.png': 12,
  '/common/destiny2_content/icons/d3cffdcb881085bc4fe19d9671c9eb0c.png': 12,
  '/common/destiny2_content/icons/0aff1f4463f6f44e9863370ab1ce6983.png': 12,
  '/common/destiny2_content/icons/1f702463c5e0c4e25c9f00a730dbc6ac.png': 12,
  '/common/destiny2_content/icons/4b6352a0fa6f68cd2b8ce5c3ba18747c.png': 12,
  '/common/destiny2_content/icons/6a52f7cd9099990157c739a8260babea.png': 13,
  '/common/destiny2_content/icons/e197b731c11556b17664b90a87dd0c11.png': 13,
  '/common/destiny2_content/icons/b07d89064a1fc9a8e061f59b7c747fa5.png': 14,
  '/common/destiny2_content/icons/a9faab035e2f59f802e99641a3aaab9e.png': 14,
  '/common/destiny2_content/icons/4368a3e344977c5551407845ede830c2.png': 15,
  '/common/destiny2_content/icons/dd4dd93c5606998595d9e5a06d5bfc9c.png': 15,
  '/common/destiny2_content/icons/4fe83598190610f122497d22579a1fd9.png': 16,
  '/common/destiny2_content/icons/d05833668bcb5ae25344dd4538b1e0b2.png': 16,
  '/common/destiny2_content/icons/b0406992c49c84bdc5febad94048dc01.png': 16,
  '/common/destiny2_content/icons/81edbfbf0bacf8e2117c00d1d6115f1b.png': 17
};
