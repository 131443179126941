import {
  DestinyRecipesWatcherType
} from './destiny-recipes-watcher';
import {
  DestinyRecipesItem
} from '../types/destiny-recipes-item';
import { ItemState } from 'bungie-api-ts/destiny2';
import { firstValueFrom } from 'rxjs';
import { ArmorQualityWatcher } from './armor-quality.watcher';
import { DataService } from '../services/data.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DimSyncService } from '../services/dim-sync.service';
import { ItemAnnotation, TagUpdate } from '@destinyitemmanager/dim-api-types';

export class ArmorQualityTagger extends ArmorQualityWatcher {

  constructor(
    protected dataService: DataService,
    protected toastr: ToastrService,
    protected translateService: TranslateService,
    private dimSyncService: DimSyncService,
  ) {
    super(dataService, toastr, translateService);
    this.type = DestinyRecipesWatcherType.ARMOR_QUALITY_TAGGER;
  }

  protected async lockItems(items: DestinyRecipesItem[]) {
    await super.lockItems(items);
    if (!this.dimSyncService.profile) {
      this.toastr.warning(this.translateService.instant('WATCHER.ARMOR_QUALITY.DIM_NOT_AVAILABLE'));
      return;
    }
    const updates: TagUpdate[] = items.map((item) => {
      return {
        action: 'tag',
        payload: {
          id: item.itemInstanceId,
          tag: 'keep',
          notes: this.translateService.instant('WATCHER.ARMOR_QUALITY_TAGGER.AUTO_KEPT_BY_DR_NOTE', { rating: this.config.data })
        }
      };
    });
    return firstValueFrom(this.dimSyncService.sendTagUpdates(updates));
  }
}
