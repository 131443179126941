import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CoreService } from './core.service';
import { DestinyInventoryItemDefinition, DestinyItemSubType, DestinySeasonDefinition } from 'bungie-api-ts/destiny2';
import { RecipeService } from '../appraiser/recipe.service';
import {
  DestinyRecipesItem,
  DestinyRecipesPerk,
  DestinyRecipesPerkRank,
  RatedDestinyRecipesPerks
} from '@Types/destiny-recipes-item';
import { RatingStatus, WeaponRating, WeaponRatingDetails } from '../appraiser/appraiser.types';
import * as _ from 'lodash';
import { RecipeHelper } from '@Types/recipe-helper';
import { RatingType } from '@Types/destiny-recipes.api';

@Injectable({
  providedIn: 'root'
})
export class AppraiserService {

  currentSeason: DestinySeasonDefinition;

  constructor(public data: DataService, private core: CoreService, private recipeService: RecipeService) {
  }

  init() {
    this.currentSeason = this.data.getDefinitionById<DestinySeasonDefinition>('DestinySeasonDefinition', this.data.globalDestinySettings.destiny2CoreSettings.currentSeasonHash);
  }

  rateItem(item: DestinyRecipesItem) {
    if (!this.recipeService.currentRecipe) {
      item.scoreAbsentReason = 'norecipe';
      return;
    }
    const recipe = this.getRatingSystemForItem(item.itemHash);
    if (!recipe) {
      item.scoreAbsentReason = 'norating';
      return;
    }
    item.score = this.getRating(item);
    item.perks = item.perks.map((column) => column.map((perk) => {
      const ratedPerk = recipe.perks.find((p) => p.plugHash === perk.plugHash);
      return {
        plugHash: perk.plugHash,
        active: perk.active,
        enhanced: perk.enhanced,
        available: perk.available,
        realSocketEntriesIndex: ratedPerk?.realSocketEntriesIndex || perk.realSocketEntriesIndex,
        proficiency: ratedPerk?.proficiency || {
          pve: DestinyRecipesPerkRank.B,
          pvp: DestinyRecipesPerkRank.B,
        },
      };
    }));
  }

  rateList(list: DestinyRecipesItem[]) {
    list.forEach((item) => {
      this.rateItem(item);
    });
  }

  getArchetypeStatus(hash: number, subType: DestinyItemSubType): RatingStatus {
    const hasArchetypeRatings = this.recipeService.currentRecipe?.hasRatingsForRatingType(hash, RatingType.archetypeRatings);
    if (hasArchetypeRatings) {
      const ratings = this.recipeService.currentRecipe?.getRatings(hash, RatingType.archetypeRatings, 4, subType);
      const hasPvERatings = ratings.perks.some((p) => p.proficiency.pve !== DestinyRecipesPerkRank.B);
      const hasPvPRatings = ratings.perks.some((p) => p.proficiency.pvp !== DestinyRecipesPerkRank.B);
      if (hasPvERatings && hasPvPRatings) {
        return 'all-available';
      } else if (hasPvPRatings || hasPvERatings) {
        return 'partially-available';
      }
    }
    return  'no-rating';
  }

  getStatus(hash: number): RatingStatus {
    const system = this.recipeService.currentRecipe?.getRatingSystemNameForItem(hash, undefined);
    switch (system) {
      case RatingType.archetypeRatings:
        return 'synced-archetype';
      case RatingType.typeRatings:
        return 'synced-type';
      case RatingType.weaponRatings:
        const ratings = this.getRatingSystemForItem(hash);
        const hasPvERatings = ratings.perks.some((p) => p.proficiency.pve !== DestinyRecipesPerkRank.B);
        const hasPvPRatings = ratings.perks.some((p) => p.proficiency.pvp !== DestinyRecipesPerkRank.B);
        if (hasPvERatings && hasPvPRatings) {
          return 'all-available';
        } else if (hasPvPRatings || hasPvERatings) {
          return 'partially-available';
        }
        break;
      default:
        return  'no-rating';
    }
    return  'no-rating';
  }

  getRatingSystemForItem(hash: number): RatedDestinyRecipesPerks {
    const system = this.recipeService.currentRecipe?.getRatingSystemNameForItem(hash, undefined);
    if (system === undefined) {
      return undefined;
    }
    return this.recipeService.currentRecipe.stagingContent[system][hash];
  }

  getMaximumScoreForItem(item: DestinyInventoryItemDefinition, withDetails?: boolean): WeaponRating {
    const ratingsUsed = this.getRatingSystemForItem(item.hash);
    if (!ratingsUsed) { return; }
    const columns = { pve: {}, pvp: {} };
    ratingsUsed.perks.forEach((perk) => {
      if (!columns.pvp[perk.realSocketEntriesIndex] || columns.pvp[perk.realSocketEntriesIndex] < perk.proficiency.pvp) {
        columns.pvp[perk.realSocketEntriesIndex] = perk.proficiency.pvp;
      }
      if (!columns.pve[perk.realSocketEntriesIndex] || columns.pve[perk.realSocketEntriesIndex] < perk.proficiency.pve) {
        columns.pve[perk.realSocketEntriesIndex] = perk.proficiency.pve;
      }
    });
    const result: WeaponRating = {
      pvp: Object.keys(columns.pvp).reduce((acc, cur, idx) => acc + columns.pvp[cur] * ratingsUsed.weights[idx], 0),
      pve: Object.keys(columns.pve).reduce((acc, cur, idx) => acc + columns.pve[cur] * ratingsUsed.weights[idx], 0)
    };
    result.notAvailable = {
      pvp: Object.keys(columns.pvp).every((k) => columns.pvp[k] === 0),
      pve: Object.keys(columns.pve).every((k) => columns.pve[k] === 0),
    };
    if (!withDetails) {
      return result;
    }
    return {
      ...result,
      details: columns
    };
  }

  getRating(instance: DestinyRecipesItem, withDetails: true): WeaponRatingDetails;
  getRating(instance: DestinyRecipesItem, withDetails?: false): WeaponRating;
  getRating(instance: DestinyRecipesItem, withDetails?: boolean): WeaponRating | WeaponRatingDetails {
    const ratingsUsed = this.getRatingSystemForItem(instance.itemHash);
    if (!ratingsUsed) { return; }
    const maxScore = this.getMaximumScoreForItem(instance.definition, withDetails);
    const rating: WeaponRating = { pve: 0, pvp: 0 };
    const details = { pve: {}, pvp: {} };
    instance.perks.forEach((column, idx) => {
      const ratedPve = ratingsUsed.perks.filter((perk) => column.findIndex((p) => p.plugHash === perk.plugHash) > -1);
      const ratedPvp = ratingsUsed.perks.filter((perk) => column.findIndex((p) => p.plugHash === perk.plugHash) > -1);
      const bestPve: DestinyRecipesPerk = _.maxBy(ratedPve, 'proficiency.pve');
      const bestPvp: DestinyRecipesPerk = _.maxBy(ratedPvp, 'proficiency.pvp');
      if (bestPve) {
        const weight = RecipeHelper.getWeightForSocketIndex(ratingsUsed, instance.definition, bestPve.realSocketEntriesIndex);
        details.pve[idx] = { perk: this.data.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', bestPve.plugHash), weight, score: bestPve.proficiency.pve };
        rating.pve += bestPve.proficiency.pve * weight;
      }
      if (bestPvp) {
        const weight = RecipeHelper.getWeightForSocketIndex(ratingsUsed, instance.definition, bestPvp.realSocketEntriesIndex);
        details.pvp[idx] = { perk: this.data.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', bestPvp.plugHash), weight, score: bestPve.proficiency.pvp };
        rating.pvp += bestPvp.proficiency.pvp * weight;
      }
    });
    const result = {
      pvp: rating.pvp / (maxScore.pvp || 1),
      pve: rating.pve / (maxScore.pve || 1),
      notAvailable: maxScore.notAvailable,
    };
    if (withDetails) {
      return {
        ...result,
        details,
        maxScore,
        raw: {
          pvp: rating.pvp,
          pve: rating.pve
        }
      };
    } else {
      return result;
    }}
}
