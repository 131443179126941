import { Component, Input, OnInit } from '@angular/core';
import { DestinyObjectiveDefinition, DestinyObjectiveProgress } from 'bungie-api-ts/destiny2/interfaces';
import { DataService } from '../../services/data.service';
import { DestinySymbolResolver } from '../../types';

@Component({
  selector: 'app-objective-description',
  templateUrl: './objective-description.component.html',
  styleUrls: ['./objective-description.component.scss']
})
export class ObjectiveDescriptionComponent implements OnInit {

  @Input() objective: DestinyObjectiveProgress;
  @Input() objectiveDefinition: DestinyObjectiveDefinition;

  displayText: string;
  displayIcon: string[] = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    const def = this.objectiveDefinition || this.dataService.getDefinitionById<DestinyObjectiveDefinition>('DestinyObjectiveDefinition', this.objective.objectiveHash);
    if (!!def) {
      this.displayText = def.progressDescription.replace(/(\[[^\]]+\]|[\uE000-\uF8FF])/g, '');
      def.progressDescription.match(/(\[[^\]]+\]|[\uE000-\uF8FF])/g)?.forEach((match) => {
        this.displayIcon.push(DestinySymbolResolver(match));
      });
    }
  }

}
