import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { CoreService, PerkPopupConfig } from '@Services/core.service';

@Directive({
  selector: '[perkPopupTrigger]'
})
export class PerkPopupTriggerDirective implements OnDestroy {

  @Input() perkPopupTrigger: PerkPopupConfig;
  @Input() popupDisabled: boolean;
  @Input() disabledHoverBehavior: boolean;

  constructor(private coreService: CoreService) {
  }

  ngOnDestroy(): void {
    this.coreService.togglePerkPopup(undefined, undefined);
  }

  @HostListener('mouseover', ['$event'])
  onMouseover(event: MouseEvent) {
    if (!this.disabledHoverBehavior) {
      if (!this.popupDisabled) {
        this.coreService.togglePerkPopup(this.perkPopupTrigger, {x: event.clientX, y: event.clientY});
      } else {
        this.coreService.togglePerkPopup(undefined, undefined);
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.popupDisabled) {
      this.coreService.togglePerkPopup(this.perkPopupTrigger, {x: event.clientX, y: event.clientY});
      event.stopImmediatePropagation();
    } else {
      this.coreService.togglePerkPopup(undefined, undefined);
    }
  }

  @HostListener('mouseout', ['$event'])
  onMouseout() {
    this.coreService.togglePerkPopup(undefined, undefined);
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (!this.disabledHoverBehavior) {
      if (!this.popupDisabled) {
        this.coreService.perkPopupPosition = {x: event.clientX, y: event.clientY};
      } else {
        this.coreService.togglePerkPopup(undefined, undefined);
      }
    }
  }
}
