import { GeneralUser } from 'bungie-api-ts/user';
import { ChecklistTracker } from '../checklist/checklist.types';
import { DestinyItemType } from 'bungie-api-ts/destiny2';
import { WeaponWishes } from '../checklist/weapons/targeted-weapons';
import { RatedDestinyRecipesPerks } from './destiny-recipes-item';

export interface AuthTokenResponse {
  accessToken: string;
  bungieNetUser: GeneralUser;
  memberships: string[];
  expiresIn: number;
}

export interface Checklist {
  tokens: ChecklistTracker[];
  custom: ChecklistTracker[];
  targetedWeapons?: WeaponWishes[];
}

export interface ChecklistDocument {
  owner: string;
  memberships: string[];
  code: string;
  checklist: string;
}
export interface ServerChecklistResponse {
  list: ChecklistDocument;
  searchTerm?: string;
  userId?: string;
}


export enum RecipeTags {
  pvp = 'pvp',
  pve = 'pve',
  controller = 'controller',
  mnk = 'mnk'
}

export interface Recipe {
  _id: string;
  name: string;
  description: string;
  owner: string;
  ownerName: string;
  tags: RecipeTags[];
  lastUpdate: Date;
  coverage: RecipeCoverage;
  seasonNumber: number;
  contentLocation: string;
}

export enum RatingType {
  typeRatings = 'typeRatings',
  archetypeRatings = 'archetypeRatings',
  weaponRatings = 'weaponRatings',
}

export const ratingTypesSorted: Array<RatingType> = [RatingType.typeRatings, RatingType.archetypeRatings, RatingType.weaponRatings];

export type RecipeContentRating = {[key in keyof typeof RatingType]?: {[key: number]: RatedDestinyRecipesPerks}};

export interface RecipeContent extends RecipeContentRating {
  synergies: any; // TODO
  antiSynergies: any; // TODO
}


export interface RecipeCreationDto {
  name: string;
  description: string;
  tags: RecipeTags[];
  coverage: number;
  seasonNumber: number;
}

export interface RecipeCompletionComponent {
  itemType: DestinyItemType;
  treated: number;
  total: number;
}

export interface RecipeCoverage {
  byWeaponRating: number;
  byArchetypeRating: number;
  byTypeRating: number;
  total: number;
}

export function defaultRecipeContent(): RecipeContent {
  return {
    archetypeRatings: {},
    typeRatings: {},
    weaponRatings: {},
    antiSynergies: null,
    synergies: null,
  };
}
