import { Injectable } from '@angular/core';
import { VaultCleanerConfig, VaultCleanerFactoryId, VaultConfigHelper } from '../vault-cleaner/vault-cleaner.types';
import { DataService } from './data.service';
import { DestinyPowerCapDefinition, ItemState, TierType } from 'bungie-api-ts/destiny2';
import * as _ from 'lodash';
import { armorShapeMapOrdered, DestinyRecipesItem, DisciplineHash } from '../types/destiny-recipes-item';
import { ItemHelper } from '../common/item.helper';
import { SocketHash } from '../types';
import { ImportedDataService } from './imported-data.service';
import { CoreService } from './core.service';
import { DimSyncService } from './dim-sync.service';

export type RuleResult = { result: boolean, value?: any };

@Injectable({providedIn: 'root'})
export class RulesService {

  get dataService(): DataService {
    return this.coreService.usingImportedData ? this.importedData : this.data;
  }

  constructor(private coreService: CoreService, private data: DataService, private importedData: ImportedDataService, private dimSyncService: DimSyncService) {
    this.initMatrix();
  }
  static ArmorRules = [
    VaultCleanerFactoryId.ARMOR_BASE,
    VaultCleanerFactoryId.ARMOR_ALL,
    VaultCleanerFactoryId.ARMOR_MASTERWORK,
    VaultCleanerFactoryId.ARMOR_SUNSET,
    VaultCleanerFactoryId.ARMOR_NEXT_SUNSET,
    VaultCleanerFactoryId.ARMOR_EXOTICS,
    VaultCleanerFactoryId.ARMOR_BEST_POWER_LEVEL,
    VaultCleanerFactoryId.ARMOR_DIM_KEEP_KEEP,
    VaultCleanerFactoryId.ARMOR_DIM_KEEP_FAVORITE,
    VaultCleanerFactoryId.ARMOR_DIM_KEEP_INFUSE,
    VaultCleanerFactoryId.ARMOR_DIM_REMOVE_JUNK,
    VaultCleanerFactoryId.ARMOR_QUALITY,
    VaultCleanerFactoryId.ARMOR_BOTTOM_SCORE,
    VaultCleanerFactoryId.ARMOR_RAID,
    VaultCleanerFactoryId.ARMOR_TOP_SCORE,
    VaultCleanerFactoryId.ARMOR_MIN_DIS,
    VaultCleanerFactoryId.ARMOR_MOBRES_QUALITY,
    VaultCleanerFactoryId.ARMOR_RECRES_QUALITY,
  ];
  static WeaponRules = [
    VaultCleanerFactoryId.WEAPONS_ALL,
    VaultCleanerFactoryId.WEAPONS_GODROLL_PVE,
    VaultCleanerFactoryId.WEAPONS_GODROLL_PVP,
    VaultCleanerFactoryId.WEAPONS_SUNSET,
    VaultCleanerFactoryId.WEAPONS_NEXT_SUNSET,
    VaultCleanerFactoryId.WEAPONS_MASTERWORK,
    VaultCleanerFactoryId.WEAPONS_EXOTICS,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_KEEP,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_FAVORITE,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_INFUSE,
    VaultCleanerFactoryId.WEAPONS_DIM_REMOVE_JUNK,
    VaultCleanerFactoryId.WEAPONS_CRAFTED,
    VaultCleanerFactoryId.WEAPONS_HAS_CRAFTED,
    VaultCleanerFactoryId.WEAPONS_CAN_CRAFT,
    VaultCleanerFactoryId.WEAPONS_DEEPSIGHT,
  ];

  ruleMatrix: {[ruleId: string]: (item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultConfig?: VaultCleanerConfig) => RuleResult};
  currentSeasonArmorItemHashes = [ // Season of the Risen
    // WARLOCK
    738153648, 1184324662, // head
    535937281, 3071673277, // arms
    2165756235, 3833868247, // chest
    1447413863, 2030782835, // boots
    1516737124, 1667220390, // classItem

    // TITAN
    642468533, 859087913, // head
    1136162598, 2945464224, // arms
    130618344, 384833278, // chest
    1218431672, 1640403802, // boots
    1830049473, 1832715717, // classItem

    // HUNTER
    1095145125, 3838798113, // head
    1834245236, 2160775258, // arms
    92499596, 1901340770, // chest
    2121983870, 2840844524, // boots
    39811837, 2325223873, // classItem
  ];

  private static keepAll(): RuleResult {
    return { result: true };
  }

  private static checkMasterwork(item: DestinyRecipesItem): RuleResult {
    if ((item.state & ItemState.Masterwork) !== 0) {
      return { result: true };
    }
    return { result: undefined };
  }

  private static checkExotic(item: DestinyRecipesItem): RuleResult {
    if (item.definition.inventory.tierType === TierType.Exotic) {
      return { result: true };
    }
    return { result: undefined };
  }

  private static checkBestRollPoly(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultConfig: VaultCleanerConfig): RuleResult {
    const config = VaultConfigHelper.getRuleConfig(vaultConfig, VaultCleanerFactoryId.WEAPONS_PREFER_POLY);
    if (item.score) {
      const maxPolyItem = _.maxBy(allItems, (i) => i.score?.polyvalent || 0);
      const isBestPoly = item.score.polyvalent >= 0 && item.score.polyvalent === maxPolyItem?.score?.polyvalent;
      if (isBestPoly) {
        ItemHelper.setContextData(item, { goodRoll: 'both' });
      }
      if (!config || config.customization.formControl.value > 100) {
        return { result: isBestPoly || undefined, value: Math.ceil(item.score.polyvalent * 100) }; // Not being the best roll is not an automatic discard
      } else if (config?.customization.formControl.value < 0) {
        return { result: true, value: Math.ceil(item.score.polyvalent * 100) };
      }  else if (item.score.polyvalent >= config?.customization.formControl.value / 100) {
        return { result: true, value: Math.ceil(item.score.polyvalent * 100) };
      }
    } else if (item.definition.inventory.tierType !== TierType.Exotic) {
      return { result: true, value: '-' };
    }
    return { result: undefined };
  }

  private static getBestRolls(item: DestinyRecipesItem, allItems: DestinyRecipesItem[]) {
    const maxPveItem = _.maxBy(allItems, (i) => i.score?.pve || 0);
    const maxPvpItem = _.maxBy(allItems, (i) => i.score?.pvp || 0);
    const isBestPve = item.score.pve >= 0 && item.score.pve === maxPveItem?.score?.pve;
    const isBestPvp = item.score.pvp >= 0 && item.score.pvp === maxPvpItem?.score?.pvp;
    return { isBestPve, isBestPvp };
  }

  private static checkBestRollPvE(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultConfig: VaultCleanerConfig): RuleResult {
    const config = VaultConfigHelper.getRuleConfig(vaultConfig, VaultCleanerFactoryId.WEAPONS_GODROLL_PVE);
    if (item.score) {
      const bestRolls = RulesService.getBestRolls(item, allItems);
      if (bestRolls.isBestPve) {
        ItemHelper.setContextData(item, {goodRoll: bestRolls.isBestPvp ? 'both' : 'pve' });
      }
      if (!config || config.customization.formControl.value > 100) {
        return { result: bestRolls.isBestPve || undefined, value: Math.ceil(item.score.pve * 100) }; // Not being the best roll is not an automatic discard
      } else if (config?.customization.formControl.value < 0) {
        return { result: undefined, value: Math.ceil(item.score.pve * 100) };
      }  else if (item.score.pve >= config?.customization.formControl.value / 100) {
        return { result: true, value: Math.ceil(item.score.pve * 100) };
      }
    } else if (item.definition.inventory.tierType !== TierType.Exotic) {
      return { result: true, value: '-' };
    }
    return { result: undefined, value: '-' };
  }

  private static checkBestRollPvP(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultConfig: VaultCleanerConfig): RuleResult {
    const config = VaultConfigHelper.getRuleConfig(vaultConfig, VaultCleanerFactoryId.WEAPONS_GODROLL_PVP);
    if (item.score) {
      const bestRolls = RulesService.getBestRolls(item, allItems);
      if (bestRolls.isBestPvp) {
        ItemHelper.setContextData(item, {goodRoll: bestRolls.isBestPve ? 'both' : 'pvp' });
      }
      if (!config || config.customization.formControl.value > 100) {
        return { result: bestRolls.isBestPvp || undefined, value: Math.ceil(item.score.pvp * 100) }; // Not being the best roll is not an automatic discard
      } else if (config?.customization.formControl.value < 0) {
        return { result: undefined, value: Math.ceil(item.score.pvp * 100) };
      } else if (item.score.pvp >= config?.customization.formControl.value / 100) {
        return { result: true, value: Math.ceil(item.score.pvp * 100) };
      }
    } else if (item.definition.inventory.tierType !== TierType.Exotic) {
      return { result: true, value: '-' };
    }
    return { result: undefined, value: '-' };
  }

  private static legendaryCheck(item: DestinyRecipesItem): RuleResult {
    if (item.definition.inventory.tierType >= TierType.Superior) {
      return { result: undefined };
    }
    return { result: false };
  }

  private static checkCurated(item: DestinyRecipesItem): RuleResult {
    return { result: item.curated || undefined };
  }

  private static checkLocked(item: DestinyRecipesItem): RuleResult {
    if (ItemHelper.isLocked(item)) {
      return { result: true };
    }
    return { result: undefined };
  }

  private static checkArmorIsRaid(item: DestinyRecipesItem): RuleResult {
    if (ItemHelper.hasSocket(item, SocketHash.DeepStoneCrypt) ||
      ItemHelper.hasSocket(item, SocketHash.GardenOfSalvation) ||
      ItemHelper.hasSocket(item, SocketHash.LastWish) ||
      ItemHelper.hasSocket(item, SocketHash.VaultOfGlass) ||
      ItemHelper.hasSocket(item, SocketHash.VowOfTheDisciple) ||
      ItemHelper.hasSocket(item, SocketHash.RootOfNightmare) ||
      ItemHelper.hasSocket(item, SocketHash.KingsFall) ||
      ItemHelper.hasSocket(item, SocketHash.CrotasEnd)
    ) {
      return { result: true };
    }
    return { result: undefined };
  }

  private static checkCrafted(item: DestinyRecipesItem): RuleResult {
    return { result: item.crafted || undefined };
  }

  private static checkDeepsight(item: DestinyRecipesItem): RuleResult {
    return { result: item.deepsight || undefined };
  }

  /*
   * Behavior:
   * - Method returns TRUE = keep
   * - Method returns FALSE = remove
   * - Method returns undefined = rule ignored
   * One FALSE is enough to discard an item
   */
  initMatrix() {
    this.ruleMatrix = {
      [VaultCleanerFactoryId.WEAPONS_ALL]: RulesService.keepAll,
      [VaultCleanerFactoryId.WEAPONS_BASE]: RulesService.legendaryCheck,
      [VaultCleanerFactoryId.WEAPONS_GODROLL_PVE]: RulesService.checkBestRollPvE,
      [VaultCleanerFactoryId.WEAPONS_GODROLL_PVP]: RulesService.checkBestRollPvP,
      [VaultCleanerFactoryId.WEAPONS_PREFER_POLY]: RulesService.checkBestRollPoly,
      [VaultCleanerFactoryId.WEAPONS_SUNSET]: this.checkCurrentSunset.bind(this),
      [VaultCleanerFactoryId.WEAPONS_NEXT_SUNSET]: this.checkNextSunset.bind(this),
      [VaultCleanerFactoryId.WEAPONS_MASTERWORK]: RulesService.checkMasterwork,
      [VaultCleanerFactoryId.WEAPONS_EXOTICS]: RulesService.checkExotic,
      [VaultCleanerFactoryId.WEAPONS_LOCKED]: RulesService.checkLocked,
      [VaultCleanerFactoryId.WEAPONS_CURATED]: RulesService.checkCurated,
      [VaultCleanerFactoryId.WEAPONS_BEST_POWER_LEVEL]: this.checkBestPowerLevel.bind(this),
      [VaultCleanerFactoryId.WEAPONS_CRAFTED]: RulesService.checkCrafted,
      [VaultCleanerFactoryId.WEAPONS_HAS_CRAFTED]: this.checkHasCrafted.bind(this),
      [VaultCleanerFactoryId.WEAPONS_CAN_CRAFT]: this.checkCanCraft.bind(this),
      [VaultCleanerFactoryId.WEAPONS_DEEPSIGHT]: RulesService.checkDeepsight,

      [VaultCleanerFactoryId.WEAPONS_DIM_KEEP_KEEP]: this.checkDIMKeepTag.bind(this),
      [VaultCleanerFactoryId.WEAPONS_DIM_KEEP_FAVORITE]: this.checkDIMFavoriteTag.bind(this),
      [VaultCleanerFactoryId.WEAPONS_DIM_KEEP_INFUSE]: this.checkDIMInfuseTag.bind(this),
      [VaultCleanerFactoryId.WEAPONS_DIM_KEEP_ARCHIVE]: this.checkDIMArchiveTag.bind(this),
      [VaultCleanerFactoryId.WEAPONS_DIM_KEEP_LOADOUT]: this.checkDIMLoadout.bind(this),
      [VaultCleanerFactoryId.WEAPONS_DIM_REMOVE_JUNK]: this.checkDIMRemoveTag.bind(this),

      [VaultCleanerFactoryId.ARMOR_BASE]: RulesService.legendaryCheck,
      [VaultCleanerFactoryId.ARMOR_ALL]: RulesService.keepAll,
      [VaultCleanerFactoryId.ARMOR_MASTERWORK]: RulesService.checkMasterwork,
      [VaultCleanerFactoryId.ARMOR_SUNSET]: this.checkCurrentSunset.bind(this),
      [VaultCleanerFactoryId.ARMOR_NEXT_SUNSET]: this.checkNextSunset.bind(this),
      [VaultCleanerFactoryId.ARMOR_EXOTICS]: RulesService.checkExotic,
      [VaultCleanerFactoryId.ARMOR_RAID]: RulesService.checkArmorIsRaid,

      [VaultCleanerFactoryId.ARMOR_TOP_SCORE]: this.checkTopScore.bind(this),
      [VaultCleanerFactoryId.ARMOR_BOTTOM_SCORE]: this.checkBottomScore.bind(this),
      [VaultCleanerFactoryId.ARMOR_QUALITY]: this.checkArmorQuality.bind(this),
      [VaultCleanerFactoryId.ARMOR_RECRES_QUALITY]: this.checkRecRes.bind(this),
      [VaultCleanerFactoryId.ARMOR_MOBRES_QUALITY]: this.checkMobRes.bind(this),
      [VaultCleanerFactoryId.ARMOR_MIN_DIS]: this.checkMinDis.bind(this),

      [VaultCleanerFactoryId.ARMOR_BEST_POWER_LEVEL]: this.checkBestPowerLevel.bind(this),
      [VaultCleanerFactoryId.ARMOR_LOCKED]: RulesService.checkLocked,

      [VaultCleanerFactoryId.ARMOR_DIM_KEEP_KEEP]: this.checkDIMKeepTag.bind(this),
      [VaultCleanerFactoryId.ARMOR_DIM_KEEP_FAVORITE]: this.checkDIMFavoriteTag.bind(this),
      [VaultCleanerFactoryId.ARMOR_DIM_KEEP_INFUSE]: this.checkDIMInfuseTag.bind(this),
      [VaultCleanerFactoryId.ARMOR_DIM_REMOVE_JUNK]: this.checkDIMRemoveTag.bind(this),
      [VaultCleanerFactoryId.ARMOR_DIM_KEEP_ARCHIVE]: this.checkDIMArchiveTag.bind(this),
      [VaultCleanerFactoryId.ARMOR_DIM_KEEP_LOADOUT]: this.checkDIMLoadout.bind(this),
    };
  }

  checkRules(vaultConfig: VaultCleanerConfig, rules: VaultCleanerFactoryId[], items: DestinyRecipesItem[]): DestinyRecipesItem[] {
    items.forEach((item) => {
      let allItems;
      item.vaultCleanerResult = [];
      if (item.isArmor) {
        allItems = this.dataService.filterInventory({fields: {armorPieceType: item.armorPieceType, classType: item.classType}});
      } else if (item.isWeapon) {
        allItems = this.dataService.filterInventory({fields: {itemHash: item.itemHash}});
      } else {
        return;
      }
      rules
        .filter((rule) => {
          const ruleConfig = vaultConfig?.weapons.options.find((r) => r.id === rule) || vaultConfig?.armor.options.find((r) => r.id === rule);
          return this.ruleMatrix[rule] !== undefined && (!ruleConfig || (!ruleConfig.isActive || ruleConfig.isActive()));
        })
        .forEach((rule) => {
          const ruleResult = this.ruleMatrix[rule](item, allItems, vaultConfig);
          const vaultCleanerResultExistingIdx = item.vaultCleanerResult.findIndex((r) => r.rule === rule);
          if (vaultCleanerResultExistingIdx === -1) {
            item.vaultCleanerResult.push({ rule, result: ruleResult });
          } else {
            item.vaultCleanerResult[vaultCleanerResultExistingIdx].result = ruleResult;
          }
        });
    });
    return items;
  }

  // Rule functions have 3 possible return values : (false) discards the item (true) keep the item (undefined) ignores the rule
  execRule(rule: VaultCleanerFactoryId, item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultConfig: VaultCleanerConfig): RuleResult {
    if (!item) {
      return { result: false };
    }
    const ruleConfig = vaultConfig.weapons.options.find((r) => r.id === rule) || vaultConfig.armor.options.find((r) => r.id === rule);
    if (this.ruleMatrix[rule] !== undefined && ruleConfig && (!ruleConfig.isActive || ruleConfig.isActive())) {
      return this.ruleMatrix[rule](item, allItems, vaultConfig);
    } else if (this.ruleMatrix[rule] && ruleConfig) {
      throw new Error('Rule not active :' + rule);
    }
    console.warn('Rule not implemented:' + rule);
    return { result: undefined };
  }

  private checkNextSunset(item: DestinyRecipesItem) {
    if (!item.definition.quality || !item.definition.quality.versions) {
      return undefined;
    }
    const hasRequirement = this.getCompatiblePowerCaps(this.dataService.currentSeason.index + 1).includes(item.definition.quality.versions[item.versionNumber || 0].powerCapHash);
    if (!hasRequirement) {
      return false;
    }
    return { result: undefined };
  }

  private checkCurrentSunset(item: DestinyRecipesItem) {
    if (!item.definition.quality || !item.definition.quality.versions) {
      return undefined;
    }
    const hasRequirement =  this.getCompatiblePowerCaps(this.dataService.currentSeason.index).includes(item.definition.quality.versions[item.versionNumber || 0].powerCapHash);
    if (!hasRequirement) {
      return false;
    }
    return { result: undefined };
  }

  private getCompatiblePowerCaps(seasonMin: number) {
    const powerCaps: DestinyPowerCapDefinition[] = this.dataService.getDefinitions('DestinyPowerCapDefinition').values;
    const minPowerCap = powerCaps.find((p) => p.index === seasonMin - 2)?.powerCap || 0;
    return powerCaps.filter((cap) => cap?.powerCap >= minPowerCap).map((e) => e.hash) || [];
  }

  private checkBestPowerLevel(item: DestinyRecipesItem, allItems: DestinyRecipesItem[]): RuleResult {
    if (item.isWeapon) {
      const sameCategory = this.dataService.inventory.filter((e) => e.isWeapon && e.bucketHash === item.bucketHash);
      item.checkedAttributes.isHighestPower = item.powerLevel === Math.max(...sameCategory.map((i) => i.powerLevel));
    } else {
      item.checkedAttributes.isHighestPower = item.powerLevel === Math.max(...allItems.map((i) => i.powerLevel));
    }
    return { result: item.checkedAttributes.isHighestPower || undefined };
  }

  private checkArmorCurrentSeason(item: DestinyRecipesItem): RuleResult {
    if (this.currentSeasonArmorItemHashes.indexOf(item.itemHash) > -1) {
      return { result: true };
    }
    return { result: undefined };
  }

  private checkDIMKeepTag(item: DestinyRecipesItem): RuleResult {
    const DIMItem = this.dimSyncService.getAnnotationsForItem(item.itemInstanceId);
    if (DIMItem?.tag === 'keep') {
      return { result: true };
    }
    return { result: undefined };
  }

  private checkDIMFavoriteTag(item: DestinyRecipesItem): RuleResult {
    const DIMItem = this.dimSyncService.getAnnotationsForItem(item.itemInstanceId);
    if (DIMItem?.tag === 'favorite') {
      return { result: true };
    }
    return { result: undefined };
  }

  private checkDIMInfuseTag(item: DestinyRecipesItem): RuleResult {
    const DIMItem = this.dimSyncService.getAnnotationsForItem(item.itemInstanceId);
    if (DIMItem?.tag === 'infuse') {
      return { result: true };
    }
    return { result: undefined };
  }

  private checkDIMRemoveTag(item: DestinyRecipesItem): RuleResult {
    const DIMItem = this.dimSyncService.getAnnotationsForItem(item.itemInstanceId);
    if (DIMItem?.tag === 'junk') {
      return { result: false };
    }
    return { result: undefined };
  }

  private checkDIMArchiveTag(item: DestinyRecipesItem): RuleResult {
    const DIMItem = this.dimSyncService.getAnnotationsForItem(item.itemInstanceId);
    if (DIMItem?.tag === 'archive') {
      return { result: true };
    }
    return { result: undefined };
  }

  private checkDIMLoadout(item: DestinyRecipesItem): RuleResult {
    const isInLoadout = this.dimSyncService.getLoadoutsForItem(item.itemInstanceId)?.length > 0;
    if (isInLoadout) {
      return { result: true };
    }
    return { result: undefined };
  }

  private checkArmorQuality(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultCleanerConfig: VaultCleanerConfig): RuleResult {
    const config = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_QUALITY);
    if (!item.armorAppraisal) {
      return { result: undefined, value: '-' };
    }
    if (config.customization.formControl.value === 'Best')  {
      // Keep best only
      const bestQuality = _.minBy(allItems, (i: DestinyRecipesItem) => !i.armorAppraisal ? Number.POSITIVE_INFINITY : i.armorAppraisal.qualityDecay);
      if (!bestQuality || item.armorAppraisal.qualityDecay <= bestQuality?.armorAppraisal.qualityDecay) {
        return { result: true, value: item.armorAppraisal.qualityDecay };
      }
    } else if (armorShapeMapOrdered[config.customization.formControl.value] !== undefined) {
      // Keep minimum quality
      return { result: armorShapeMapOrdered[item.armorAppraisal.rating] >= armorShapeMapOrdered[config.customization.formControl.value] || undefined, value: item.armorAppraisal.qualityDecay };
    }
    return { result: undefined, value: item.armorAppraisal.qualityDecay };
  }

  private checkTopScore(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultCleanerConfig: VaultCleanerConfig): RuleResult {
    const config = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_TOP_SCORE);
    return this.checkScore(item, allItems, config.customization.formControl.value, 'top');
  }

  private checkBottomScore(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultCleanerConfig: VaultCleanerConfig): RuleResult {
    const config = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_BOTTOM_SCORE);
    return this.checkScore(item, allItems, config.customization.formControl.value, 'bottom');
  }

  private checkScore(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], minScore: number, segment: 'top' | 'bottom'): RuleResult {
    if (minScore > 34) {
      // Keep best only
      const bestScore = _.maxBy(allItems, (i: DestinyRecipesItem) => i.armorAppraisal?.[segment].points);
      if (item.armorAppraisal[segment].points >= bestScore.armorAppraisal[segment].points) {
        return { result: true, value: item.armorAppraisal[segment].points };
      }
    } else if (minScore >= 22) {
      // check individual shape
      return { result: item.armorAppraisal[segment].points >= minScore, value: item.armorAppraisal[segment].points };
    }
    return { result: undefined, value: item.armorAppraisal[segment].points };
  }

  private checkRecRes(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultCleanerConfig: VaultCleanerConfig, checkOtherBuild = true): RuleResult {
    const config = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_RECRES_QUALITY);
    const configOtherBuild = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_MOBRES_QUALITY);
    const minScore = config.customization.formControl.value;
    if (minScore > 0) {
      // Keep best only
      const bestScore = _.minBy(allItems, (i: DestinyRecipesItem) => i.armorAppraisal.top.recres.buildSegmentGap);
      if (item.armorAppraisal.top.recres.buildSegmentGap <= bestScore.armorAppraisal.top.recres.buildSegmentGap) {
        return { result: true, value: item.armorAppraisal.top.recres.buildSegmentGap };
      }
    } else if (minScore >= -12) {
      // check for min score (segment gap is positive, but input is negative, have to reverse everything)
      if (checkOtherBuild && configOtherBuild.execute) {
        const otherBuildResult = this.checkMobRes(item, allItems, vaultCleanerConfig, false);
        if (item.armorAppraisal.top.recres.buildSegmentGap <= -minScore) {
          return { result: true, value: item.armorAppraisal.top.recres.buildSegmentGap };
        }
        return { result: otherBuildResult ? undefined : false, value: item.armorAppraisal.top.recres.buildSegmentGap };
      }
      return { result: item.armorAppraisal.top.recres.buildSegmentGap <= -minScore, value: item.armorAppraisal.top.recres.buildSegmentGap };
    }
    return { result: undefined, value: item.armorAppraisal.top.recres.buildSegmentGap };
  }

  private checkMobRes(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultCleanerConfig: VaultCleanerConfig, checkOtherBuild = true): RuleResult {
    const config = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_MOBRES_QUALITY);
    const configOtherBuild = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_RECRES_QUALITY);
    const minScore = config.customization.formControl.value;
    if (minScore > 0) {
      // Keep best only
      const bestScore = _.minBy(allItems, (i: DestinyRecipesItem) => i.armorAppraisal.top.mobres.buildSegmentGap);
      if (item.armorAppraisal.top.mobres.buildSegmentGap <= bestScore.armorAppraisal.top.mobres.buildSegmentGap) {
        return { result: true, value: item.armorAppraisal.top.mobres.buildSegmentGap };
      }
    } else if (minScore >= -12) {
      // check for min score (segment gap is positive, but input is negative, have to reverse everything)
      if (checkOtherBuild && configOtherBuild.execute) {
        const otherBuildResult = this.checkRecRes(item, allItems, vaultCleanerConfig, false);
        if (item.armorAppraisal.top.mobres.buildSegmentGap <= -minScore) {
          return { result: true, value: item.armorAppraisal.top.mobres.buildSegmentGap };
        }
        return { result: otherBuildResult ? undefined : false, value: item.armorAppraisal.top.mobres.buildSegmentGap };
      }
      return { result: item.armorAppraisal.top.mobres.buildSegmentGap <= -minScore, value: item.armorAppraisal.top.mobres.buildSegmentGap };
    }
    return { result: undefined, value: item.armorAppraisal.top.mobres.buildSegmentGap };
  }

  private checkMinDis(item: DestinyRecipesItem, allItems: DestinyRecipesItem[], vaultCleanerConfig: VaultCleanerConfig): RuleResult {
    const config = vaultCleanerConfig.armor.options.find((o) => o.id === VaultCleanerFactoryId.ARMOR_MIN_DIS);
    const minScore = config.customization.formControl.value;
    if (minScore > 30) {
      // Keep best only
      const bestScore = _.maxBy(allItems, (i: DestinyRecipesItem) => i.armorAppraisal.stats[DisciplineHash]);
      if (item.armorAppraisal.stats[DisciplineHash] >= bestScore.armorAppraisal.stats[DisciplineHash]) {
        return { result: true, value: item.armorAppraisal.stats[DisciplineHash]};
      }
    } else if (minScore >= 0) {
      return { result: item.armorAppraisal.stats[DisciplineHash] >= minScore, value: item.armorAppraisal.stats[DisciplineHash] };
    }
    return { result: undefined, value: item.armorAppraisal.stats[DisciplineHash] };
  }

  private checkHasCrafted(item: DestinyRecipesItem, allItems: DestinyRecipesItem[]): RuleResult {
    if (!ItemHelper.isCraftable(item, this.data)) {
      return { result: undefined };
    }
    const hasCrafted = allItems.find((i) => i.crafted && i.itemHash === item.itemHash && i.itemInstanceId !== item.itemInstanceId);
    return { result: hasCrafted ? false : undefined };
  }

  private checkCanCraft(item: DestinyRecipesItem, allItems: DestinyRecipesItem[]): RuleResult {
    if (!ItemHelper.isCraftable(item, this.data)) {
      return { result: undefined };
    }
    const hasCrafted = allItems.find((i) => i.crafted && i.itemHash === item.itemHash);
    return { result: !hasCrafted ? true : undefined };
  }
}
