
<h4>{{'SECTIONS.VAULT_CLEANER.import-modal.SEARCH' | translate}}</h4>
<div *ngIf="!loadingUser">
  <app-user-autocomplete (userSelected)="selectUser($event)"></app-user-autocomplete>
</div>
<div class="result" *ngIf="loadingUser">
  <div class="loading" *ngIf="!errorLoadingUser">
    <fa-icon [icon]="['fas', 'circle-notch']" spin="true" size="lg"></fa-icon><br>
    {{'SECTIONS.VAULT_CLEANER.import-modal.LOADING_USER_INFO' | translate}}
  </div>
</div>
<div class="error" align="center" *ngIf="errorLoadingUser">
  <fa-icon [icon]="['fas', 'exclamation-triangle']" size="lg"></fa-icon><br>
  {{errorLoadingUser}}
  <br>
  <p [innerHTML]="'SECTIONS.VAULT_CLEANER.import-modal.BUNGIE_OPTION' | translate"></p>
</div>

<h4 class="title-accordion" (click)="openImportAccordion = !openImportAccordion"><fa-icon [icon]="['fas', openImportAccordion ? 'caret-down' : 'caret-right']"></fa-icon>{{'SECTIONS.VAULT_CLEANER.import-modal.CHOOSE_FILE' | translate}}</h4>
<div class="accordion-content" *ngIf="openImportAccordion">
  <input type="file" accept=".json" (change)="handleFile($event)">

  <div class="result" *ngIf="result">
    <dl>
      <dt>{{'SECTIONS.VAULT_CLEANER.import-modal.VAULTS_OWNER' | translate}}</dt> <dd>{{result.bungieName}}<span class="bungie-code">#{{result.bungieNameCode}}</span></dd>
      <dt>{{'SECTIONS.VAULT_CLEANER.import-modal.CHARACTERS' | translate}}</dt> <dd>{{result.characters}}</dd>
      <dt>{{'SECTIONS.VAULT_CLEANER.import-modal.CURRENT_USAGE' | translate}}</dt><dd>{{result.vaultSpace}}/500</dd>
      <dt>{{'SECTIONS.VAULT_CLEANER.import-modal.EXPORT_DATE' | translate}}</dt><dd>{{result.date | date: 'MMM dd yyyy HH:mm'}}</dd>
    </dl>
  </div>
  <div class="error" *ngIf="error">
    {{'SECTIONS.VAULT_CLEANER.import-modal.CANNOT_PARSE' | translate}}
  </div>

  <div class="info">
    <p>{{'SECTIONS.VAULT_CLEANER.import-modal.INFO1' | translate}}</p>
    <p>{{'SECTIONS.VAULT_CLEANER.import-modal.INFO2' | translate}}</p>
    <p>{{'SECTIONS.VAULT_CLEANER.import-modal.INFO3' | translate}}</p>
  </div>

</div>
