import { Component, OnInit } from '@angular/core';
import { ModalContentDialog } from '../../types';
import { ModaleComponent } from '@Common/modale/modale.component';
import { RecipeService } from '../../appraiser/recipe.service';

@Component({
  selector: 'app-confirm-navigation-leave',
  templateUrl: './confirm-navigation-leave.component.html',
  styleUrls: ['./confirm-navigation-leave.component.scss']
})
export class ConfirmNavigationLeaveComponent implements OnInit, ModalContentDialog<boolean> {

  data: any;
  readonly modalReference: ModaleComponent;
  disabled: boolean;
  saved: boolean;

  constructor(private recipeService: RecipeService) { }

  getData(): any {
    return this.saved;
  }

  ngOnInit(): void {
    setTimeout(() => this.modalReference.confirmedDisabled = false);
  }

  save() {
    if (this.disabled) { return; }
    this.disabled = true;
    this.modalReference.confirmedDisabled = true;
    this.recipeService.saveCurrentContent().subscribe({
      next: () => {
        this.saved = true;
        this.modalReference.done();
      },
      error: (err) => {
        console.error(err);
        this.disabled = false;
        this.modalReference.confirmedDisabled = false;
      }
    });
  }

  undo() {
    this.recipeService.revertChanges();
    this.saved = true;
    this.modalReference.done();
  }

}
