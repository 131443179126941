import { Component, OnInit } from '@angular/core';
import { ModalContentDialog } from '../../types';
import { ModaleComponent } from '../modale/modale.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss']
})
export class TextModalComponent implements OnInit, ModalContentDialog {

  data: any;
  readonly modalReference: ModaleComponent;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.data = this.domSanitizer.bypassSecurityTrustHtml(this.data);
    setTimeout(() => this.modalReference.confirmedDisabled = false, 0);
  }

  getData(): any {
  }

}
