import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[appDestroy]'
})
export class DestroyDirective implements OnDestroy {

  @Output() appDestroy = new EventEmitter();

  constructor() { }

  ngOnDestroy() {
    this.appDestroy.emit();
  }
}
