<div class="modal-container">
  <div class="backdrop" (click)="dismiss()"></div>
  <div class="modal-content" *ngIf="config">
    <div class="modal-header">{{config.title | translate}}</div>
    <div class="modal-body" [ngClass]="modalBodyClass">
      <ng-template #dynamic></ng-template>
    </div>
    <div class="modal-footer">
      <button class="app-btn" (click)="close()" [disabled]="confirmedDisabled">{{(config.confirmButtonText || 'SECTIONS.COMMON.modale.OK') | translate}}</button>
      <button class="app-btn" (click)="dismiss()" *ngIf="config.cancelButton">{{(config.cancelButtonText || 'SECTIONS.COMMON.modale.CANCEL') | translate}}</button>
    </div>
  </div>
</div>
