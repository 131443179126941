import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { searchByGlobalNamePrefix, UserSearchResponse, UserSearchResponseDetail } from 'bungie-api-ts/user';
import { CoreService } from '../../services/core.service';
import { ServerResponse } from 'bungie-api-ts/common';
import { logError } from '../../console';

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss']
})
export class UserAutocompleteComponent implements OnInit {

  autocompleteText: string;
  autoComplete$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  result$: Observable<UserSearchResponseDetail[]>;
  loading: boolean;
  searchDone: boolean;

  @Output() userSelected = new EventEmitter<UserSearchResponseDetail>();


  constructor(private coreService: CoreService) { }

  ngOnInit(): void {
    this.result$ = this.autoComplete$.pipe(
      tap(() => this.searchDone = false),
      switchMap((value) => of(value)),
      debounceTime(500),
      switchMap((value) => {
        this.loading = true;
        return value.length ? this.search(value) : of(undefined);
      }),
      map((response) => {
        this.loading = false;
        this.searchDone = true;
        return response?.Response.searchResults || [];
      })
    );
  }

  modelUpdated() {
    this.autoComplete$.next(this.autocompleteText);
  }

  search(valueSearch: string): Observable<ServerResponse<UserSearchResponse>> {
    return from(searchByGlobalNamePrefix(this.coreService.httpClient, {displayNamePrefix: valueSearch, page: 0})).pipe(
      catchError((err) => {
        logError(err);
        return of(undefined);
      })
    );
  }

}
