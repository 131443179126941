import { Component, OnInit } from '@angular/core';
import { WatchersService } from '../../services/watchers.service';
import { ModalService } from '../../services/modal.service';
import { WatcherManagerModalComponent } from './watcher-manager-modal.component';

@Component({
  selector: 'app-watcher-status',
  templateUrl: './watcher-status.component.html',
  styleUrls: ['./watcher-status.component.scss']
})
export class WatcherStatusComponent implements OnInit {

  get watcherRunning(): boolean {
    return Object.keys(this.watchersService.watchers).length > 0;
  }

  constructor(
    public watchersService: WatchersService,
    public modalService: ModalService
    ) { }

  ngOnInit(): void {}

  openWatchersModale() {
    this.modalService.create({
      component: WatcherManagerModalComponent,
      title: 'WATCHER.CONFIGURE'
    });
  }
}

