import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ToastrService } from 'ngx-toastr';
import { DestinyRecipesItem } from '../types/destiny-recipes-item';
import { ItemLocation, TierType } from 'bungie-api-ts/destiny2';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ItemMoverService {

  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  async transferItem(item: DestinyRecipesItem, toCharacterId: string) {
    console.log(`[Item Mover] Moving item ${item.definition.displayProperties.name} (${item.itemInstanceId}) to character ${toCharacterId} (${this.dataService.getCharacter(toCharacterId).classType})`);
    if (item.location === ItemLocation.Inventory && item.character === toCharacterId && !this.dataService.isItemEquipped(item)) {
      this.toastr.info(this.translate.instant('ITEM_MOVER.ALREADY_IN_INVENTORY'));
      return;
    }
    if (item.location === ItemLocation.Inventory && item.character === toCharacterId && this.dataService.isItemEquipped(item)) {
      return this.unequipItem(item);
    }
    if (item.location === ItemLocation.Postmaster) {
      await this.pullFromPostmaster(item);
    }
    if (item.location === ItemLocation.Inventory  && item.character !== toCharacterId) {
      await this.sendToVault(item);
    }
    return this.dataService.transferItem(item, {characterId: toCharacterId})
      .pipe(
        tap(() => {
          (item as any).location = ItemLocation.Inventory;
          item.character = toCharacterId;
          console.log(`[Item Mover] Transfer item done`);
        }),
      ).toPromise();
  }

  async unequipItem(item: DestinyRecipesItem) {
    console.log(`[Item Mover] Unequipping item ${item.definition.displayProperties.name} (${item.itemInstanceId}) from character ${item.character} (${this.dataService.getCharacter(item.character).classType})`);
    if (!this.dataService.isItemEquipped(item)) {
      return;
    }
    const slotInventory = this.dataService.getCharacterInventoryForSlot(item.character, item.bucketHash);
    if (slotInventory.length > 0) {
      await this.equipItem(slotInventory[0], item.character);
    } else {
      const validItemsToReplace = this.dataService.inventory.filter((i) => i.itemInstanceId !== item.itemInstanceId && i.bucketHash === item.bucketHash && i.definition.inventory.tierType !== TierType.Exotic && !this.dataService.isItemEquipped(i));
      let itemToReplaceWith = validItemsToReplace.find((i) => i.location === ItemLocation.Vault);
      if (!itemToReplaceWith) {
        itemToReplaceWith = validItemsToReplace[0];
      }
      await this.equipItem(itemToReplaceWith, item.character);
    }
    console.log(`[Item Mover] Unequip item done`);
  }

  async equipItem(item: DestinyRecipesItem, toCharacterId: string) {
    console.log(`[Item Mover] Equipping item ${item.definition.displayProperties.name} (${item.itemInstanceId}) on character ${toCharacterId} (${this.dataService.getCharacter(toCharacterId).classType})`);
    if (item.location === ItemLocation.Postmaster) {
      await this.pullFromPostmaster(item);
    }
    if (item.location === ItemLocation.Inventory && item.character !== toCharacterId) {
      await this.sendToVault(item);
    }
    if (item.location === ItemLocation.Vault) {
      await this.transferItem(item, toCharacterId);
    }
    return this.dataService.equipItem(item, toCharacterId)
      .pipe(
        tap(() => {
          (item as any).location = ItemLocation.Inventory;
          item.character = toCharacterId;
          this.dataService.setEquipment(item, item.character);
          console.log(`[Item Mover] Equip item done`);
        }),
      ).toPromise();
  }

  async sendToVault(item: DestinyRecipesItem) {
    console.log(`[Item Mover] Send to vault item ${item.definition.displayProperties.name} (${item.itemInstanceId}) from character ${item.character} (${this.dataService.getCharacter(item.character)?.classType})`);
    if (item.location === ItemLocation.Vault) {
      return;
    }
    if (this.dataService.isItemEquipped(item)) {
      await this.unequipItem(item);
    }
    return this.dataService.transferItem(item, {toVault: true})
      .pipe(
        tap(() => {
          (item as any).location = ItemLocation.Vault;
          item.character = undefined;
          console.log(`[Item Mover] Send to vault item done`);
        }),
      ).toPromise();
  }

  async pullFromPostmaster(item: DestinyRecipesItem) {
    console.log(`[Item Mover] Pulling item ${item.definition.displayProperties.name} (${item.itemInstanceId}) from character postmaster ${item.character} (${this.dataService.getCharacter(item.character)?.classType})`);
    if (item.location !== ItemLocation.Postmaster) {
      return;
    }
    return this.dataService.pullItemFromPostmaster(item)
      .pipe(
        tap(() => {
          console.log(`[Item Mover] Pulling item done`);
          (item as any).location = ItemLocation.Inventory;
        }),
      ).toPromise();
  }
}
