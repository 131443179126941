import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { DestinyInventoryItemDefinition } from 'bungie-api-ts/destiny2';
import { CoreService, TooltipInstance } from '../../services/core.service';

@Directive({
  selector: '[crTooltip]'
})
export class TooltipDirective implements OnDestroy {

  @Input() crTooltip: DestinyInventoryItemDefinition;
  @Input() crTooltipOptions: TooltipInstance;
  @Input() crTooltipType: 'target' | 'normal';

  constructor(private coreService: CoreService) {
  }

  ngOnDestroy(): void {
    this.coreService.closeTooltip();
  }

  @HostListener('mouseover', ['$event'])
  onMouseover() {
    if (window.innerWidth > 800 && !this.coreService.tooltipLocked) {
      this.coreService.openTooltip(this.crTooltip, this.crTooltipOptions, this.crTooltipType);
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.coreService.openTooltip(this.crTooltip, this.crTooltipOptions, this.crTooltipType);
    this.coreService.tooltipPosition = {x: event.clientX, y: event.clientY};
    this.coreService.lockTooltip();
    event.stopImmediatePropagation();
    if (this.coreService.devMode) {
      console.log('[Item clicked] ', this.crTooltipOptions);
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseout() {
    if (window.innerWidth > 800  && !this.coreService.tooltipLocked) {
      this.coreService.closeTooltip();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (window.innerWidth > 800  && !this.coreService.tooltipLocked) {
      this.coreService.tooltipPosition = {x: event.clientX, y: event.clientY};
    }
  }
}
