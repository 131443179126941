import { Component, OnInit } from '@angular/core';
import { ModalContentDialog } from '../../types';
import { ModaleComponent } from '../../common/modale/modale.component';
import { DestinyProfileResponse } from 'bungie-api-ts/destiny2';
import { UserSearchResponseDetail } from 'bungie-api-ts/user';
import { ImportedDataService } from '../../services/imported-data.service';
import { logError } from '../../console';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportModalComponent implements OnInit, ModalContentDialog<DestinyProfileResponse> {

  data: any;
  readonly modalReference: ModaleComponent;
  error: boolean;
  openImportAccordion = false;
  loading = false;

  result: {
    date: number;
    bungieName: string;
    bungieNameCode: number;
    characters: number;
    vaultSpace: number;
  };
  inventoryData: any;
  loadingUser: boolean;
  errorLoadingUser: string;

  constructor(private importedData: ImportedDataService) { }

  ngOnInit(): void {
  }

  getData(): DestinyProfileResponse {
    return this.inventoryData;
  }

  selectUser(event: UserSearchResponseDetail) {
    this.loadingUser = true;
    this.errorLoadingUser = null;
    const mainMembership = event.destinyMemberships.reduce((final, cur) => {
      if ((cur.crossSaveOverride === 0 && !final) || cur.crossSaveOverride === cur.membershipType) {
        return cur;
      } else {
        return final;
      }
    });
    this.importedData.loadGuardianInventory(mainMembership)
      .subscribe((result) => {
        this.inventoryData = result;
        this.modalReference.close();
      }, (err) => {
        logError(err);
        this.errorLoadingUser = err.message;
        this.loadingUser = false;
      });
  }

  handleFile(event) {
    if (this.loading) { return; }
    this.loading = true;
    this.error = false;
    this.modalReference.confirmedDisabled = true;
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.loading = false;
        try {
          const result = JSON.parse(fileReader.result.toString());
          if (!result.hasOwnProperty('date')) {
            throw new Error('Bad format. No date.');
          }
          if (!result.hasOwnProperty('inventory')) {
            throw new Error('Bad format. No inventory.');
          }
          this.inventoryData = result.inventory;
          this.result = {
            date: result.date,
            bungieName: result.inventory.profile.data.userInfo.bungieGlobalDisplayName,
            bungieNameCode: result.inventory.profile.data.userInfo.bungieGlobalDisplayNameCode,
            characters: result.inventory.profile.data.characterIds.length,
            vaultSpace: result.vaultSpace
          };
          this.modalReference.confirmedDisabled = false;
        } catch (err) {
          logError(err);
          this.error = true;
        }
      };
      fileReader.readAsText(file);
    }
  }

}
