import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<T = any> implements OnInit {

  @ViewChild('downdownBox') downdownBox: any;

  options: Array<{
    value: T;
    text: string;
  }>;
  selectedItem: {value: T, text: string};
  dropdownOpened: boolean;
  position: {x: number, y: number, width: string} = {x: 0, y: 0, width: 'auto'};

  @Input() optionList: Array<{
    value: T;
    text: string;
  }>;

  @Input() icon: IconProp;
  @Input() placeholder: string;
  @Input() source: T[];
  @Input() valueKey: string;
  @Input() textKey: string;
  @Input() displayFunction: (item: T) => string;
  @Input() default: T;
  @Input() currentValue: string;

  @Output() selected = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer, private eRef: ElementRef) { }

  ngOnInit(): void {
    if (this.source && this.valueKey && (this.textKey || this.displayFunction)) {
      this.options = this.source.map((item) => ({
        value: _.get(item, this.valueKey),
        text:  this.textKey ? _.get(item, this.textKey) : this.sanitizer.bypassSecurityTrustHtml(this.displayFunction(item))
      }));
      if (this.default) {
        this.selectOption(this.options.find((o) => o.value === this.default[this.valueKey]));
      }
    } else if (this.optionList) {
      this.options = this.optionList;
      if (this.displayFunction) {
        this.options.forEach((o) => o.text = this.displayFunction(o.value));
      }
      if (this.default) {
        this.selectOption(this.options.find((o) => o.value === this.default));
      }
    } else {
      console.warn('[app-dropdown] you should pass either [source] option with [valueKey] and [textKey]; or [config]');
    }
  }

  setOption(val: T) {
    if (val) {
      this.selectedItem = this.options.find((e) => e.value === val);
    } else {
      this.selectedItem = undefined;
    }
  }

  selectOption(value: {value: T, text: string}) {
    if (value) {
      this.selectedItem = value;
      this.selected.emit(value.value);
      this.dropdownOpened = false;
    }
  }

  public openDropdown() {
    this.position = {
      x: this.downdownBox.nativeElement.offsetLeft,
      y: this.downdownBox.nativeElement.offsetTop + this.downdownBox.nativeElement.offsetHeight,
      width: this.downdownBox.nativeElement.clientWidth + 'px'
    };
    this.dropdownOpened = !this.dropdownOpened;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target) === false) {
      this.dropdownOpened = false;
    }
  }
}
