import { Injectable } from '@angular/core';
import {
  DestinyRecipesWatcher,
  DestinyRecipesWatcherConfig,
  DestinyRecipesWatcherType
} from '../watchers/destiny-recipes-watcher';
import { FarmingModeWatcher } from '../watchers/farming-mode.watcher';
import { DataService } from './data.service';
import { ItemMoverService } from './item-mover.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BestPowerWatcher } from '../watchers/best-power.watcher';
import { TranslateService } from '@ngx-translate/core';
import { ArmorQualityWatcher } from '../watchers/armor-quality.watcher';
import { ArmorQualityTagger } from '../watchers/armor-quality-tagger.watcher';
import { DimSyncService } from './dim-sync.service';

export interface DestinyRecipesWatcherInfo {
  type: DestinyRecipesWatcherType;
  config?: {
    control: UntypedFormControl;
    type: string;
    options?: string[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class WatchersService {

  public availabledWatchers: DestinyRecipesWatcherInfo[] = [
    {
      type: DestinyRecipesWatcherType.FARMING_MODE,
      config: {
        control: new UntypedFormControl(1, [Validators.min(1), Validators.max(9)]),
        type: 'number'
      }
    },
    {
      type: DestinyRecipesWatcherType.BEST_POWER
    },
    {
      type: DestinyRecipesWatcherType.ARMOR_QUALITY,
      config: {
        control: new UntypedFormControl('A', [Validators.pattern(/^[A-F]|S$/)]),
        type: 'select',
        options: ['S', 'A', 'B', 'C', 'D', 'E', 'F']
      }
    },
    {
      type: DestinyRecipesWatcherType.ARMOR_QUALITY_TAGGER,
      config: {
        control: new UntypedFormControl('A', [Validators.pattern(/^[A-F]|S$/)]),
        type: 'select',
        options: ['S', 'A', 'B', 'C', 'D', 'E', 'F']
      }
    }
    /* {
       type: DestinyRecipesWatcherType.ROLL_QUALITY,
       config: {
         control: new FormControl(75, [Validators.min(1), Validators.max(100)]),
         type: 'number'
       }
     } */
  ];

  watchers: {[type: string]: DestinyRecipesWatcher} = {};

  get running() {
    return Object.keys(this.watchers).some((w) => this.watchers[w].started);
  }

  constructor(
    private dataService: DataService,
    private itemMoverService: ItemMoverService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private dimSyncService: DimSyncService,
  ) {}

  getStatus(watcherType: DestinyRecipesWatcherType): 'running' | 'paused' | 'stopped' {
    if (!this.watchers[watcherType]) {
      return 'stopped';
    }
    if (this.watchers[watcherType].started) {
      return 'running';
    }
    if (this.watchers[watcherType].started === false) {
      return 'paused';
    }
  }

  notify() {
    Object.keys(this.watchers)
      .filter((watcherName) => this.watchers[watcherName].started)
      .forEach((watcherName) => this.watchers[watcherName].notify());
  }

  startWatcher(watcherType: DestinyRecipesWatcherType, config: DestinyRecipesWatcherConfig) {
    if (this.watchers[watcherType]) {
      if (this.watchers[watcherType].started) {
        return;
      } else {
        this.watchers[watcherType].restart();
      }
    } else {
      this.watchers[watcherType] = this.createWatcher(watcherType);
      this.watchers[watcherType].setConfig(config);
      this.watchers[watcherType].start();
    }
  }

  stopWatcher(watcherType: DestinyRecipesWatcherType) {
    if (this.watchers[watcherType] && this.watchers[watcherType].started) {
      this.watchers[watcherType].stop();
    }
  }

  private createWatcher(type: DestinyRecipesWatcherType): DestinyRecipesWatcher {
    switch (type) {
      case DestinyRecipesWatcherType.FARMING_MODE:
        return new FarmingModeWatcher(this.dataService, this.itemMoverService, this.toastr, this.translateService);
      case DestinyRecipesWatcherType.BEST_POWER:
        return new BestPowerWatcher(this.dataService, this.toastr, this.translateService);
      case DestinyRecipesWatcherType.ROLL_QUALITY:
      case DestinyRecipesWatcherType.ARMOR_QUALITY:
        return new ArmorQualityWatcher(this.dataService, this.toastr, this.translateService);
      case DestinyRecipesWatcherType.ARMOR_QUALITY_TAGGER:
        return new ArmorQualityTagger(this.dataService, this.toastr, this.translateService, this.dimSyncService);
      default:
        return null;
    }
  }
}
