import { Component, Input, OnInit } from '@angular/core';
import { DestinyInventoryItemDefinition, ItemState } from 'bungie-api-ts/destiny2';
import { DataService } from '../../services/data.service';
import { Pursuit } from '../../checklist/checklist.types';
import { CoreService, TooltipInstanceType } from '../../services/core.service';
import { DestinyRecipesItem } from '../../types/destiny-recipes-item';
import { ItemHelper } from '../item.helper';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DimSyncService } from '../../services/dim-sync.service';

@Component({
  selector: 'app-destiny-item',
  templateUrl: './destiny-item.component.html',
  styleUrls: ['./destiny-item.component.scss']
})
export class DestinyItemComponent implements OnInit {

  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() item: DestinyInventoryItemDefinition;
  @Input() inventoryItem: DestinyRecipesItem;
  @Input() crTooltipType: TooltipInstanceType;
  @Input() polyvalentScoreOnly: boolean;
  @Input() iconOnly: boolean;
  @Input() isPerk: boolean;
  @Input() hash: number;
  @Input() displayScore: boolean;
  @Input() scoreDisplayType: 'rating' | 'percentage' = 'percentage';
  @Input() bounty: Pursuit;
  @Input() minimumScoreValue: number;
  @Input() showPoly: boolean;

  iconUrl: string;
  isMasterWorked: boolean;
  watermarkIconUrl: string;

  get isIntrinsic() {
    return this.isPerk && this.item && this.item.itemCategoryHashes.includes(2237038328);
  }

  get isLocked() {
    return ItemHelper.isLocked(this.inventoryItem);
  }

  get hasNotes(): boolean {
    return !!this.dimSyncService.getAnnotationsForItem(this.inventoryItem.itemInstanceId)?.notes;
  }

  get tag(): IconProp {
    return this.dimSyncService.getTagIconForItem(this.inventoryItem.itemInstanceId);
  }

  constructor(private dataService: DataService, private coreService: CoreService, public dimSyncService: DimSyncService) { }

  ngOnInit(): void {
    this.init();
  }

  refresh() {
    this.init();
  }

  init() {
    this.iconUrl = undefined;
    if (this.hash) {
      this.item = this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', this.hash);
    }
    if (this.item) {
      this.iconUrl = 'https://bungie.net' + this.item.displayProperties.icon;
      if (this.item.quality?.displayVersionWatermarkIcons?.length) {
        this.watermarkIconUrl = 'https://bungie.net' + this.item.quality.displayVersionWatermarkIcons[this.item.quality.currentVersion];
      }
    }
    if (this.inventoryItem && this.inventoryItem.overrideStyleItemHash) {
      const overrideItem = this.dataService.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', this.inventoryItem.overrideStyleItemHash);
      if (overrideItem) {
        this.iconUrl = 'https://bungie.net' + overrideItem.displayProperties.icon;
      }
    } else if (this.inventoryItem) {
      this.iconUrl = 'https://bungie.net' + this.inventoryItem.definition.displayProperties.icon;
    }

    if (this.inventoryItem) {
      this.isMasterWorked = (this.inventoryItem.state & ItemState.Masterwork) !== 0;
      if (this.inventoryItem.definition.quality?.displayVersionWatermarkIcons?.length) {
        this.watermarkIconUrl = 'https://bungie.net' + this.inventoryItem.definition.quality.displayVersionWatermarkIcons[this.inventoryItem.versionNumber || 0];
      }
    }
    if (!this.iconUrl || this.iconUrl.indexOf('undefined') > -1) {
      this.iconUrl = '~/assets/missing_icon.png';
    }
  }

  devVerbose() {
    if (this.coreService.devMode) {
      console.log(this);
    }
  }

}
