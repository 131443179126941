import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DestinyManifestComponentName } from 'bungie-api-ts/destiny2';

@Pipe({
  name: 'manifestEntry'
})
export class ManifestEntryPipe implements PipeTransform {

  constructor(private dataService: DataService) {}

  transform(itemId: string | number, category: DestinyManifestComponentName): typeof category {
    return this.dataService.getDefinitionById<typeof category>(category || 'DestinyItemCategoryDefinition' as any, itemId as any);
  }
}
