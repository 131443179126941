import {
  DestinyRecipesWatcher,
  DestinyRecipesWatcherConfig,
  DestinyRecipesWatcherType
} from './destiny-recipes-watcher';
import { DataService } from '../services/data.service';
import { ItemMoverService } from '../services/item-mover.service';
import * as _ from 'lodash';
import { DestinyCharacterComponent } from 'bungie-api-ts/destiny2';
import { DestinyRecipesItem, DestinyRecipesItemHelper } from '../types/destiny-recipes-item';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

export interface FarmingModeWatcherConfig extends DestinyRecipesWatcherConfig {
  type: DestinyRecipesWatcherType.FARMING_MODE;
  data: number;
}

export class FarmingModeWatcher extends DestinyRecipesWatcher {

  config: FarmingModeWatcherConfig;
  farmingBuckets = [
    '3551918588', // Gauntlets
    '3448274439', // helmet
    '1585787867', // class armor
    '1498876634', // knetic w
    '2465295065', // energy w
    '953998645', // power w
    '20886954', // Legs
    '14239492' // Chests
  ];

  constructor(
    protected dataService: DataService,
    private itemMoverService: ItemMoverService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super(DestinyRecipesWatcherType.FARMING_MODE, dataService);
  }

  inventoryUpdated() {
    const latestPlayedCharacter = _.maxBy(Object.keys(this.dataService.inventoryData.characters.data).map((c) => this.dataService.inventoryData.characters.data[c]), 'dateLastPlayed');
    this.checkSpace(latestPlayedCharacter)
      .then(() => this.afterUpdate())
      .catch((err) => {
        this.toastr.error(this.translateService.instant('WATCHER.FARMING_MODE.VAULT_FULL'));
        this.stop();
      });
  }

  private async checkSpace(character: DestinyCharacterComponent) {
    const inventory = this.dataService.inventoryData.characterInventories.data[character.characterId].items;
    const slots = _.groupBy(inventory, 'bucketHash');
    for (const slotHash in slots) {
      if (slots.hasOwnProperty(slotHash) && this.farmingBuckets.indexOf(slotHash) > -1 && slots[slotHash].length + this.config.data > 9) {
        await this.freeSpaceInSlot(slots[slotHash].map((item) => this.dataService.inventory.find((i) => i.itemInstanceId === item.itemInstanceId)));
      }
    }
  }

  async freeSpaceInSlot(slot: DestinyRecipesItem[]) {
    slot.sort(DestinyRecipesItemHelper.orderByRecency);
    const listToSendToVault = slot.splice(0, this.config.data);
    for (const item of listToSendToVault) {
      try {
        await this.itemMoverService.sendToVault(item);
      } catch (err) {
        if (!(err instanceof HttpErrorResponse) || err.error.ErrorStatus !== 'DestinyItemNotFound') {
          throw err;
        }
      }
    }
  }

  protected checkConfig() {
    if (!this.config.data) {
      throw new Error('minimumSpaceInSlots must be >0');
    }
    if (typeof this.config.data === 'string') {
      this.config.data = Number.parseInt(this.config.data, 10);
    }
    if (this.config.data > 9) {
      throw new Error('minimumSpaceInSlots must be <9');
    }
  }

  protected beforeStart() {
  }

  protected beforeStop() {
  }

}
