import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DestinyItemCategoryDefinition, DestinyManifestComponentName } from 'bungie-api-ts/destiny2';

@Pipe({
  name: 'itemName'
})
export class ItemNamePipe implements PipeTransform {

  constructor(private dataService: DataService) {}

  transform(itemId: string | number, category: DestinyManifestComponentName): string {
    return this.dataService.getDefinitionById<DestinyItemCategoryDefinition>(category || 'DestinyItemCategoryDefinition' as any, itemId as any)?.displayProperties?.name || 'Unknown item';
  }
}
