<div class="perk-circle" [ngClass]="{
    'active': perk.active,
    'pve': perk.proficiency && perk.proficiency.pve >= Rank.A,
    'pvp': perk.proficiency && perk.proficiency.pvp >= Rank.A,
    'highlighted': highlighted,
    'lg': size === 'lg',
    'sm': size === 'sm',
    'enhanced': definition.inventory.tierType === 3,
    'not-available': lowlightUnavailable && !perk.available
  }" *ngIf="definition"
     [perkPopupTrigger]="{perk: perk, highlighted: highlighted}"
     [popupDisabled]="disablePopup" [disabledHoverBehavior]="noPopupOnHover"
     (click)="perkClicked()"
>
  <div class="rank-icon" *ngIf="perk.proficiency && perk.proficiency.pve === Rank.A"><img src="assets/rolls/A-rank-pve.svg" alt="A pve"></div>
  <div class="rank-icon" *ngIf="perk.proficiency && perk.proficiency.pve === Rank.S"><img src="assets/rolls/S-rank-pve.svg" alt="S pve"></div>
  <div class="rank-icon" *ngIf="perk.proficiency && perk.proficiency.pve === Rank.C"><img class="trash-pve" src="assets/rolls/trash-pve.svg" alt="C pve"></div>

  <div class="rank-icon" *ngIf="perk.proficiency && perk.proficiency.pvp === Rank.A"><img src="assets/rolls/A-rank-pvp.svg" alt="A pvp"></div>
  <div class="rank-icon" *ngIf="perk.proficiency && perk.proficiency.pvp === Rank.S"><img src="assets/rolls/S-rank-pvp.svg" alt="S pvp"></div>
  <div class="rank-icon" *ngIf="perk.proficiency && perk.proficiency.pvp === Rank.C"><img class="trash-pvp" src="assets/rolls/trash-pvp.svg" alt="C pvp"></div>

  <img [ngSrc]="'https://bungie.net' + definition.displayProperties.icon" height="30" width="30" [alt]="definition.displayProperties.name">
</div>
