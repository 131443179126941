import {
  armorShapeMap,
  DestinyArmorAppraisal,
  DestinyArmorAppraisalRating,
  DisciplineHash,
  IntellectHash,
  MobilityHash,
  RecoveryHash,
  ResilienceHash,
  StrengthHash
} from './destiny-recipes-item';

export class DestinyArmorAppraiser {
  static ClassItemAppraisal: DestinyArmorAppraisal = {
    base: 0,
    rating: DestinyArmorAppraisalRating.S,
    qualityDecay: 0,
    top: { points: 34, mobres: { buildSegmentGap: 0, segmentGap: 0 }, recres: { buildSegmentGap: 0, segmentGap: 0 }, mobrec: { buildSegmentGap: 0, segmentGap: 0 } },
    bottom: { points: 34, disShape: DestinyArmorAppraisalRating.S },
    isClassItem: true,
    stats: {
      [MobilityHash]: 0,
      [ResilienceHash]: 0,
      [RecoveryHash]: 0,
      [DisciplineHash]: 0,
      [IntellectHash]: 0,
      [StrengthHash]: 0,
    }
  };
  static NotApplicableRating: DestinyArmorAppraisal = {
    base: 0,
    rating: DestinyArmorAppraisalRating.S,
    qualityDecay: 0,
    top: { points: 34, mobres: { buildSegmentGap: 0, segmentGap: 0 }, recres: { buildSegmentGap: 0, segmentGap: 0 }, mobrec: { buildSegmentGap: 0, segmentGap: 0 } },
    bottom: { points: 34, disShape: DestinyArmorAppraisalRating.S },
    isClassItem: true,
    stats: {
      [MobilityHash]: 0,
      [ResilienceHash]: 0,
      [RecoveryHash]: 0,
      [DisciplineHash]: 0,
      [IntellectHash]: 0,
      [StrengthHash]: 0,
    }
  };

  constructor(public stats: {[statHash: number]: number; intrinsic: {[statHash: number]: number}}) {}

  computeTopSegment() {
    const top: any = {};
    top.points = this.stats[MobilityHash] + this.stats[ResilienceHash] + this.stats[RecoveryHash];
    if (top.points === 0) {
      /** Class item */
      return { points: 34, mobres: { buildSegmentGap: 0, segmentGap: 0 }, recres: { buildSegmentGap: 0, segmentGap: 0 }};
    }
    top.mobres = {
      buildSegmentGap: 32 - (this.stats[MobilityHash] + this.stats[ResilienceHash]),
      segmentGap: 34 - top.points,
    };
    top.mobrec = {
      buildSegmentGap: 32 - (this.stats[MobilityHash] + this.stats[RecoveryHash]),
      segmentGap: 34 - top.points,
    };
    top.recres = {
      buildSegmentGap: 32 - (this.stats[RecoveryHash] + this.stats[ResilienceHash]),
      segmentGap: 34 - top.points,
    };
    return top;
  }

  computeBottomSegment() {
    const bottom: any = {};
    bottom.points = this.stats[DisciplineHash] + this.stats[IntellectHash] + this.stats[StrengthHash];
    if (bottom.points === 0) {
      /** Class item */
      return { points: 34, disShape: DestinyArmorAppraisalRating.S };
    }
    bottom.disShape = this.getSegmentShape(Math.max(this.stats[IntellectHash], this.stats[StrengthHash]), this.stats[DisciplineHash]);
    return bottom;
  }

  getSegmentShape(min: number, max: number): DestinyArmorAppraisalRating {
    if (min === 2 && max === 30) { return DestinyArmorAppraisalRating.S; }
    if (min === 2 && max >= 20) { return DestinyArmorAppraisalRating.A; }
    if (min >= 6 && min <= 12 && max >= 20) { return DestinyArmorAppraisalRating.B; }
    if (min >= 6 && min <= 12 && max >= 13 && max <= 19) { return DestinyArmorAppraisalRating.C; }
    return DestinyArmorAppraisalRating.D;
  }

  /**
   * computeGlobalRating
   *
   * Top segment decay calculation:
   *
   * For each build type, the top segment is checked.
   *
   * - MOB_RES and REC_RES:
   *    Build segments range from 4 to 32. Gap is computed as the difference between 32 and the actual build segment (0 to 28).
   *    -> buildSegmentGap = 0 -> +0
   *    -> buildSegmentGap = 1-7 -> +1
   *    -> buildSegmentGap = 8-14 -> +2
   *    -> buildSegmentGap = 15-21 -> +3
   *    -> buildSegmentGap = 21-28 -> +4
   *
   *    Segment ranges from 22 to 34. Gap is computed as the difference between 34 and the actual segment (0 to 12).
   *    -> segmentGap = 0 -> +0
   *    -> segmentGap = 1-3 -> +1
   *    -> segmentGap = 4-6 -> +2
   *    -> segmentGap = 7-9 -> +3
   *    -> segmentGap = 9-12 -> +4
   *
   * Bottom segment decay calculation:
   *    Segment gap: total points in the bottom segment (0 to 12)
   *    -> points = 0 -> +0
   *    -> points = 1-3 -> +1
   *    -> points = 4-6 -> +2
   *    -> points = 7-9 -> +3
   *    -> points = 9-12 -> +4
   *
   *    Shape: shape of the bottom segment (A to D)
   *    -> shape = S -> +0
   *    -> shape = A -> +1
   *    -> shape = B -> +2
   *    -> shape = C -> +3
   *    -> shape = D -> +4
   *
   *    QualityDecay is a simple addition of those term, though some are weighted down (ratio gap).
   */
  computeGlobalRating(appraisal: DestinyArmorAppraisal) {
    appraisal.decayDetails = {
      bestBuild: null,
      mobres: {
        buildSegmentGap: Math.max(0, Math.ceil(appraisal.top.mobres.buildSegmentGap / 7)),
        segmentGap: Math.max(0, Math.ceil(appraisal.top.mobres.segmentGap / 3)),
      },
      recres: {
        buildSegmentGap: Math.max(0, Math.ceil(appraisal.top.recres.buildSegmentGap / 7)),
        segmentGap: Math.max(0, Math.ceil(appraisal.top.recres.segmentGap / 3)),
      },
      mobrec: {
        buildSegmentGap: Math.max(0, Math.ceil(appraisal.top.recres.buildSegmentGap / 7)),
        segmentGap: Math.max(0, Math.ceil(appraisal.top.recres.segmentGap / 3)),
      },
      bottom: {
        segmentGap: Math.max(Math.ceil((34 - appraisal.bottom.points) / 3), 0),
        disShape: armorShapeMap[appraisal.bottom.disShape],
      },
    };
    const mobresDecay = appraisal.decayDetails.mobres.buildSegmentGap + appraisal.decayDetails.mobres.segmentGap;
    const recresDecay = appraisal.decayDetails.recres.buildSegmentGap + appraisal.decayDetails.recres.segmentGap;
    const bottomDecay = appraisal.decayDetails.bottom.segmentGap + appraisal.decayDetails.bottom.disShape;
    appraisal.decayDetails.bestBuild = mobresDecay <= recresDecay ? 'mobres' : 'recres';
    /** Bottom Decay is weighed down twice (disShape is 0.5 and global bottom decay is 0.25) */
    appraisal.qualityDecay = Math.min(mobresDecay, recresDecay) + bottomDecay * .25;
    /** Quality Decay ranges from 0 to 10 (TopDecay 0-8 ; BottomDecay 0-2) */
    appraisal.rating = this.qualityDecayToQuality(appraisal.qualityDecay);
  }

  qualityDecayToQuality(qualityDecay: number): DestinyArmorAppraisalRating {
    if (qualityDecay <= 0) {
      return DestinyArmorAppraisalRating.S;
    }
    if (qualityDecay <= 3) {
      return DestinyArmorAppraisalRating.A;
    }
    if (qualityDecay <= 5) {
      return DestinyArmorAppraisalRating.B;
    }
    if (qualityDecay <= 7) {
      return DestinyArmorAppraisalRating.C;
    }
    if (qualityDecay <= 9) {
      return DestinyArmorAppraisalRating.D;
    }
    if (qualityDecay < 10) {
      return DestinyArmorAppraisalRating.E;
    }
    return DestinyArmorAppraisalRating.F;
  }

  exec(): DestinyArmorAppraisal {
    const appraisal: DestinyArmorAppraisal = {
      top: this.computeTopSegment(),
      bottom: this.computeBottomSegment(),
      stats: this.stats,
      base: this.stats[MobilityHash] + this.stats[ResilienceHash] + this.stats[RecoveryHash] + this.stats[DisciplineHash] + this.stats[IntellectHash] + this.stats[StrengthHash],
      rating: DestinyArmorAppraisalRating.UNKNOWN,
      qualityDecay: 0,
    };
    this.computeGlobalRating(appraisal);
    return appraisal;
  }
}
