import { DestinyItemComponentCharacterized, RecipesCraftableState } from '../types';
import {
  DestinyClass,
  DestinyCollectibleComponent,
  DestinyInventoryItemDefinition,
  DestinyItemInstanceComponent,
  DestinyItemObjectivesComponent,
  DestinyItemPlugObjectivesComponent,
  DestinyItemReusablePlugsComponent,
  DestinyItemSocketsComponent, DestinyItemSocketState,
  DestinyItemStatsComponent,
  DestinyItemSubType, DestinyItemType,
} from 'bungie-api-ts/destiny2';
import { DestinyItemInstanceEnergy } from 'bungie-api-ts/destiny2/interfaces';
import { DestinyItemCategory } from '@Services/destinyItemCategory';
import { VaultCleanerFactoryId } from '../vault-cleaner/vault-cleaner.types';
import { WeaponRating } from '../appraiser/appraiser.types';
import { PlugCategoryHashes } from '@Utils/generated-enums';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

export interface DestinyRecipesItem extends DestinyItemComponentCharacterized {
  definition: DestinyInventoryItemDefinition;
  isWeapon?: boolean;
  isArmor?: boolean;
  armorPieceType?: DestinyItemSubType;
  score?: WeaponRating;
  scoreAbsentReason?: 'norecipe' | 'norating';
  objectives?: DestinyItemObjectivesComponent;
  plugObjectives?: DestinyItemPlugObjectivesComponent;
  collectible?: DestinyCollectibleComponent;
  instance?: DestinyItemInstanceComponent;
  stats?: DestinyItemStatsComponent;
  currentPlugs?: DestinyItemSocketsComponent;
  reusablePlugs?: DestinyItemReusablePlugsComponent;
  perks?: DestinyRecipesPerk[][];
  energy?: DestinyItemInstanceEnergy;
  hasError?: boolean;
  armorTotal?: number;
  contextData?: any;
  powerLevel?: number;
  crafted?: boolean;
  recipeState?: RecipesCraftableState;
  crafting?: {
    date: number;
    level: number;
    progress: number;
  };
  deepsight?: boolean;
  masterworked?: boolean;
  baseStats?: DestinyItemStatsComponent;
  baseArmorTotal?: number;
  classType?: DestinyClass;
  curated?: boolean;
  armorAppraisal?: DestinyArmorAppraisal;
  /**
   * Vault Cleaner rule results, such as if the item has good rolls
   */
  checkedAttributes?: {
    isHighestPower?: boolean;
    bestTotal?: boolean;
  };
  vaultCleanerResult?: Array<{rule: VaultCleanerFactoryId, result: { result: boolean; value?: any; }}>;
}

export const perkOrderingComparator = (a: DestinyRecipesPerk, b: DestinyRecipesPerk) => {
  if (a.enhanced && !b.enhanced) { return 1; }
  if (!a.enhanced && b.enhanced) { return -1; }
  return a < b ? -1 : 1;
};

export enum DestinyRecipesPerkRank {
  S = 2,
  A = 1,
  B = 0,
  C = -1
}

export interface DestinyRecipesPerk {
  plugHash: number;
  active: boolean;
  enhanced: boolean;
  available?: boolean;
  proficiency?: {
    pve: DestinyRecipesPerkRank,
    pvp: DestinyRecipesPerkRank
  };
  realSocketEntriesIndex?: number;
  plugCategoryHash?: PlugCategoryHashes;
}

export interface SortedDestinyRecipesPerks {
  [DestinyRecipesPerkRank.S]: DestinyRecipesPerk[][];
  [DestinyRecipesPerkRank.A]: DestinyRecipesPerk[][];
  [DestinyRecipesPerkRank.B]: DestinyRecipesPerk[][];
  [DestinyRecipesPerkRank.C]: DestinyRecipesPerk[][];
}

export interface ActivitySortedDestinyRecipesPerks {
  pve: SortedDestinyRecipesPerks;
  pvp: SortedDestinyRecipesPerks;
}

export interface RatedDestinyRecipesPerks {
  perks: DestinyRecipesPerk[];
  weights: number[];
  source?: 'weapon' | 'archetype' | 'type' | 'empty';
}


export class DestinyRecipesItemSimulator {
  item: DestinyRecipesItem;
  active: boolean;
  columnsSimulated: {[key: number]: DestinyItemSocketState};
  update$ = new Subject<DestinyRecipesItemSimulator>();

  static from(item: DestinyRecipesItem): DestinyRecipesItemSimulator {
    const sim = new DestinyRecipesItemSimulator(item.definition);
    sim.item = _.cloneDeep(item);
    return sim;
  }

  constructor(definition: DestinyInventoryItemDefinition) {
    this.item = {
      bindStatus: undefined,
      bucketHash: 0,
      isWrapper: false,
      itemHash: definition.hash,
      itemValueVisibility: [],
      location: undefined,
      lockable: false,
      metricObjective: undefined,
      quantity: 0,
      state: undefined,
      tooltipNotificationIndexes: [],
      transferStatus: undefined,
      definition,
      perks: [],
      isWeapon: definition.itemType === DestinyItemType.Weapon,
      isArmor: definition.itemType === DestinyItemType.Armor,
    };
    this.active = false;
    this.columnsSimulated = {};
  }

  refreshComponents() {
    this.update$.next(this);
  }

  setDefinition(itemDefinition: DestinyInventoryItemDefinition) {
    this.item.definition = itemDefinition;
    this.update$.next(this);
  }

  previewPerk(perk: DestinyRecipesPerk) {
    if (!this.item.currentPlugs) {
      this.item.currentPlugs = {sockets: []};
    }
    if (this.columnsSimulated.hasOwnProperty(perk.realSocketEntriesIndex) === false) {
      this.columnsSimulated[perk.realSocketEntriesIndex] = this.item.currentPlugs.sockets[perk.realSocketEntriesIndex];
    }
    this.item.currentPlugs.sockets[perk.realSocketEntriesIndex] = {
      plugHash: perk.plugHash,
      enableFailIndexes: [],
      isEnabled: true,
      isVisible: perk.available
    };
  }

  setPerk(perk: DestinyRecipesPerk) {
    if (!this.item.currentPlugs) {
      this.item.currentPlugs = {sockets: []};
    }
    this.item.currentPlugs.sockets[perk.realSocketEntriesIndex] = {
      plugHash: perk.plugHash,
      enableFailIndexes: [],
      isEnabled: true,
      isVisible: perk.available
    };
    this.item.perks[perk.realSocketEntriesIndex] = [perk];
    if (this.columnsSimulated.hasOwnProperty(perk.realSocketEntriesIndex) === true) {
      this.columnsSimulated[perk.realSocketEntriesIndex] = this.item.currentPlugs.sockets[perk.realSocketEntriesIndex];
    }
    this.update$.next(this);
  }

  removePerkPreview(perk: DestinyRecipesPerk) {
    if (this.item.currentPlugs?.sockets[perk.realSocketEntriesIndex]?.plugHash === perk.plugHash && this.columnsSimulated.hasOwnProperty(perk.realSocketEntriesIndex)) {
      if (this.columnsSimulated[perk.realSocketEntriesIndex]) {
        this.item.currentPlugs.sockets[perk.realSocketEntriesIndex] = this.columnsSimulated[perk.realSocketEntriesIndex];
      } else {
        delete this.item.currentPlugs.sockets[perk.realSocketEntriesIndex];
      }
      delete this.columnsSimulated[perk.realSocketEntriesIndex];
    }
  }

  removePerk(perk: DestinyRecipesPerk) {
    if (this.item.currentPlugs?.sockets[perk.realSocketEntriesIndex]?.plugHash === perk.plugHash) {
      this.item.perks[perk.realSocketEntriesIndex] = [];
      delete this.item.currentPlugs.sockets[perk.realSocketEntriesIndex];
    }
    this.update$.next(this);
  }
}


export interface DestinyArmorAppraisal {
  isClassItem?: boolean;
  stats: {[statHash: number]: number};
  base: number;
  top: {
    points: number;
    mobres: {
      buildSegmentGap: number;
      segmentGap: number;
    },
    recres: {
      buildSegmentGap: number;
      segmentGap: number;
    },
    mobrec: {
      buildSegmentGap: number;
      segmentGap: number;
    }
  };
  bottom: {
    points: number;
    disShape: DestinyArmorAppraisalRating;
  };
  rating: DestinyArmorAppraisalRating;
  qualityDecay: number;
  decayDetails?: {
    bestBuild: 'mobres' | 'recres';
    mobres: {
      buildSegmentGap: number;
      segmentGap: number;
    };
    recres: {
      buildSegmentGap: number;
      segmentGap: number;
    };
    mobrec: {
      buildSegmentGap: number;
      segmentGap: number;
    }
    bottom: {
      segmentGap: number;
      disShape: number;
    }
  };
}

export enum DestinyArmorAppraisalRating {
  S = 'S',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  UNKNOWN = 'UNKNOWN',
  NA = 'NA',
}

export const armorShapeMapOrdered = {
  [DestinyArmorAppraisalRating.F]: 0,
  [DestinyArmorAppraisalRating.E]: 1,
  [DestinyArmorAppraisalRating.D]: 2,
  [DestinyArmorAppraisalRating.C]: 3,
  [DestinyArmorAppraisalRating.B]: 4,
  [DestinyArmorAppraisalRating.A]: 5,
  [DestinyArmorAppraisalRating.S]: 6,
  [DestinyArmorAppraisalRating.UNKNOWN]: -1,
  [DestinyArmorAppraisalRating.NA]: -2,
};

export const armorShapeMap = {
  [DestinyArmorAppraisalRating.S]: 0,
  [DestinyArmorAppraisalRating.A]: 1,
  [DestinyArmorAppraisalRating.B]: 2,
  [DestinyArmorAppraisalRating.C]: 3,
  [DestinyArmorAppraisalRating.D]: 4,
  [DestinyArmorAppraisalRating.E]: 5,
  [DestinyArmorAppraisalRating.F]: 6,
};

export const MobilityHash = 2996146975;
export const ResilienceHash = 392767087;
export const RecoveryHash = 1943323491;
export const DisciplineHash = 1735777505;
export const IntellectHash = 144602215;
export const StrengthHash = 4244567218;

export class DestinyRecipesItemHelper {
  static getArmorPieceType(item: DestinyRecipesItem) {
    return item.definition.itemSubType;
  }

  static classTypeToItemCategory(classType: DestinyClass) {
    switch (classType) {
      case DestinyClass.Hunter:
        return DestinyItemCategory.HUNTER;
      case DestinyClass.Titan:
        return DestinyItemCategory.TITAN;
      case DestinyClass.Warlock:
        return DestinyItemCategory.WARLOCK;
      default:
        return DestinyItemCategory.ARMOR;
    }
  }

  static getItemRecencyIdentifier(item: DestinyRecipesItem): string {
    if (!item.itemInstanceId) {
      return undefined;
    }
    return item.itemInstanceId.padStart(20, '0');
  }

  /* Most recent first **/
  static orderByRecency(itemA: DestinyRecipesItem, itemB: DestinyRecipesItem) {
    return DestinyRecipesItemHelper.getItemRecencyIdentifier(itemA) > DestinyRecipesItemHelper.getItemRecencyIdentifier(itemB) ? -1 : 1;
  }
}

export interface DestinyRecipesPerksStats {
  perkApplied: DestinyInventoryItemDefinition;
  statModifier: number;
}

export interface DestinyRecipesStat {
  baseRolledStatValue: number;
  modsStatValue: DestinyRecipesPerksStats[];
  totalModsStatValue: number;
  masterworkStatValue: number;
  displayValue: number;
}


export interface RecoilValue {
  /** Value found in the recoil value of the definition */
  value: number;

  /** bounciness = 100-x. More bouncy means more recoil toward the angle */
  bounciness: number;

  /** For display purposes, 40 recoil being the lowest in the game it is mapped to 100% bounciness */
  mappedBounciness: number;

  /** Angle is mapped from the direction value [-1,1] to degrees [-90,90] */
  angle: number;
}
