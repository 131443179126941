<p>{{'SECTIONS.VAULT_CLEANER.share-modal.DESC' | translate}}</p>
<p class="warn">{{'SECTIONS.VAULT_CLEANER.share-modal.WARNING' | translate}}</p>
<p></p>

<p class="gold" [innerHTML]="'SECTIONS.VAULT_CLEANER.share-modal.BUNGIE_OPTION_WARNING' | translate: {bungie: bungieLink}"></p>
<div class="links">
  <div class="share-link" *ngFor="let m of memberships">
    <img [alt]="m.membershipType" [src]="'https://bungie.net/' + m.iconPath">
    <input type="text" disabled [value]="'https://destinyrecipes.com/vault?mId=' + m.membershipId + '&mType=' + m.membershipType">
    <button ngxClipboard (cbOnSuccess)="copied()" [cbContent]="'https://destinyrecipes.com/vault?mId=' + m.membershipId + '&mType=' + m.membershipType"><fa-icon [icon]="['fas', 'copy']"></fa-icon> {{'SECTIONS.VAULT_CLEANER.share-modal.COPY_LINK' | translate}}</button>
  </div>
</div>

<div align="center">
  <button class="app-btn" (click)="downloadVault()"><fa-icon [icon]="['fas', 'download']"></fa-icon>{{'SECTIONS.VAULT_CLEANER.share-modal.DOWNLOAD' | translate}} ({{size}})</button><br>
  <span *ngIf="saved">{{'SECTIONS.VAULT_CLEANER.share-modal.DOWNLOAD_INFO' | translate}}</span>
</div>

