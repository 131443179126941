<div class="bungie-card-ctn" *ngIf="isAuthenticated; else loginButton" [ngClass]="{'small': small}">
  <app-dropdown *ngIf="memberships?.length > 1" [placeholder]="'SECTIONS.COMMON.bungie-card.SELECT_MEMBERSHIP' | translate" [source]="memberships" [displayFunction]="getMembershipName" [valueKey]="'membershipId'" [icon]="['fas', 'exchange-alt']" (selected)="selectMembership($event)"></app-dropdown>
  <a class="card-logout" (click)="doRefresh()"><fa-icon [icon]="['fas', 'sync-alt']" size="lg" [animation]="refreshing || dataService.loadingInventory ? 'spin' : undefined" *ngIf="!small"></fa-icon></a>
  <div class="bungie-card type-bungie" *ngIf="!memberships || memberships.length < 2">
    <span class="card-name">
      <span>{{authService.bungieMembership.cachedBungieGlobalDisplayName}}</span>
      <span class="code">#{{authService.bungieMembership.cachedBungieGlobalDisplayNameCode}}</span>
    </span>
    <div class="warning" *ngIf="!usingCache && dataService.lastUpdateStatus === 'ERROR'">
      <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    </div>
    <div class="warning" *ngIf="usingCache">
      <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    </div>
  </div>
  <div class="bungie-card" [ngClass]="'type-' + authService.currentMemberShip.membershipType" *ngIf="memberships?.length > 1">
    <span class="card-icon"><img alt="platform" [src]="'https://bungie.net' + authService.currentMemberShip.iconPath"></span>
    <span class="card-name">{{authService.currentMemberShip.displayName}}</span>
    <div class="other-memberships" *ngIf="!small && otherMbr?.length > 1">
      <span class="linked-mbr" *ngFor="let mbrType of otherMbr"><img alt="platform" [src]="'https://bungie.net' + mbrType.iconPath"></span>
    </div>
    <div class="warning">
      <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    </div>
  </div>
  <a class="card-logout" [title]="'SECTIONS.COMMON.bungie-card.SIGN_OUT' | translate" (click)="logout()"><fa-icon [icon]="['fas', 'sign-out-alt']" size="lg"></fa-icon></a>

</div>
<ng-template #loginButton>
  <button class="app-btn"
          (click)="login()"
          [disabled]="loginLoading"
          *ngIf="!isAuthenticated">
    {{(core.loadingState === 'AUTHENTICATING' ? 'COMMON.LOADING' : loginText) | translate}}
  </button>
</ng-template>


