import { Component, Input, OnChanges } from '@angular/core';
import { ItemAnnotation, Loadout } from '@destinyitemmanager/dim-api-types';
import { DimSyncService } from '../../services/dim-sync.service';
import { logError } from '../../console';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dim-sync-notes',
  templateUrl: './dim-sync-notes.component.html',
  styleUrls: ['./dim-sync-notes.component.scss']
})
export class DimSyncNotesComponent implements OnChanges {

  @Input() instanceId: string;

  dimSyncAvailable: boolean;
  dimSyncEntry: ItemAnnotation;
  loadouts: Loadout[];
  noteEditorOpen = false;
  notes: string;

  constructor(private dimSync: DimSyncService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnChanges(): void {
    this.loadDimSyncEntry();
  }

  loadDimSyncEntry() {
    this.dimSyncEntry = null;
    this.dimSyncAvailable = true;
    if (!this.instanceId) {
      return;
    }
    if (!this.dimSync.profile) {
      this.dimSyncAvailable = false;
      return;
    }
    this.dimSyncEntry = this.dimSync.getAnnotationsForItem(this.instanceId) || { id: this.instanceId, tag: null, notes: null };
    this.loadouts = this.dimSync.getLoadoutsForItem(this.instanceId);
  }

  toggleNoteEditor() {
    this.notes = this.dimSyncEntry?.notes || '';
    setTimeout(() => this.noteEditorOpen = !this.noteEditorOpen, 10);
  }

  sendNotes(e: KeyboardEvent) {
    if (e.isTrusted && e.key === 'Enter' && !e.shiftKey) {
      this.noteEditorOpen = false;
      this.dimSyncEntry.notes = this.notes;
      this.dimSync.sendTagUpdates([{
        action: 'tag',
        payload: {
          notes: this.notes,
          id: this.instanceId,
        }
      }]).subscribe({
        error: (err) => {
          logError(err);
          this.toastr.error(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.NOTES_UPDATED'));
        }
      });
    }
  }

}
