import { Component, Directive, ElementRef, Input } from '@angular/core';
import { LoadingComponent } from '../../types';
import { CoreService } from '@Services/core.service';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent {

  @Input('components') components: LoadingComponent[] | LoadingComponent | undefined = undefined;
  @Input('placeholder') placeholder = 'You are not logged in';

  allComponentsLoaded = false;
  hasError = false;

  constructor(private element: ElementRef, private core: CoreService) {}

  ngOnInit() {
    let wait$;
    if (typeof this.components === 'string') {
      wait$ = this.core.waitFor(this.components);
    } else if (Array.isArray(this.components)) {
      wait$ = this.core.waitAll(this.components);
    }
    if (wait$) {
      wait$.subscribe((c) =>  this.checkState());
    }
  }

  checkState() {
    if (!this.components) {
      this.allComponentsLoaded = false;
      return;
    }
    if (typeof this.components === 'string') {
      this.allComponentsLoaded = this.core.components.hasOwnProperty(this.components);
    } else if (Array.isArray(this.components)) {
      this.allComponentsLoaded = this.components.every((c) => this.core.components.hasOwnProperty(c));
    }
    if (this.allComponentsLoaded) {
      this.checkError();
    }
  }

  checkError() {
    const componentsToCheck = Array.isArray(this.components) ? this.components : [this.components];
    this.hasError = componentsToCheck.some((c) => !this.core.components[c]);
  }

}
