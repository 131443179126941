<div class="loot modes">
  <div class="mode" *ngFor="let watcher of watchersService.availabledWatchers">
    <div class="mode-info">
      <span class="mode-name">{{('WATCHER.' + watcher.type + '.NAME') | translate}}</span>
      <small class="mode-desc">{{('WATCHER.' + watcher.type + '.DESCRIPTION') | translate}}</small>
    </div>
    <div class="customizer" *ngIf="watcher.config">
      <label>{{('WATCHER.' + watcher.type + '.CUSTOMIZER.label') | translate}}</label>
      <input [type]="watcher.config.type" [formControl]="watcher.config.control" *ngIf="watcher.config.type === 'number' || watcher.config.type === 'string'">
      <select *ngIf="watcher.config.type === 'select'" [formControl]="watcher.config.control">
        <option *ngFor="let option of watcher.config.options" [value]="option">{{('WATCHER.' + watcher.type + '.CUSTOMIZER.OPTIONS.' + option | translate)}}</option>
      </select>
      <fa-icon size="sm" [icon]="['fas', 'redo']" title="Update watcher" *ngIf="watcher.config.control.dirty && watchersService.getStatus(watcher.type) === 'running'" (click)="updateWatcherConfig(watcher)"></fa-icon>
    </div>
    <div class="mode-status">
      <ng-container [ngSwitch]="watchersService.getStatus(watcher.type)">
        <ng-container *ngSwitchCase="'stopped'">
          <fa-icon [icon]="['fas', 'play']" size="2x" (click)="startWatcher(watcher)"></fa-icon>
          <div class="status">{{'WATCHER.STATUS.stopped' | translate}}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'running'">
          <fa-icon [icon]="['fas', 'pause']" size="2x" (click)="stopWatcher(watcher)"></fa-icon>
          <div class="status running">{{'WATCHER.STATUS.running' | translate}}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'paused'">
          <fa-icon [icon]="['fas', 'play']" size="2x" (click)="startWatcher(watcher)"></fa-icon>
          <div class="status pause">{{'WATCHER.STATUS.paused' | translate}}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
