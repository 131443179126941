import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
import { DestinyManifestComponentName } from 'bungie-api-ts/destiny2';

@Pipe({
  name: 'itemIconUrl'
})
export class ItemIconUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer, private dataService: DataService) {}

  transform(itemHash: number, type: DestinyManifestComponentName = 'DestinyInventoryItemDefinition'): SafeStyle {
    const item: any = this.dataService.getDefinitionById(type, itemHash);
    if (!item) {
      return '';
    } else {
      return this.sanitizer.bypassSecurityTrustUrl('https://www.bungie.net' + item.displayProperties.icon);
    }
  }
}
