<div class="tags" *ngIf="dimSyncAvailable && instanceId">
  <div class="tag-select">
    <select [(ngModel)]="dimSyncEntry.tag">
      <option *ngFor="let tag of DIMTags" [value]="tag">{{tag}}</option>
    </select>
    <div class="select-dropdown" [ngClass]="{'visible': tagDropdownActive}">
      <div class="option" *ngFor="let tag of DIMTags" (click)="selectTag(tag)" [ngClass]="{'selected': tag === dimSyncEntry.tag}"><fa-icon [icon]="tagIcons[tag]" size="sm" *ngIf="tagIcons"></fa-icon> {{('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.TAGS.' + tag) | translate}}</div>
    </div>
    <span class="tag-value" (click)="tagDropdownActive = !tagDropdownActive">
            <fa-icon [icon]="tagIcons[dimSyncEntry.tag]" *ngIf="tagIcons && dimSyncEntry?.tag" size="sm"></fa-icon>
      {{('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.TAGS.' + (dimSyncEntry.tag || 'ADD')) | translate}}
      <fa-icon [icon]="['fas', 'caret-down']" size="sm"></fa-icon>
          </span>
  </div>
</div>
