<div class="error-ctn">
  <p>{{'SECTIONS.COMMON.error-modal.WOOPS' | translate}}</p>
  <div class="stacktrace" *ngIf="errorType !== 'maxtries'">
    <span>State: {{errorService.currentState || 'Unknown'}}</span>
    <span *ngFor="let line of data.error.stack.split('\n')">{{line}}</span>
  </div>
  <div class="stacktrace" *ngIf="errorType === 'maxtries'">
    <span>State: {{errorService.currentState || 'Unknown'}}</span>
    <span>{{copiedError}}</span>
  </div>
  <div class="action">
    <button class="app-btn" ngxClipboard [cbContent]="copiedError" >{{'SECTIONS.COMMON.error-modal.COPY' | translate}}</button>
    <a class="social-button discord" href="https://discord.gg/mbcEaA72XS"><fa-icon [icon]="['fab', 'discord']"></fa-icon>{{'SECTIONS.COMMON.error-modal.REPORT' | translate}}</a>
  </div>
</div>
