import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DestinyCharacterComponent, DestinyClassDefinition, DestinyRaceDefinition } from 'bungie-api-ts/destiny2';

@Component({
  selector: 'app-character-card',
  templateUrl: './character-card.component.html',
  styleUrls: ['./character-card.component.scss']
})
export class CharacterCardComponent implements OnInit {

  @Input() characterId: string;
  @Input() equipmentLevel: number;
  @Input() artifactLevel: number;
  @Input() example: boolean;

  character: DestinyCharacterComponent;
  emblem: string;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.character = this.dataService.getCharacter(this.characterId);
    if (!this.character) {
      this.example = true;
    }
    this.emblem = 'https://bungie.net' + this.character?.emblemBackgroundPath || 'https://bungie.net/common/destiny2_content/icons/bc6631a30e4d7ea340ed65c95bacec60.jpg';
  }

  getClassName() {
    return this.dataService
      .getDefinitionById<DestinyClassDefinition>('DestinyClassDefinition', this.character.classHash)
      .genderedClassNamesByGenderHash[this.character.genderHash];
  }

  getRaceName() {
    return this.dataService
      .getDefinitionById<DestinyRaceDefinition>('DestinyRaceDefinition', this.character.raceHash)
      .genderedRaceNamesByGenderHash[this.character.genderHash];
  }

}
