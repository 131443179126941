import { Component, OnInit } from '@angular/core';
import { CleanupConfig, ModalContentDialog } from '../../types';
import { ModaleComponent } from '../../common/modale/modale.component';
import { DataService } from '../../services/data.service';
import { CoreService } from '../../services/core.service';
import { ImportedDataService } from '../../services/imported-data.service';
import { logError } from '../../console';

@Component({
  selector: 'app-import-cleanup-modal',
  templateUrl: './import-cleanup-modal.component.html',
  styleUrls: ['./import-cleanup-modal.component.scss']
})
export class ImportCleanupModalComponent implements OnInit, ModalContentDialog<CleanupConfig> {

  data: any;
  loading: boolean;
  error: boolean;
  readonly modalReference: ModaleComponent;
  toImport: CleanupConfig;
  isOwnVault = false;

  get dataS(): DataService {
    return this.coreService.usingImportedData ? this.importedDataService : this.dataService;
  }

  constructor(public dataService: DataService, public coreService: CoreService, public importedDataService: ImportedDataService) { }

  ngOnInit(): void {
  }

  getData(): CleanupConfig {
    return this.toImport;
  }

  handleFile(event) {
    if (this.loading) { return; }
    this.loading = true;
    this.error = false;
    this.modalReference.confirmedDisabled = true;
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.loading = false;
        try {
          const toImport: CleanupConfig = JSON.parse(fileReader.result.toString());
          if (!toImport) {
            throw new Error('No JSON to import');
          }
          if (!toImport.hasOwnProperty('ownerId') || !toImport.hasOwnProperty('toKeep') || !toImport.hasOwnProperty('toRemove') || !toImport.hasOwnProperty('date')) {
            throw new Error('File is malformed');
          }
          this.toImport = toImport;
          this.isOwnVault = this.dataS.ownerId === toImport.ownerId;
          this.modalReference.confirmedDisabled = !this.isOwnVault;
        } catch (err) {
          logError(err);
          this.error = true;
        }
      };
      fileReader.readAsText(file);
    }
  }

}
