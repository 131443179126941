import { Injectable } from '@angular/core';
import { RecipeHelper } from '@Types/recipe-helper';
import { HttpClient } from '@angular/common/http';
import { environment } from '@Env';
import { RecipesApiService } from '@Services/recipes-api.service';
import { Recipe, RecipeContent, RecipeCreationDto } from '@Types/destiny-recipes.api';
import { NotificationService } from '@Services/notification.service';
import { catchError, map, mergeMap, share, tap } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '@Services/data.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  /** Current working stage or the Recipe. Unsaved changes */
  currentRecipe: RecipeHelper;

  /** Used to revert unsaved changes to the previous staged saved state of the loaded Recipe */
  stashedRecipe: RecipeHelper;

  loadingRecipe: boolean;
  recipeLoader$: Observable<RecipeHelper>;

  private update$ = new Subject<void>();

  get dirty(): boolean {
    return this.currentRecipe.hasChanges;
    // return !_.isEqual(this.currentRecipe?.content, this.currentRecipe?.stagingContent);
  }

  get pristine(): boolean {
    return !this.dirty;
  }

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private recipesApiService: RecipesApiService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
  ) {
  }

  onUpdate(): Observable<void> {
    return this.update$.pipe(share());
  }

  createRecipe(dto: RecipeCreationDto) {
    if (this.dirty) {
      // TODO handle warning unsaved
    }
    const loadingNotif = this.notificationService.create({
      title: 'Creating new Recipe',
      style: 'info',
      loading: true
    });
    this.recipesApiService.createRecipe(dto).subscribe((recipe) => {
      this.currentRecipe = RecipeHelper.from(recipe, this.dataService);
      this.stashedRecipe = this.currentRecipe.clone();
      this.update$.next();
      loadingNotif.close();
      this.notificationService.create({title: 'New recipe created!', style: 'success', timeout: 5000});
    }, (err) => {
      loadingNotif.close();
      this.notificationService.create({title: 'Could not create new Recipe!', message: err.message, style: 'error', timeout: 7000});
    });

  }

  useRecipe(recipeId: string) {
    if (this.loadingRecipe) { return this.recipeLoader$; }
    this.loadingRecipe = true;
    this.recipeLoader$ = this.recipesApiService.getRecipeById(recipeId)
      .pipe(
        mergeMap((result: Recipe) => {
          if (result) {
            this.currentRecipe = RecipeHelper.from(result, this.dataService);
            if (this.currentRecipe.recipe.contentLocation) {
              return this.http.get<RecipeContent>(environment.dataUrl + this.currentRecipe.recipe.contentLocation);
            }
          }
          return of(undefined);
        }),
        map((content) => {
          if (content) {
            this.currentRecipe.setContent(content);
          }
          this.stashedRecipe = this.currentRecipe.clone();
          this.loadingRecipe = false;
          this.update$.next();
          return this.currentRecipe;
        }),
        catchError((error) => {
          console.error(error);
          this.toastr.error('Could not load recipe');
          this.loadingRecipe = false;
          this.update$.next();
          return throwError(error);
        })
      );
    return this.recipeLoader$;
  }

  saveCurrentContent() {
    if (this.loadingRecipe) { return; }
    this.loadingRecipe = true;
    this.currentRecipe.saveAll();
    return this.recipesApiService.uploadRecipeContent(this.currentRecipe).pipe(
      tap(() => {
        this.stashedRecipe = this.currentRecipe.clone();
        this.loadingRecipe = false;
        this.update$.next();
        this.toastr.success('Recipe saved');
      }),
      catchError((error) => {
        this.toastr.error('Could not save changes');
        this.loadingRecipe = false;
        this.update$.next();
        return throwError(error);
      }),
    );
  }

  revertChanges() {
    this.currentRecipe = this.stashedRecipe.clone();
    this.update$.next();
  }

  closeRecipe() {
    if (this.dirty) {
      // TODO handle save
    }
    this.currentRecipe = null;
    this.stashedRecipe = null;
    this.update$.next();
  }

  useDefault() {
    this.currentRecipe = new RecipeHelper(this.dataService);
  }
}
