import { Component, OnInit } from '@angular/core';
import { DestinyRecipesWatcherInfo, WatchersService } from '../../services/watchers.service';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '@Services/core.service';
import { LoadingComponent } from '../../types';

@Component({
  selector: 'app-watcher-manager',
  templateUrl: './watcher-manager.component.html',
  styleUrls: ['./watcher-manager.component.scss']
})
export class WatcherManagerComponent implements OnInit {


  constructor(public watchersService: WatchersService, public toast: ToastrService, private core: CoreService) { }

  ngOnInit(): void {
  }

  startWatcher(watcherInfo: DestinyRecipesWatcherInfo) {
    if (!this.core.components[LoadingComponent.INVENTORY]) {
      return this.toast.error('You must load the inventory before starting a watcher.');
    }

    this.watchersService.startWatcher(watcherInfo.type, {
      watcherType: watcherInfo.type,
      data: watcherInfo.config?.control?.value
    });
    if (watcherInfo.config) {
      watcherInfo.config.control.markAsPristine();
    }
  }

  updateWatcherConfig(watcherInfo: DestinyRecipesWatcherInfo) {
    if (!this.core.components[LoadingComponent.INVENTORY]) {
      return this.toast.error('You must load the inventory before interacting with a watcher.');
    }
    this.watchersService.watchers[watcherInfo.type].setConfig({
      watcherType: watcherInfo.type,
      data: watcherInfo.config?.control?.value
    });
    this.watchersService.watchers[watcherInfo.type].restart();
    watcherInfo.config.control.markAsPristine();
  }

  stopWatcher(watcherInfo: DestinyRecipesWatcherInfo) {
    if (!this.core.components[LoadingComponent.INVENTORY]) {
      return this.toast.error('You must load the inventory before interacting with a watcher.');
    }
    this.watchersService.stopWatcher(watcherInfo.type);
  }
}
