import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '@Services/data.service';
import { ClarityEntry } from '@Types/clarity';

@Component({
  selector: 'app-clarity-insight',
  templateUrl: './clarity-insight.component.html',
  styleUrls: ['./clarity-insight.component.scss']
})
export class ClarityInsightComponent implements OnInit {

  @Input() perkHash: number;
  entry: ClarityEntry;

  constructor(private data: DataService) { }

  ngOnInit(): void {
    if (!this.data.clarityDatabase) { return; }
    this.entry = this.data.clarityDatabase[this.perkHash];
  }

}
