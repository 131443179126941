import { Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import {
  DestinyAmmunitionType,
  DestinyInventoryItemDefinition,
  DestinyItemObjectivesComponent,
  DestinyItemPlugObjectivesComponent,
  DestinyObjectiveDefinition,
  DestinySandboxPerkDefinition
} from 'bungie-api-ts/destiny2';
import { DataService } from '../../services/data.service';
import { CoreService, TooltipInstance } from '../../services/core.service';
import { ItemHelper } from '../item.helper';
import { DimSyncService } from '../../services/dim-sync.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { logError } from '../../console';
import { repositionPopup } from '../../../utils/popup-positioner';
import { deepsightResonanceHashes, killTrackers } from '../../../utils/known-values';
import { DestinyObjectiveProgress } from 'bungie-api-ts/destiny2/interfaces';
import { RecipesCraftableState } from '../../types';

@Component({
  selector: 'cr-equipment-tooltip',
  templateUrl: './equipment-tooltip.component.html',
  styleUrls: ['./equipment-tooltip.component.scss', 'tooltip-item-description.scss']
})
export class EquipmentTooltipComponent implements OnChanges {

  @ViewChild('tooltip', { static: false }) tooltip: ElementRef;
  @Input() item: DestinyInventoryItemDefinition;
  @Input() tooltipInstance: TooltipInstance;
  @Input() side: 'left' | 'right';
  @Input() position: {x: number, y: number};
  currentX = '0';
  currentY = '0';

  tierTypeClass = {
    0: 'Unknown',
    1: 'Currency',
    2: 'Basic',
    3: 'Common',
    4: 'Rare',
    5: 'Superior',
    6: 'Exotic',
  };
  objectives: DestinyItemObjectivesComponent;
  objectiveDefinitions: {[key: number]: DestinyObjectiveDefinition} = {};
  modPerkDefinition: DestinySandboxPerkDefinition;
  noInstanceBounty: boolean;
  deepsightObjective: DestinyObjectiveProgress;
  craftableState: RecipesCraftableState;
  trackerObjective: DestinyObjectiveProgress;
  showTogglable = false;

  get dimSyncAvailable() {
    return !!this.dimSync.profile;
  }

  get maxLight(): number {
    if (!this.item || !this.item.quality) { return 0; }
    try {
      const powerCap = this.item.quality.versions[this.tooltipInstance?.instance ? (this.tooltipInstance.instance.versionNumber || 0) : this.item.quality.currentVersion].powerCapHash;
      return this.dataService.powerCaps.find((c) => c.hash === powerCap).powerCap;
    } catch (err) {
      logError(err);
    }
    return 0;
  }

  get perksString() {
    if (this.tooltipInstance?.instance?.perks?.length && ItemHelper.isWeapon(this.item)) {
      return this.tooltipInstance.instance.perks.map((e) => e.find((p) => p.active).plugHash).join(',');
    }
  }

  AmmoType = {
    [DestinyAmmunitionType.Heavy]: 'Heavy',
    [DestinyAmmunitionType.Primary]: 'Primary',
    [DestinyAmmunitionType.Special]: 'Special',
  };

  constructor(
    public dataService: DataService,
    protected coreService: CoreService,
    protected eRef: ElementRef,
    protected dimSync: DimSyncService,
    protected toastr: ToastrService,
    protected translate: TranslateService
  ) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.coreService.tooltipLocked) {
      this.coreService.closeTooltip();
    }
  }

  ngOnChanges(changes) {
    if (changes.tooltipInstance && changes.tooltipInstance.currentValue !== changes.tooltipInstance.previousValue) {
      if (!this.tooltipInstance) {
        return;
      }
      if (this.tooltipInstance?.type === 'bounty' && this.tooltipInstance.bounty) {
        this.objectiveDefinitions = {};
        if (this.tooltipInstance.bounty.itemInstanceId) {
          this.noInstanceBounty = false;
          this.objectives = this.dataService.getObjective(this.tooltipInstance.bounty.itemInstanceId);
          this.objectives?.objectives.forEach((o) => {
            this.objectiveDefinitions[o.objectiveHash] = this.dataService.getDefinitionById<DestinyObjectiveDefinition>('DestinyObjectiveDefinition', o.objectiveHash);
          });
        } else {
          this.noInstanceBounty = true;
          this.tooltipInstance.bounty.definition.objectives.objectiveHashes.forEach((hash) => {
            this.objectiveDefinitions[hash] = this.dataService.getDefinitionById<DestinyObjectiveDefinition>('DestinyObjectiveDefinition', hash);
          });
        }
      }
      if (this.tooltipInstance.type === 'mod') {
        this.modPerkDefinition = this.dataService.getDefinitionById<DestinySandboxPerkDefinition>('DestinySandboxPerkDefinition', this.tooltipInstance.instance.definition.perks[0].perkHash);
      }
      if (this.tooltipInstance.type === 'item' && this.tooltipInstance.instance?.deepsight) {
        this.getDeepsightProgress();
      }
      this.getTrackerInfo();
    }
    if (changes.position && changes.position.currentValue) {
      const pos = repositionPopup(this.tooltip, { left: changes.position.currentValue.x, top: changes.position.currentValue.y }, this.side);
      this.currentY = pos.top;
      this.currentX = pos.left;
    }
  }

  closeTooltip() {
    this.coreService.closeTooltip();
  }

  getDeepsightProgress() {
    const progressItem: DestinyItemPlugObjectivesComponent = this.dataService.inventoryData?.itemComponents?.plugObjectives?.data[this.tooltipInstance.instance.itemInstanceId];
    if (Boolean(progressItem)) {
      const key = Object.keys(progressItem.objectivesPerPlug).find((e: string) => deepsightResonanceHashes.indexOf(Number.parseInt(e, 10)) > -1);
      if (Boolean(key)) {
        this.deepsightObjective = progressItem.objectivesPerPlug[key][0];
        this.craftableState = this.tooltipInstance.instance.recipeState;
      }
    }
  }

  getTrackerInfo() {
    if (this.tooltipInstance?.instance?.isWeapon) {
      const activeTrackerColumn = this.tooltipInstance.instance.perks.find((col) => col.some((perk) => killTrackers.indexOf(perk.plugHash) > -1));
      if (activeTrackerColumn) {
        const activeTracker = activeTrackerColumn.find((p) => p.active);
        const objective = this.tooltipInstance.instance.plugObjectives?.objectivesPerPlug[activeTracker.plugHash];
        if (objective?.length) {
          this.trackerObjective = objective[0];
        }
      }
    }
  }

  devVerbose() {
    if (this.coreService.devMode) {
      console.log(this.item, this.tooltipInstance);
    }
  }
}
