
<div class="dropdown">
  <select name="season">
    <option [value]="option.value" *ngFor="let option of options">{{option.text | translate}}</option>
  </select>
  <div class="dropdown-items" *ngIf="!icon">
    <div class="dropdown-selected" #downdownBox (click)="openDropdown()">
      <fa-icon [icon]="['fas', 'bars']" size="lg"></fa-icon> <span [innerHTML]="selectedItem?.text | translate"></span>
    </div>
  </div>
  <div class="dropdown-icon" [title]="placeholder" *ngIf="icon" #downdownBox (click)="openDropdown()">
    <fa-icon [icon]="icon"  size="lg"></fa-icon>
  </div>
</div>

<div class="dropdown-list" [ngClass]="{'opened': dropdownOpened}" [style.top]="position.y + 'px'" [style.left]="position.x + 'px'" [style.minWidth]="position.width">
  <div class="dropdown-item" [ngClass]="{'selected': currentValue === option.value}" (click)="selectOption(option)" *ngFor="let option of options" [innerHTML]="option.text | translate"></div>
</div>
